import React, { useState } from 'react';
import { Route, withRouter, Link, Redirect, useLocation } from 'react-router-dom';
import routes from './../../config/page-route.jsx';
import { PageSettings } from './../../config/page-settings.js';
import { useEffect } from 'react';
import { WebUrl } from 'util/Constant.js';
import NavigationButton from 'components/buttons/NavigationButton';
import { Config, ConfigEnum } from 'util/Config.js';
import { stringIsNullOrEmpty } from 'util/Utility.js';
import { useRecoilState } from 'recoil';
import { appState } from 'recoil/Atoms.js';
import useAuthController from 'recoil/useAuthController.js';

/// <summary>
/// Author: Christopher Chan
/// </summary>
const compareRoutes = (routePath, path) => {
        console.log(routePath);
        console.log(path);
        const splitRoutePath = routePath.split('/').slice(1);
        const splitPath = path.split('/').slice(1);

        splitRoutePath.map((item, index) => {
                if (item.charAt(0) === ':') {
                        splitRoutePath.splice(index, 1);

                        if (splitPath[index] !== undefined) {
                                splitPath.splice(index, 1);
                        }
                }
        });

        return splitRoutePath.join('/') === splitPath.join('/');
}

const setTitle = (path, routeArray) => {
        var pageTitle;

        for (var i = 0; i < routeArray.length; i++) {
                if (compareRoutes(routeArray[i].path, path)) {
                        pageTitle = `NECS | ${routeArray[i].title}`;
                }
        }

        document.title = pageTitle;
}

/// <summary>
/// Author: Lewis
/// </summary>
const AuthRoute = ({ component: Component, isLoggedIn, ...rest }) => {
        return (
                <>
                        <Route
                                {...rest}
                                render={props => (isLoggedIn ? (<Component {...props} />) : (<Redirect to={WebUrl._LOGIN} />))}
                        />
                        {isLoggedIn && <Redirect from={WebUrl._LOGIN} to={WebUrl._DASHBOARD} />}
                </>
        );
}

const Content = ({ history }) => {
        const { isLoggedIn } = useAuthController({});
        const _location = useLocation();

        /// <summary>
        /// Author: Christopher Chan
        /// </summary>
        useEffect(() => {
                setTitle(history.location.pathname, routes);
                return history.listen(() => {
                        setTitle(history.location.pathname, routes);
                });
        }, []);

        return (

                <PageSettings.Consumer>
                        {({ pageContentFullWidth, pageContentClass, pageContentInverseMode, pageFooter }) => (
                                <div className={'content ' + (pageContentFullWidth ? 'content-full-width ' : '') + (pageContentInverseMode ? 'content-inverse-mode ' : '') + pageContentClass + (pageFooter ? 'has-footer ' : '')}>
                                        {routes.map((route, index) => (
                                                // route.authRequired ? (<AuthRoute component={route.component} {...route} isLoggedIn={isLoggedIn} />) : (<Route {...route} />)
                                                <Route {...route} />
                                        ))}
                                </div>
                        )}
                </PageSettings.Consumer>
        )
}

export default withRouter(Content);
