import React, { useState } from "react";
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import InputHoc from "components/form/InputHoc.js";
import { useForm } from "react-hook-form";
import { Confirm } from 'notiflix';
import ReactTooltip from 'react-tooltip';

/// <summary>
/// Author: Christopher Chan
/// </summary>
export default ({ type, id, apiCall, seperator = true }) => {
    const { register, handleSubmit, errors } = useForm();
    const [modal, setModal] = useState(false);

    /// <summary>
    /// Author: Christopher Chan
    /// </summary>
    const confirmationMessage = (id, status, remarks = null) => {
        let statusMessage = status === 1 ? '<span style="color: green;">Win</span>' : '<span style="color: red;">Loss</span>';
        let message = `<table style="width: 90%; margin: auto; text-align: left;">
        <tbody>
            <tr>
                <td><b>ID</b></td>
                <td>:</td>
                <td style="padding-left: 10px;">${id}</td>
            </tr>
            <tr>
                <td><b>Status</b></td>
                <td>:</td>
                <td style="padding-left: 10px;"><b>${statusMessage}</b></td>
            </tr>
            ${remarks ? `
                    <tr>
                        <td><b>Remarks</b></td>
                        <td>:</td>
                        <td style="padding-left: 10px; font-weight: bold; max-width: 1px; word-wrap: break-word;">${remarks}</td>
                    </tr>
                ` :
                ''
            }
            </tbody>
        </table>`;

        Confirm.Init({ plainText: false, messageMaxLength: 1000 });
        Confirm.Show(
            `Are you sure?`,
            `Please ensure this is what you would like to perform:<br/><br/>${message}`,
            'Yes',
            'No',
            () => apiCall({ id, status, remarks })
        );
    }

    /// <summary>
    /// Author: Christopher Chan
    /// </summary>
    const onSubmit = (data) => {
        confirmationMessage(id, -1, data.remarks);
    }

    /// <summary>
    /// Author: Christopher Chan
    /// </summary>
    const winStatusHandler = () => {
        confirmationMessage(id, 1);
    }

    /// <summary>
    /// Author: Christopher Chan
    /// </summary>
    const toggleModal = () => {
        setModal(!modal);
    }

    return <>
            <div
                className="btn-table"
                data-for={`lossBtn-${id}`}
                data-tip={`Loss ${type}`}
                onClick={toggleModal}
            >
                <i className="fas fa-times"></i>
            </div>
            <div
                className="btn-table"
                data-for={`winBtn-${id}`}
                data-tip={`Win ${type}`}
                onClick={winStatusHandler}
            >
                <i className="fas fa-check"></i>
            </div>
            <ReactTooltip id={`winBtn-${id}`} />
            <ReactTooltip id={`lossBtn-${id}`} />

        <Modal isOpen={modal} toggle={toggleModal} centered>
            <ModalHeader toggle={toggleModal}>Please state the reason for loss of {type}</ModalHeader>
            <ModalBody>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <InputHoc
                        ref={register({ required: 'Remarks is required.' })}
                        name="remarks"
                        error={errors?.remarks?.message}
                        placeholder="Remarks"
                        showErrorMsg
                    />
                    <hr style={{ marginBottom: 5, marginTop: 25 }} />
                    <button type="button" className="btn btn-default btn-min-width pull-left mt-2" onClick={toggleModal}>Cancel</button>
                    <button type="submit" className="btn btn-themed btn-min-width pull-right mt-2">Proceed</button>
                </form>
            </ModalBody>
        </Modal>
    </>
}
