import React, { useMemo } from "react";
import VerticalTimelineItem from "components/VerticalTimelineItem";

/// <summary>
/// Author: KurisuCodes
/// </summary>
export default ({ items }) => (
  <>
    {items.map((data, index) => (
      <div style={{ marginBottom: index !== items.length - 1 ? 10 : 0 }}>
        {data.map((item, dataIndex) => (
          <VerticalTimelineItem
            item={item}
            isLast={dataIndex === data.length - 1}
          />
        ))}
      </div>
    ))}
  </>
);
