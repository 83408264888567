import React from 'react';
import { Redirect } from 'react-router';
import { WebUrl } from './../util/Constant';
import Login from 'pages/common/LoginPage';
import Dashboard from 'pages/dashboard/Dashboard';
import Customer from 'pages/customer/Customer';
import EditCustomer from 'pages/customer/EditCustomer';
import ViewCustomer from 'pages/customer/ViewCustomer';
import ViewEnquiry from 'pages/enquiry/ViewEnquiry';
import ViewQuotation from 'pages/quotation/ViewQuotation';
import Enquiry from 'pages/enquiry/Enquiry';
import CreateUpdateQuotation from 'pages/quotation/CreateUpdateQuotation';
import Quotation from 'pages/quotation/Quotation';
import Contract from 'pages/contract/List';
import ElevatorModels from 'pages/elevator-models/List';
import ElevatorModelCreateOrUpdate from 'pages/elevator-models/CreateOrUpdate';

const routes = [
        {
                path: '/',
                exact: true,
                component: () => <Redirect to={WebUrl._LOGIN} />,
                authRequired: false,
        },
        {
                path: WebUrl._DASHBOARD,
                title: 'Dashboard',
                exact: true,
                component: () => <Dashboard />,
                authRequired: true,
        },
        {
                path: WebUrl._CUSTOMER,
                title: 'Customer Management',
                exact: true,
                component: () => <Customer />,
                authRequired: true,
        },
        {
                path: WebUrl._EDIT_CUSTOMER,
                title: 'Edit Customer',
                exact: true,
                component: () => <EditCustomer />,
                authRequired: true,
        },
        {
                path: WebUrl._VIEW_CUSTOMER,
                title: 'View Customer',
                exact: true,
                component: () => <ViewCustomer />,
                authRequired: true,
        },
        {
                path: WebUrl._ENQUIRY,
                title: 'Enquiry Management',
                exact: true,
                component: () => <Enquiry />,
                authRequired: true,
        },
        {
                path: WebUrl._VIEW_ENQUIRY,
                title: 'View Enquiry',
                exact: true,
                component: () => <ViewEnquiry />,
                authRequired: true,
        },
        {
                path: WebUrl._VIEW_QUOTATION,
                title: 'View Quotation',
                exact: true,
                component: () => <ViewQuotation />,
                authRequired: true,
        },
        {
                path: WebUrl._QUOTATION_CREATE_OR_UPDATE,
                title: 'Enquiry Management',
                exact: true,
                component: () => <CreateUpdateQuotation />,
                authRequired: true,
        },
        {
                path: WebUrl._QUOTATION,
                title: 'Quotation Management',
                exact: true,
                component: () => <Quotation />,
                authRequired: true,
        },
        {
                path: WebUrl._LOGIN,
                title: 'Login',
                exact: true,
                component: () => <Login />,
                authRequired: false,
        },
        {
                path: WebUrl._CONTRACT,
                title: 'Contract Management',
                exact: true,
                component: () => <Contract />,
                authRequired: true,
        },
        {
                path: WebUrl._ELEVATOR_MODELS,
                title: "Elevator Models",
                exact: true,
                component: () => <ElevatorModels />
        },
        {
                path: WebUrl._ELEVATOR_MODELS_CREATE_OR_UPDATE,
                title: "Elevator Models",
                exact: true,
                component: () => <ElevatorModelCreateOrUpdate />
        }
];


export default routes;