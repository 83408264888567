import React, { useState, useEffect, forwardRef } from "react";
import classnames from "classnames";
import { FaLock } from "react-icons/fa";

/// <summary>
/// Author: Christopher Chan
/// </summary>
const CustomTag = forwardRef((props, ref) => {
  const {
    readOnly,
    icon,
    label,
    placeholder,
    showLabel,
    isEditable = true,
    style,
    error,
    type = "text",
    values,
    onKeyDown,
    tags,
    deleteTag,
    input,
    onKeyUp,
    onChange,
    ...rest
  } = props;

  return (
    <>
      {showLabel && <label className="custom-input-label">{label}</label>}
      <div className="tag-container">
        {icon && (
          <div className={`tag-icon-container ${readOnly ? "disabled" : ""}`}>
            {icon}
          </div>
        )}
        {tags &&
          tags.map((tag, index) => (
            <div key={tag} className="tag">
              {tag}
              <button
                disabled={readOnly || !isEditable}
                onClick={() => deleteTag(index)}
              >
                x
              </button>
            </div>
          ))}
        <input
          ref={ref}
          {...rest}
          value={input}
          placeholder="Enter a tag"
          onKeyDown={onKeyDown}
          onKeyUp={onKeyUp}
          onChange={onChange}
          readOnly={readOnly || !isEditable}
        />
      </div>
    </>
  );
});

export default CustomTag;
