import React, { useMemo, useState, useRef, useEffect } from "react";
import ReactTable from "components/react-table/ReactTable";
import {
  Card,
  CardHeader,
  CardBody,
  Row,
  Col,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";
import Notiflix from "notiflix";
import { useHistory } from "react-router-dom";
import { InputTypes, SelectField, WebUrl, ApiKey } from "util/Constant";
import { Config, ConfigEnum } from "util/Config";
import ElevatorModelDao from "data/ElevatorModelDao";
import { Block } from "notiflix";
import { tryCatch, handleSetPageOptions } from "util/Utility";
import moment from "moment";

/// <summary>
/// Author: Christopher Chan
/// </summary>
export default () => {
  const history = useHistory();
  const [agent, setAgent] = useState(null);
  const [elevatorModels, setElevatorModels] = useState([]);
  const [selectedRow, setSelectedRow] = useState();
  const _tableRef = useRef();
  let configInstance = Config.getInstance();
  var token = configInstance.getValue(ConfigEnum._TOKEN);

  /// <summary>
  /// Author: Christopher Chan
  /// </summary>
  const getElevatorModels = async () => {
    tryCatch(async () => {
      Block.circle("#elevatorsTable");

      let dao = new ElevatorModelDao();

      await dao.getElevatorModels(token).then((response) => {
        if (response[ApiKey._API_SUCCESS_KEY]) {
          var data = response[ApiKey._API_DATA_KEY];
          console.log(data);
          setElevatorModels(data);
          handleSetPageOptions(_tableRef, response[ApiKey._API_TOTAL_KEY] ?? 0);
        } else {
          Notiflix.Report.Failure(
            "Error",
            "Failed to retrieve elevator models. Please try again later"
          );
        }
      });

      Block.remove("#elevatorsTable");
    });
  };

  const deleteElevatorModel = async (row) => {
    var id = row.id;
    let dao = new ElevatorModelDao();
    console.log(id);
    await dao.deleteElevatorModel(id, token).then((responseJson) => {
      if (responseJson[ApiKey._API_SUCCESS_KEY]) {
        Notiflix.Report.success("Success", "Elevator Model Deleted");
        getElevatorModels();
      } else {
        Notiflix.Report.failure(
          "Error",
          "Failed to delete customer. " + responseJson[ApiKey._API_MESSAGE_KEY]
        );
      }
    });
  };

  /// <summary>
  /// Author: Christopher Chan
  /// </summary>
  useEffect(() => {
    getElevatorModels();
  }, []);

  const _COLUMN = useMemo(() => [
    {
      Header: "Serial Number",
      Cell: ({ row }) => <b>{row.original.serialNumber}</b>,
    },
    {
      Header: "Model",
      accessor: "name",
    },
    {
      Header: "Description",
      accessor: "description",
    },
    {
      Header: "Price (RM)",
      accessor: "price",
    },
    {
      Header: "Creation Date",
      accessor: (d) => {
        return moment(d.createdAt).local().format("DD-MM-YYYY hh:mm:ss A");
      },
    },
    {
      Header: "Modified Date",
      accessor: (d) => {
        return moment(d.modifiedAt).local().format("DD-MM-YYYY hh:mm:ss A");
      },
    },
    {
      Header: "Created By",
      accessor: "created_by.username",
    },
    {
      Header: "Modified By",
      accessor: "last_modified_by.username",
    },
    {
      Header: " ",
      Cell: ({ row }) => (
        <>
          <div
            className="btn-table"
            onClick={() =>
              history.push(
                WebUrl._ELEVATOR_MODELS_CREATE_OR_UPDATE.replace(
                  ":id",
                  row.original.serialNumber
                ),
                { details: row.original }
              )
            }
          >
            <i className="fa fa-edit"></i>
          </div>
          <div
              className="btn-table last-btn"
              title="Delete Elevator Model"
              onClick={() => {
                Notiflix.Confirm.show(
                  "Confirmation",
                  "Do you sure to delete elevator model?",
                  "Yes",
                  "No",
                  function () {
                    deleteElevatorModel(row.original);
                  }
                );
              }}
            >
              <i className="fa fa-trash"></i>
            </div>
        </>
      ),
      disableSortBy: true,
      disableFilters: true,
      style: { overflow: "visible", textAlign: "right" },
      sticky: "right",

    },
  ]);

  return (
    <>
      <div className="float-xl-right">
        <button
          type="type"
          className="btn btn-sm btn-themed"
          onClick={() =>
            history.push(
              WebUrl._ELEVATOR_MODELS_CREATE_OR_UPDATE.replace(":id", "create")
            )
          }
        >
          <i className="fa fa-plus mr-1"></i>Add Elevator Model
        </button>
      </div>
      <h1 className="page-header">Elevator Model Management</h1>

      <div id="elevatorsTable">
        <ReactTable
          columns={_COLUMN}
          data={elevatorModels}
          getRowProps={(row) => {
            return {
              onDoubleClick: () =>
                history.push(
                  WebUrl._ELEVATOR_MODELS_CREATE_OR_UPDATE.replace(
                    ":id",
                    row.original.serialNumber
                  ),
                  { details: row.original }
                ),
              onClick: (e) => {
                setSelectedRow(row.id);
              },
              class: row.id == selectedRow && "table-row-selected",
            };
          }}
          filterable
          canPreviousPage={true}
          canNextPage={true}
          ref={_tableRef}
        />
      </div>
    </>
  );
};
