
import React, { useState, useEffect, useRef } from "react";
import Notiflix from "notiflix";
import { Config, ConfigEnum } from 'util/Config';
import { InputTypes, SelectField, WebUrl, ApiKey } from "util/Constant";
import { Row, Col, Card, CardBody, CardHeader, CardFooter } from 'reactstrap';
import { useLocation } from "react-router-dom";
import classnames from 'classnames';
import CustomerDao from "data/CustomerDao.js";
import InputHoc from "components/form/InputHoc.js";
import { useForm, useFieldArray } from "react-hook-form";
import { useRecoilState, useSetRecoilState } from "recoil";
import { appState, natureOfBusinessesState, blacklistState, customerFormState } from "recoil/Atoms.js";

let configInstance = Config.getInstance();
var token = configInstance.getValue(ConfigEnum._TOKEN);

const PersonalInfo = ({ customerFormValue, setCustomerFormState, setAppState, resetStateValue }) => {
    const { register, handleSubmit, control, errors, reset } = useForm({});
    const [isDetailEditableState, setDetailEditableState] = useState(false);

    useEffect(() => {
        if (customerFormValue) {
            reset({ 
                honorific: customerFormValue.honorific, 
                shortName: customerFormValue.shortName, 
                fullName: customerFormValue.fullName, 
                icNumber: customerFormValue.icNumber, 
                gender: customerFormValue.gender, 
                companyName: customerFormValue.companyName, 
                ssmNumber: customerFormValue.ssmNumber, 
                department: customerFormValue.department, 
                jobTitle: customerFormValue.jobTitle, 
                remarks: customerFormValue.remarks
            });
        }
    }, [customerFormValue])

    useEffect(() => {
        return reset;
    }, []);

    const onSubmit = async (data) => {
        setAppState(prevState => ({ ...prevState, isBusy: true }));
        let stateData = {};

        setCustomerFormState(prevState => {
            let customerData = {};

            customerData['id'] = customerFormValue.id;
            customerData['honorific'] = data.honorific;
            customerData['shortName'] = data.shortName;
            customerData['fullName'] = data.fullName;
            customerData['icNumber'] = data.icNumber;
            customerData['gender'] = data.gender;
            customerData['companyName'] = data.companyName;
            customerData['ssmNumber'] = data.ssmNumber;
            customerData['department'] = data.department;
            customerData['jobTitle'] = data.jobTitle;
            customerData['remarks'] = data.remarks;
            customerData['phones'] = customerFormValue.phones;
            customerData['emails'] = customerFormValue.emails;
            customerData['addresses'] = customerFormValue.addresses;
            customerData['websites'] = null;
            customerData['natureOfBusinesses'] = customerFormValue.natureOfBusinesses;
            customerData['blacklists'] = customerFormValue.blacklists;

            stateData = { ...prevState, ...customerData };

            return { ...prevState, ...customerData }
        });

        let dao = new CustomerDao();
        await dao.editCustomer(stateData, token).then(responseJson => {
            if (responseJson[ApiKey._API_SUCCESS_KEY]) {
                Notiflix.Report.Success('Success', "Customer Info Updated.");
            } else {
                Notiflix.Report.Failure('Error', 'Failed to update customer info. Please try again later');
            }
        })

        setAppState(prevState => ({ ...prevState, isBusy: false }));
    }

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <strong className="mt-1">Personal Info</strong>
            <Row>
                <Col><InputHoc defaultValue={customerFormValue.honorific} name="honorific" label="Honorific" inputType={InputTypes.INPUT} ref={register({ required: 'Honorific is required.' })} disabled={!isDetailEditableState} /></Col>
                <Col><InputHoc defaultValue={customerFormValue.shortName} name="shortName" label="Short Name" inputType={InputTypes.INPUT} ref={register({ required: 'Short Name is required.' })} disabled={!isDetailEditableState} /></Col>
            </Row>
            <Row>
                <Col><InputHoc defaultValue={customerFormValue.fullName} name="fullName" label="Full Name" inputType={InputTypes.INPUT} ref={register({ required: 'Full Name is required.' })} disabled={!isDetailEditableState} /></Col>
                <Col><InputHoc defaultValue={customerFormValue.icNumber} name="icNumber" label="IC Number" inputType={InputTypes.INPUT} ref={register({ required: 'IC Number is required.' })} disabled={!isDetailEditableState} /></Col>
            </Row>
            <Row>
                <Col xl={6}><InputHoc defaultValue={customerFormValue.gender} name="gender" label="Gender" inputType={InputTypes.SELECT} options={SelectField._GENDER_OPTIONS} control={control} ref={register({ required: 'Gender is required.' })} disabled={!isDetailEditableState} /></Col>
            </Row>

            <strong className="mt-2">Company Info</strong>
            <Row>
                <Col><InputHoc defaultValue={customerFormValue.companyName} name='companyName' label='Company Name' inputType={InputTypes.INPUT} ref={register} disabled={!isDetailEditableState} /></Col>
                <Col><InputHoc defaultValue={customerFormValue.ssmNumber} name='ssmNumber' label='SSM Number' inputType={InputTypes.INPUT} ref={register} disabled={!isDetailEditableState} /></Col>
            </Row>
            <Row>
                <Col><InputHoc defaultValue={customerFormValue.department} name='department' label='Department' inputType={InputTypes.INPUT} ref={register} disabled={!isDetailEditableState} /></Col>
                <Col><InputHoc defaultValue={customerFormValue.jobTitle} name='jobTitle' label='Job Title' inputType={InputTypes.INPUT} ref={register} disabled={!isDetailEditableState} /></Col>
            </Row>

            <button
                type="submit"
                className={classnames("mr-2 btn btn-themed", { "d-none": !isDetailEditableState })}
                disabled={isDetailEditableState ? false : true}>Save</button>
            <button
                type="button"
                className={classnames("btn", { "btn-themed": !isDetailEditableState, "btn-default": isDetailEditableState })}
                onClick={() => {
                    resetStateValue();
                    setDetailEditableState(!isDetailEditableState);
                }}>
                {isDetailEditableState ? 'Cancel' : 'Edit'}
            </button>
        </form>
    );
};

const ContactInfo = ({ customerFormValue, setCustomerFormState, setAppState, resetStateValue }) => {
    const { register, handleSubmit, control, errors, reset } = useForm({});
    const {
        fields: phoneFields,
        append: phoneAppend,
        remove: phoneRemove
    } = useFieldArray({ control, name: "phones" });
    const {
        fields: emailFields,
        append: emailAppend,
        remove: emailRemove
    } = useFieldArray({ control, name: "emails" });

    const [isContactEditableState, setContactEditableState] = useState(false);

    useEffect(() => {
        if (customerFormValue.emails) {
            reset({ phones: customerFormValue.phones, emails: customerFormValue.emails });
        }
    }, [customerFormValue])

    useEffect(() => {
        return reset;
    }, []);

    const onSubmit = async (data) => {
        setAppState(prevState => ({ ...prevState, isBusy: true }));
        let stateData = {};

        setCustomerFormState(prevState => {
            let customerData = {};

            customerData['id'] = customerFormValue.id;
            customerData['honorific'] = customerFormValue.honorific;
            customerData['shortName'] = customerFormValue.shortName;
            customerData['fullName'] = customerFormValue.fullName;
            customerData['icNumber'] = customerFormValue.icNumber;
            customerData['gender'] = customerFormValue.gender;
            customerData['companyName'] = customerFormValue.companyName;
            customerData['ssmNumber'] = customerFormValue.ssmNumber;
            customerData['department'] = customerFormValue.department;
            customerData['jobTitle'] = customerFormValue.jobTitle;
            customerData['remarks'] = customerFormValue.remarks;
            customerData['phones'] = data.phones;
            customerData['emails'] = data.emails;
            customerData['addresses'] = customerFormValue.addresses;
            customerData['websites'] = null;
            customerData['natureOfBusinesses'] = customerFormValue.natureOfBusinesses;
            customerData['blacklists'] = customerFormValue.blacklists;

            stateData = { ...prevState, ...customerData };

            return { ...prevState, ...customerData }
        });

        let dao = new CustomerDao();
        await dao.editCustomer(stateData, token).then(responseJson => {
            if (responseJson[ApiKey._API_SUCCESS_KEY]) {
                Notiflix.Report.Success('Success', "Customer Info Updated.");
            } else {
                Notiflix.Report.Failure('Error', 'Failed to update customer info. Please try again later');
            }
        })

        setAppState(prevState => ({ ...prevState, isBusy: false }));
    }

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <strong className="mt-1">Phone</strong>
            {
                phoneFields.map((item, index) => {
                    return (
                        <Row key={item.id}>
                            <Col><InputHoc defaultValue={item.number} name={`phones[${index}].number`} label="Phone Number" ref={register({ required: 'Phone Number is required.' })} disabled={!isContactEditableState} /></Col>
                            <Col><InputHoc defaultValue={item.type} name={`phones[${index}].type`} label="Type" inputType={InputTypes.SELECT} options={SelectField._PHONE_TYPES} control={control} rules={{ required: 'Phone Type is required.' }} disabled={!isContactEditableState} /></Col>
                            {isContactEditableState &&
                                <Col xl={1}>
                                    {index == 0 ? <div className="btn-round expand-theme m-t-5" onClick={() => phoneAppend()}><i className="fas fa-plus"></i></div> :
                                        <div className="btn-round expand-red m-t-5" onClick={() => phoneRemove(index)}><i className="fas fa-trash-alt"></i></div>
                                    }
                                </Col>
                            }
                        </Row>
                    );
                })
            }
            <strong className="mt-5">Email</strong>
            {
                emailFields.map((item, index) => {
                    return (
                        <Row key={item.id}>
                            <Col><InputHoc defaultValue={item.email} name={`emails[${index}].email`} label="Email" ref={register({ required: 'Email is required.', pattern: { value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i, message: "invalid email address" } })} disabled={!isContactEditableState} /></Col>
                            <Col><InputHoc defaultValue={item.type} name={`emails[${index}].type`} label="Type" inputType={InputTypes.SELECT} options={SelectField._EMAIL_TYPES} control={control} rules={{ required: 'Email Type is required.' }} disabled={!isContactEditableState} /></Col>
                            {isContactEditableState &&
                                <Col xl={1}>
                                    {index == 0 ? <div className="btn-round expand-theme m-t-5" onClick={() => emailAppend()}><i className="fas fa-plus"></i></div> :
                                        <div className="btn-round expand-red m-t-5" onClick={() => emailRemove(index)}><i className="fas fa-trash-alt"></i></div>
                                    }
                                </Col>
                            }
                        </Row>
                    );
                })
            }
            <button
                type="submit"
                className={classnames("mr-2 btn btn-themed", { "d-none": !isContactEditableState })}
                disabled={isContactEditableState ? false : true}>Save</button>
            <button
                type="button"
                className={classnames("btn", { "btn-themed": !isContactEditableState, "btn-default": isContactEditableState })}
                onClick={() => {
                    resetStateValue();
                    setContactEditableState(!isContactEditableState);
                }}>
                {isContactEditableState ? 'Cancel' : 'Edit'}
            </button>
        </form>
    );
};

const LocationInfo = ({ customerFormValue, setCustomerFormState, setAppState, resetStateValue }) => {
    const { register, handleSubmit, control, errors, reset } = useForm({});
    const { fields, append, remove } = useFieldArray({
        control,
        name: 'addresses'
    });
    const [isAddressEditableState, setAddressEditableState] = useState(false);

    useEffect(() => {
        if (customerFormValue.emails) {
            reset({ addresses: customerFormValue.addresses });
        }
    }, [customerFormValue])

    useEffect(() => {
        return reset;
    }, []);

    const onSubmit = async (data) => {
        setAppState(prevState => ({ ...prevState, isBusy: true }));
        let stateData = {};

        setCustomerFormState(prevState => {
            let customerData = {};

            customerData['id'] = customerFormValue.id;
            customerData['honorific'] = customerFormValue.honorific;
            customerData['shortName'] = customerFormValue.shortName;
            customerData['fullName'] = customerFormValue.fullName;
            customerData['icNumber'] = customerFormValue.icNumber;
            customerData['gender'] = customerFormValue.gender;
            customerData['companyName'] = customerFormValue.companyName;
            customerData['ssmNumber'] = customerFormValue.ssmNumber;
            customerData['department'] = customerFormValue.department;
            customerData['jobTitle'] = customerFormValue.jobTitle;
            customerData['remarks'] = customerFormValue.remarks;
            customerData['phones'] = customerFormValue.phones;
            customerData['emails'] = customerFormValue.emails;
            customerData['addresses'] = data.addresses;
            customerData['websites'] = null;
            customerData['natureOfBusinesses'] = customerFormValue.natureOfBusinesses;
            customerData['blacklists'] = customerFormValue.blacklists;

            stateData = { ...prevState, ...customerData };

            return { ...prevState, ...customerData }
        });

        let dao = new CustomerDao();
        await dao.editCustomer(stateData, token).then(responseJson => {
            if (responseJson[ApiKey._API_SUCCESS_KEY]) {
                Notiflix.Report.Success('Success', "Customer Info Updated.");
            } else {
                Notiflix.Report.Failure('Error', 'Failed to update customer info. Please try again later');
            }
        })

        setAppState(prevState => ({ ...prevState, isBusy: false }));
    }

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            {isAddressEditableState &&
                <button type="type" className="btn btn-sm btn-themed mb-2" onClick={() => append()}><i className="fa fa-plus mr-1"></i>Add Address</button>
            }
            {
                fields.map((item, index) => {
                    return (
                        <div className="mt-2 mb-2">
                            <strong>Address {index + 1}</strong>
                            <Row>
                                <Col><InputHoc defaultValue={item.addressLine1} name={`addresses[${index}].addressLine1`} label="Address Line 1" ref={register({ required: 'Address Line 1 is required.' })} disabled={!isAddressEditableState} /></Col>
                                <Col><InputHoc defaultValue={item.addressLine2} name={`addresses[${index}].addressLine2`} label="Address Line 2" ref={register({ required: false })} disabled={!isAddressEditableState} /></Col>
                            </Row>
                            <Row>
                                <Col><InputHoc defaultValue={item.state} name={`addresses[${index}].state`} label="State" inputType={InputTypes.SELECT} options={SelectField._STATE_OPTIONS} control={control} rules={{ required: 'State is required.' }} disabled={!isAddressEditableState} /></Col>
                                <Col><InputHoc defaultValue={item.country} name={`addresses[${index}].country`} label="Country" inputType={InputTypes.SELECT} options={SelectField._COUNTRY_OPTIONS} control={control} rules={{ required: 'Country is required.' }} disabled={!isAddressEditableState} /></Col>
                            </Row>
                            <Row>
                                <Col><InputHoc defaultValue={item.city} name={`addresses[${index}].city`} label="City" ref={register({ required: 'City is required.' })} disabled={!isAddressEditableState} /></Col>
                                <Col><InputHoc defaultValue={item.postcode} name={`addresses[${index}].postcode`} label="Postcode" ref={register({ required: 'Postcode is required.' })} disabled={!isAddressEditableState} /></Col>
                            </Row>
                            <Row>
                                <Col><InputHoc defaultValue={item.category} name={`addresses[${index}].category`} label="Type" inputType={InputTypes.SELECT} options={SelectField._ADDRESS_TYPES} control={control} rules={{ required: 'Type is required.' }} disabled={!isAddressEditableState} /></Col>
                                {isAddressEditableState &&
                                    <Col className="pull-right">{index !== 0 && <div className="btn-round expand-red m-t-5 pull-right" onClick={() => remove(index)}><i className="fas fa-trash-alt"></i></div>}</Col>
                                }
                            </Row>
                        </div>
                    );
                })
            }
            <button
                type="submit"
                className={classnames("mr-2 btn btn-themed", { "d-none": !isAddressEditableState })}
                disabled={isAddressEditableState ? false : true}>Save</button>
            <button
                type="button"
                className={classnames("btn", { "btn-themed": !isAddressEditableState, "btn-default": isAddressEditableState })}
                onClick={() => {
                    resetStateValue();
                    setAddressEditableState(!isAddressEditableState);
                }}>
                {isAddressEditableState ? 'Cancel' : 'Edit'}
            </button>
        </form>
    );
};

const OtherInfo = ({ customerFormValue, setCustomerFormState, setAppState, resetStateValue, natureOfBusinessesOptions, blacklistOptions }) => {
    const { register, handleSubmit, control, errors, reset } = useForm({});
    const { fields, append, remove } = useFieldArray({
        control,
        name: 'websites'
    });
    const _location = useLocation();
    const [isOtherInfoEditableState, setOtherInfoEditableState] = useState(false);
    const [custNob, setCustNob] = useState([]);
    const [custBlacklist, setCustBlacklist] = useState([]);
    const [custNobString, setCustNobString] = useState('');
    const [custBlacklistString, setCustBlacklistString] = useState('');

    useEffect(() => {
        if (customerFormValue.websites || customerFormValue.natureOfBusinesses || customerFormValue.blacklists) {
            reset({
                websites: customerFormValue.websites,
                natureOfBusinesses: custNob,
                blacklists: custBlacklist
            });
        }
    }, [customerFormValue, custNob, custBlacklist])

    useEffect(() => {
        let customerNob = []; let customerBlacklist = [];
        let nobString = ''; let blacklistString = '';

        if (_location.state.natureOfBusinesses !== undefined) {
            _location.state.natureOfBusinesses.map((nob) => {
                customerNob.push({ label: nob.nob_name, value: nob.id });
                nobString = nobString + nob.nob_name + "  ";
            })
            setCustNob(customerNob);
            setCustNobString(nobString);
        }

        if (_location.state.blacklists !== undefined) {
            _location.state.blacklists.map((blacklist) => {
                customerBlacklist.push({ label: blacklist.name, value: blacklist.id });
                blacklistString = blacklistString + blacklist.name + "  ";
            })
            setCustBlacklist(customerBlacklist);
            setCustBlacklistString(blacklistString);
        }
    }, [customerFormValue.natureOfBusinesses, customerFormValue.blacklists])

    useEffect(() => {
        return reset;
    }, []);

    const onSubmit = async (data) => {
        setAppState(prevState => ({ ...prevState, isBusy: true }));
        let stateData = {};

        setCustomerFormState(prevState => {
            let customerData = {};

            customerData['id'] = customerFormValue.id;
            customerData['honorific'] = customerFormValue.honorific;
            customerData['shortName'] = customerFormValue.shortName;
            customerData['fullName'] = customerFormValue.fullName;
            customerData['icNumber'] = customerFormValue.icNumber;
            customerData['gender'] = customerFormValue.gender;
            customerData['companyName'] = customerFormValue.companyName;
            customerData['ssmNumber'] = customerFormValue.ssmNumber;
            customerData['department'] = customerFormValue.department;
            customerData['jobTitle'] = customerFormValue.jobTitle;
            customerData['remarks'] = customerFormValue.remarks;
            customerData['phones'] = customerFormValue.phones;
            customerData['emails'] = customerFormValue.emails;
            customerData['addresses'] = customerFormValue.addresses;

            let nobArr = [];
            let blacklistArr = [];

            if (data.natureOfBusinesses !== null) {
                data.natureOfBusinesses.map((nob) => {
                    nobArr.push(nob.value);
                    customerData['natureOfBusinesses'] = nobArr;
                });
            } else {
                customerData['natureOfBusinesses'] = null;
            }

            if (data.blacklists !== null) {
                data.blacklists.map((blacklist) => {
                    blacklistArr.push(blacklist.value);
                    customerData['blacklists'] = blacklistArr;
                });
            } else {
                customerData['blacklists'] = null;
            }

            customerData['websites'] = data.websites;

            stateData = { ...prevState, ...customerData };

            return { ...prevState, ...customerData }
        });

        let dao = new CustomerDao();
        await dao.editCustomer(stateData, token).then(responseJson => {
            if (responseJson[ApiKey._API_SUCCESS_KEY]) {
                let nobString = ''; let blacklistString = '';

                if (data.natureOfBusinesses !== null) {
                    setCustNob(data.natureOfBusinesses);
                    data.natureOfBusinesses.map((nob) => {
                        nobString = nobString + nob.label + "  ";
                    });
                    setCustNobString(nobString);
                } else {
                    setCustNob([]);
                    setCustNobString('');
                }

                if (data.blacklists !== null) {
                    setCustBlacklist(data.blacklists);
                    data.blacklists.map((blacklist) => {
                        blacklistString = blacklistString + blacklist.label + "  ";
                    });
                    setCustBlacklistString(blacklistString);
                } else {
                    setCustBlacklist([]);
                    setCustBlacklistString('');
                }

                Notiflix.Report.Success('Success', "Customer Info Updated.");
            } else {
                Notiflix.Report.Failure('Error', 'Failed to update customer info. Please try again later');
            }
        })

        setAppState(prevState => ({ ...prevState, isBusy: false }));
    }

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            {isOtherInfoEditableState &&
                <button type="button" className="btn btn-sm btn-themed mb-2" onClick={() => append()}><i className="fa fa-plus mr-1"></i>Add Website</button>
            }
            {
                fields.map((item, index) => {
                    return (
                        <div className="mt-2 mb-2">
                            <strong>Website {index + 1}</strong>
                            <Row>
                                <Col><InputHoc defaultValue={item.url ? item.url : ''} name={`websites[${index}].url`} label="URL" ref={register({ required: 'Website is required.' })} disabled={!isOtherInfoEditableState} /></Col>
                                {isOtherInfoEditableState &&
                                    <Col xs={1}><div className="btn-round expand-red m-t-5 pull-right" onClick={() => remove(index)}><i className="fas fa-trash-alt"></i></div></Col>
                                }
                            </Row>
                        </div>
                    );
                })
            }

            {isOtherInfoEditableState ?
                <>
                    <InputHoc defaultValue={custNob} label='Nature of Business' isMulti={true} name='natureOfBusinesses' inputType={InputTypes.SELECT} control={control} options={natureOfBusinessesOptions} ref={register} error={errors?.natureOfBusinesses?.message} />
                    <InputHoc defaultValue={custBlacklist} label='Blacklist' isMulti={true} name='blacklists' inputType={InputTypes.SELECT} control={control} options={blacklistOptions} ref={register} error={errors?.blacklists?.message} />
                </>
                :
                <>
                    <InputHoc defaultValue={custNobString} label="Nature of Businesses" disabled={true} />
                    <InputHoc defaultValue={custBlacklistString} label="Blacklists" disabled={true} />
                </>
            }

            <button
                type="submit"
                className={classnames("mr-2 btn btn-themed", { "d-none": !isOtherInfoEditableState })}
                disabled={isOtherInfoEditableState ? false : true}>Save</button>
            <button
                type="button"
                className={classnames("btn", { "btn-themed": !isOtherInfoEditableState, "btn-default": isOtherInfoEditableState })}
                onClick={() => {
                    resetStateValue();
                    setOtherInfoEditableState(!isOtherInfoEditableState);
                }}>
                {isOtherInfoEditableState ? 'Cancel' : 'Edit'}
            </button>
        </form>
    );
};

const EditCustomer = props => {
    const _location = useLocation();
    const [natureOfBusinessesOptions] = useRecoilState(natureOfBusinessesState);
    const [blacklistOptions] = useRecoilState(blacklistState);
    const [customerFormValue, setCustomerFormState] = useRecoilState(customerFormState);
    const setAppState = useSetRecoilState(appState);

    useEffect(() => {
        setCustomerFormState(prevState => {
            let customerData = {};

            customerData['id'] = _location.state.id;
            customerData['honorific'] = _location.state.honorific;
            customerData['shortName'] = _location.state.shortName;
            customerData['fullName'] = _location.state.fullName;
            customerData['icNumber'] = _location.state.icNumber;
            customerData['gender'] = _location.state.gender;
            customerData['companyName'] = _location.state.companyName ? _location.state.companyName : null;
            customerData['ssmNumber'] = _location.state.ssmNumber ? _location.state.ssmNumber : null;
            customerData['department'] = _location.state.department ? _location.state.department : null;
            customerData['jobTitle'] = _location.state.jobTitle ? _location.state.jobTitle : null;
            customerData['remarks'] = _location.state.remarks ? _location.state.remarks : null;
            customerData['phones'] = _location.state.phones ? _location.state.phones : null;
            customerData['emails'] = _location.state.emails ? _location.state.emails : null;
            customerData['addresses'] = _location.state.addresses ? _location.state.addresses : null;
            customerData['websites'] = _location.state.websites ? _location.state.websites : null;

            let customerNob = [];
            let customerBlacklist = [];

            if (_location.state.natureOfBusinesses !== null) {
                _location.state.natureOfBusinesses.map((nob) => {
                    customerNob.push(nob.id);
                })
                customerData['natureOfBusinesses'] = customerNob;
            }

            if (_location.state.blacklists !== null) {
                _location.state.blacklists.map((blacklist) => {
                    customerBlacklist.push(blacklist.id);
                })
                customerData['blacklists'] = customerBlacklist;
            }

            return { ...prevState, ...customerData }
        });
    }, [])

    const resetStateValue = () => {
        setCustomerFormState(prevState => {
            return { ...prevState, ...customerFormValue }
        });
    }

    return (
        <Row>
            <Col md={6}>
                <Card className="mb-3">
                    <CardHeader>
                        Personal Details
                    </CardHeader>
                    <CardBody>
                        <PersonalInfo
                            customerFormValue={customerFormValue}
                            setCustomerFormState={setCustomerFormState}
                            setAppState={setAppState}
                            resetStateValue={resetStateValue}
                        />
                    </CardBody>
                </Card>
                <Card className="mb-3">
                    <CardHeader>
                        Contact Info
                    </CardHeader>
                    <CardBody>
                        <ContactInfo
                            customerFormValue={customerFormValue}
                            setCustomerFormState={setCustomerFormState}
                            setAppState={setAppState}
                            resetStateValue={resetStateValue}
                        />
                    </CardBody>
                </Card>
            </Col>
            <Col md={6}>
                <Card className="mb-3">
                    <CardHeader>
                        Location Info
                    </CardHeader>
                    <CardBody>
                        <LocationInfo
                            customerFormValue={customerFormValue}
                            setCustomerFormState={setCustomerFormState}
                            setAppState={setAppState}
                            resetStateValue={resetStateValue}
                        />
                    </CardBody>
                </Card>
                <Card className="mb-3">
                    <CardHeader>
                        Other Info
                    </CardHeader>
                    <CardBody>
                        <OtherInfo
                            customerFormValue={customerFormValue}
                            setCustomerFormState={setCustomerFormState}
                            setAppState={setAppState}
                            resetStateValue={resetStateValue}
                            natureOfBusinessesOptions={natureOfBusinessesOptions}
                            blacklistOptions={blacklistOptions}
                        />
                    </CardBody>
                </Card>
            </Col>
        </Row >
    )
}

export default EditCustomer;
