import { ApiUrl } from 'util/Constant';
import DataAccessObject from './DataAccessObject';

/// <summary>
/// Author: Christopher Chan
/// </summary>
class ElevatorModelDao extends DataAccessObject {

    async getElevatorModels(token) {
        return this.get(ApiUrl._API_ELEVATOR_MODELS, token);
    }

    async createOrUpdateElevatorModel(data, token) {
        return this.post(ApiUrl._API_ELEVATOR_MODELS, data, token);
    }

    async deleteElevatorModel(data, token) {
        return this.delete(ApiUrl._API_ELEVATOR_MODELS + "/delete/" + data, token);
    }

    async updateElevatorPart(data, token) {
        return this.put(ApiUrl._API_MODIFY_ELEVATOR_PART, data, token);
    }

    async createElevatorPart(data, token) {
        return this.post(ApiUrl._API_CREATE_ELEVATOR_PART, data, token);
    }

    async deleteElevatorPart(data, token) {
        return this.delete(ApiUrl._API_DELETE_ELEVATOR_PART+ "/" + data, token);
    }
}

export default ElevatorModelDao;
