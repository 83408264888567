import { ApiUrl } from 'util/Constant';
import DataAccessObject from './DataAccessObject';

/// <summary>
/// Author: Christopher Chan
/// </summary>
class ContractDao extends DataAccessObject {

    async getContracts(token) {
        return this.get(ApiUrl._API_CONTRACT, token);
    }

    async createOrUpdateContract(data, token) {
        return this.post(ApiUrl._API_CONTRACT, data, token);
    }

    async updateContractStatus(data, token) {
        return this.postForm(ApiUrl._API_UPDATE_CONTRACT_STATUS, data, token);
    }


    async updateContract(data, token) {
        return this.post(ApiUrl._API_CONTRACT, data, token)
    }

    async getContractSites(token) {
        return this.get(ApiUrl._API_GET_CONTRACT_SITES, token);
    }

    async getContractBelongsToSite(id, token) {
        return this.get(`${ApiUrl._API_GET_SITE_CONTRACTS}/${id}`, token);
    }
}

export default ContractDao;
