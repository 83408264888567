import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { useHistory, useLocation,useParams } from 'react-router-dom';
import CommonDao from "data/CommonDao.js";
import CustomerDao from "data/CustomerDao.js";
import { ApiKey } from "util/Constant";
import { Config, ConfigEnum } from 'util/Config';
import NavigationButton from 'components/buttons/NavigationButton';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import MoreVert from '@mui/icons-material/MoreVert';
import PersonPinIcon from '@mui/icons-material/PersonPin';
import LocationOn from '@mui/icons-material/LocationOn';
import ContactPage from '@mui/icons-material/ContactPage';
import PersonalInfo from 'components/customer/PersonalInfo';
import ContactInfo from 'components/customer/ContactInfo';
import LocationInfo from 'components/customer/LocationInfo';
import OtherInfo from 'components/customer/OtherInfo';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import PropTypes from 'prop-types';
import { useRecoilState} from "recoil";
import { natureOfBusinessesState, blacklistState } from "recoil/Atoms.js";
import classnames from 'classnames';

function TabPanel(props) {
        const { children, value, index, ...other } = props;
      
        return (
          <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
          >
            {value === index && (
              <Box sx={{ p: 3 }}>
                <Typography>{children}</Typography>
              </Box>
            )}
          </div>
        );
}
      
TabPanel.propTypes = {
        children: PropTypes.node,
        index: PropTypes.number.isRequired,
        value: PropTypes.number.isRequired,
};

///<summary>
///Author: Nicholas Lai
///</summary>
export default () => {
    const [natureOfBusinessesOptions, setNatureOfBusinessesState] = useRecoilState(natureOfBusinessesState);
    const [blacklistOptions, setBlacklistState] = useRecoilState(blacklistState);
    const { watch } = useForm();
    const [isDisabled, setIsDisabled] = useState(true);
    const [customer, setCustomer] = useState([]);
    let { id } = useParams();
    let configInstance = Config.getInstance();
    var token = configInstance.getValue(ConfigEnum._TOKEN);
    let classes = classnames('label custom-status-label', {
        'label-danger': customer?.deleted_date !== null,
        'label-success': customer?.deleted_date === null,
    })
    const [tabIndex, setTabIndex] = useState(0);

    const handleChange = (event, newValue) => {

            setTabIndex(newValue);
   };


    /// <summary>
    /// Author: Nicholas Lai
    /// </summary>
    useEffect(() => {
        getNatureOfBusinessList();
        getBlacklist();
        getCustomerInfo(id);
    }, []);


   const getNatureOfBusinessList = async () => {
        let dao = new CommonDao();
        await dao.getNatureOfBusinessList().then((response) => {
            if (response[ApiKey._API_SUCCESS_KEY]) {
                var data = response[ApiKey._API_DATA_KEY];
                var selectOptions = [];
                data.map((item) => {
                    selectOptions.push({ label: item.nob_name, value: item.id });
                })
                setNatureOfBusinessesState(selectOptions);
            }
        })
    };

    const getBlacklist = async () => {
        let dao = new CommonDao();
        await dao.getBlacklist().then((response) => {
            if (response[ApiKey._API_SUCCESS_KEY]) {
                var data = response[ApiKey._API_DATA_KEY];
                var selectOptions = [];
                data.map((item) => {
                    selectOptions.push({ label: item.name, value: item.id });
                })
                setBlacklistState(selectOptions);
            }
        })
    };

    const getCustomerInfo = async (id) => {
        let dao = new CustomerDao();
        await dao.getCustomer(id, token).then((response) => {
            if (response[ApiKey._API_SUCCESS_KEY]) {
                var data = response[ApiKey._API_DATA_KEY];
                setCustomer(data);
                console.log(data);
            }
        })
    };

    // ///<summary>
    // ///Author: Nicholas Lai
    // ///</summary>
    const toggleFalse = () => {
        setIsDisabled(false);
    };

    // ///<summary>
    // ///Author: Nicholas Lai
    // ///</summary>
    const toggleTrue = () => {
        setIsDisabled(true);
    };

    return <>
     <h1 className="page-header">
            <NavigationButton />
            {'View Customer'} ({customer?.honorific} {customer?.shortName})                   
                     <div className={classes}>
                        {
                                customer?.deleted_date !== null ? "Deleted" : "Active"
                        }
                    </div>
        </h1>
        <div className="float-xl-right">
           {customer?.deleted_date === null && <button type="type" className="btn btn-sm btn-themed mb-2" style={{marginRight:'10px'}} onClick={toggleFalse}><i className="fa fa-edit mr-1"></i>Edit</button> } 
            {!isDisabled &&  <button type="type" className="btn btn-sm btn-themed mb-2" onClick={toggleTrue}><i className="fa fa-edit mr-1"></i>Cancel</button>}
        </div>
           <Tabs
          
                value={tabIndex}
                onChange={handleChange}
                aria-label="icon position tabs example"
                >
                <Tab icon={<PersonPinIcon />} iconPosition="start" label="Personal Info" />
                <Tab icon={<ContactPage />} iconPosition="start" label="Contact Info" />
                <Tab icon={<LocationOn />} iconPosition="start" label="Location Info" />
                <Tab icon={<MoreVert />} iconPosition="start" label="Other Info" />
                
        </Tabs>
        <TabPanel value={tabIndex} index={0}>
        <PersonalInfo isDisabled={isDisabled} selectedCustomer={customer} />
      </TabPanel>
      <TabPanel value={tabIndex} index={1}>
        <ContactInfo isDisabled={isDisabled} selectedCustomer={customer}/>
      </TabPanel>
      <TabPanel value={tabIndex} index={2}>
        <LocationInfo isDisabled={isDisabled} selectedCustomer={customer}/>
      </TabPanel>
      <TabPanel value={tabIndex} index={3}>
        <OtherInfo
                isDisabled={isDisabled}
                natureOfBusinessesOptions={natureOfBusinessesOptions}
                blacklistOptions={blacklistOptions}
                selectedCustomer={customer}
        />
      </TabPanel>
        
    </>;
}