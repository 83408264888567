import React, { useEffect } from "react";
import { Card, CardHeader, CardBody, Row, Col, Nav, NavItem, NavLink } from 'reactstrap';
import Notiflix from "notiflix";
import { useForm, useFieldArray } from "react-hook-form";
import { Config, ConfigEnum } from 'util/Config';
import { InputTypes, SelectField, WebUrl, ApiKey } from "util/Constant";
import { stringIsNullOrEmpty } from "util/Utility.js";
import EnquiryDao from "data/EnquiryDao.js";
import InputHoc from "components/form/InputHoc.js";
import ImageGallery from "react-image-gallery";
import { Container, Button } from 'react-floating-action-button'
import DocViewer,{ DocViewerRenderers } from "react-doc-viewer";

const FloatingButton = () => {
        return (
                <Container>
                    <Button
                        tooltip="Submit Changes!"
                        icon="fas fa-paper-plane"
                        rotate={true}
                         />
                </Container>
            )
}

const EnquiryForm = ({ setAppState, toggle, enquiry, customerListOption ,isDisabled,setIsDisabled}) => {
        const { register, handleSubmit, control, errors, reset } = useForm({
            defaultValues: enquiry == null &&
                { sites: [{ siteProjectTitle: "", addressLine1: "", addressLine2: "", state: "", country: "", city: "", postcode: "" }] }
        });

        const images = [

         ];

         const docs = [
                { uri: "http://localhost:8000/enquiry/EQ-000031_jLAlsWYndW.pdf" }
        ];      

        const { fields, append, remove } = useFieldArray({
            keyName: 'fieldId',
            control,
            name: 'sites'
        });
    
        let configInstance = Config.getInstance();
        var token = configInstance.getValue(ConfigEnum._TOKEN);
    
        useEffect(() => {
            if (enquiry && enquiry.sites) {
                reset({ sites: enquiry.sites });
            }

        }, [enquiry])
    
        useEffect(() => {
            return reset;
        }, []);

        const onDownload = async(data) => {
                console.log(data);
                let dao = new EnquiryDao();
                await dao.getAttachments(data, token)
                .then((response) => {
                    const link = document.createElement('a');
                    link.href = URL.createObjectURL(response);
                    link.setAttribute("download","enquiry_attachments.zip");
                    link.click();
    
                    Notiflix.Report.Success(
                        'Success',
                        `Successfully downloaded.`,
                        'Okay'
                    );
                })
                .catch(error => {
                    console.log(error);
                    Notiflix.Report.Failure('Error', 'Operation Failed. ');
                })
                .finally(() => {
                    //Loading.Remove();
                });
        }
    
        const onSubmit = async (data) => {

                const formData = new FormData();
        
                if (data.sites !== null) {
                    data.sites.map((site, index) => {
                        formData.append(`sites[${index}][id]`, site.id);
                        formData.append(`sites[${index}][siteProjectTitle]`, site.siteProjectTitle);
                        formData.append(`sites[${index}][address][addressLine1]`, site.addressLine1);
                        formData.append(`sites[${index}][address][addressLine2]`, site.addressLine2);
                        formData.append(`sites[${index}][address][city]`, site.city);
                        formData.append(`sites[${index}][address][state]`, site.state);
                        formData.append(`sites[${index}][address][postcode]`, site.postcode);
                        formData.append(`sites[${index}][address][country]`, site.country);
                        console.log(data);
                        if (!stringIsNullOrEmpty(site.images)) {
                            site.images.map((image) => {
                                formData.append(`sites[${index}][address][images][]`, image);
                            })
                        }
        
                        if (!stringIsNullOrEmpty(site.files)) {
                            site.files.map((file) => {
                                formData.append(`sites[${index}][address][files][]`, file);
                            })
                        }
                    })
                }
        
                if (enquiry) {
                    formData.append("id", enquiry.id);
                    formData.append("customerId", enquiry.customer.id);
                } else {
                    formData.append("customerId", data.customerId);
                }
        
                let dao = new EnquiryDao();
                let action = '';
                
                action = dao.updateEnquiry(formData, token);
        
                await action.then(responseJson => {
                    if (responseJson[ApiKey._API_SUCCESS_KEY]) {
                        let data = responseJson[ApiKey._API_DATA_KEY];
                        console.log(data);
                        Notiflix.Report.Init({ plainText: false });
                        Notiflix.Report.Success(
                            'Success',
                            `Successfully updated enquiry, <b>${enquiry.enquiryId}</b>.`,
                            'Okay'
                        );
                        setIsDisabled(true);
                    } else {
                        Notiflix.Report.Failure('Error', 'Operation Failed. ' + responseJson[ApiKey._API_MESSAGE_KEY]);
                    }
                })
            }
    
        return (
            <form onSubmit={handleSubmit(onSubmit)}>
           <button type="button" className="btn btn-sm btn-themed mb-2" onClick={() => append()}><i className="fa fa-plus mr-1"></i>Add Site</button>   
          
                {
                    enquiry == null &&
                    <Row><Col><InputHoc defaultValue={enquiry && enquiry.customerId} name="customerId" label="Customer" inputType={InputTypes.SELECT} options={customerListOption} control={control} rules={{ required: 'Customer is required.' }} /></Col></Row>
                }
                {
                    fields.map((item, index) => {
                            let checkAttachments = false;
                            let itemExist = false;
                            if(item?.attachments?.length > 0 ) {
                                   
                                    checkAttachments = item.attachments.find(x => x.type === "pdf" || x.type === "PDF" || x.type === "docx" || x.type === "DOCX");
                         
                                    itemExist = item.attachments.find(x => x.type === "png" || x.type === "jpg" || x.type === "jpeg" || x.type === "JPG");

                                    if(itemExist) {
                                        images.push({
                                                original : 'http://localhost:8000/enquiry/' + itemExist.name,
                                                thumbnail : 'http://localhost:8000/enquiry/' +itemExist.name
                                        })
                                        console.log(images)
                                    }
                           }
                        return (
                                <Card className="mb-2">
                                   <CardBody className="scrollable-container" style={{ maxHeight: '65vh' }}>
                                     <div className="mt-2 mb-2" key={item.fieldId}>
                                        <Row>
                                        <Col style={{ alignSelf: 'center' }}><strong >Site {index + 1}</strong></Col>
                                        <Col><InputHoc disabled={isDisabled} defaultValue={item.id && item.id ? item.id : undefined} name={`sites[${index}].id`} ref={register()} style={{ display: 'none' }} /></Col>
                                        {index !== 0 &&
                                                <Col xs={1}><div className="btn-round expand-red m-t-5 pull-right" onClick={() => remove(index)}><i className="fas fa-trash-alt"></i></div></Col>
                                        }
                                        </Row>
                                        <Row>
                                        <Col><InputHoc disabled={isDisabled} defaultValue={item.siteProjectTitle && item.siteProjectTitle} name={`sites[${index}].siteProjectTitle`} label="Site Project Title" ref={register({ required: 'Site Project Title is required.' })}  error={errors?.sites ? errors?.sites[index]?.siteProjectTitle?.message : ""} showErrorMsg={true} /></Col>
                                        </Row>
                                        <Row>
                                        <Col><InputHoc disabled={isDisabled} defaultValue={item.address && item.address.addressLine1} name={`sites[${index}].addressLine1`} label="Address Line 1" ref={register({ required: 'Address Line 1 is required.' })} /></Col>
                                        <Col><InputHoc disabled={isDisabled} defaultValue={item.address && item.address.addressLine2} name={`sites[${index}].addressLine2`} label="Address Line 2" ref={register({ required: false })} /></Col>
                                        </Row>
                                        <Row>
                                        <Col><InputHoc disabled={isDisabled} defaultValue={item.address && item.address.city} name={`sites[${index}].city`} label="City" ref={register({ required: 'City is required.' })} /></Col>
                                        <Col><InputHoc disabled={isDisabled} defaultValue={item.address && item.address.postcode} name={`sites[${index}].postcode`} label="Postcode" ref={register({ required: 'Postcode is required.' })} /></Col>
                                        </Row>
                                        <Row>
                                        <Col><InputHoc disabled={isDisabled} defaultValue={item.address && item.address.state} name={`sites[${index}].state`} label="State" inputType={InputTypes.SELECT} options={SelectField._STATE_OPTIONS} control={control} rules={{ required: 'State is required.' }} /></Col>
                                        <Col><InputHoc disabled={isDisabled} defaultValue={item.address && item.address.country} name={`sites[${index}].country`} label="Country" inputType={InputTypes.SELECT} options={SelectField._COUNTRY_OPTIONS} control={control} rules={{ required: 'Country is required.' }} /></Col>
                                        </Row>
                                        <Row>
                                        <Col> {
                                                !isDisabled &&
                                                <InputHoc disabled={isDisabled} name={`sites[${index}].images`} label="Images" inputType={InputTypes.ATTACHMENT} control={control} singleImage={false} ref={register({ required: false })} />
                                        }
                                        {
                                                itemExist && 
                                                <ImageGallery items={images} showThumbnails={false} showPlayButton={false} showBullets={true}/>
                                        }          
                                        </Col>
                                        <Col>
                                        {
                                                !isDisabled && 
                                                <InputHoc disabled={isDisabled} name={`sites[${index}].files`} label="Extra Files" inputType={InputTypes.FILEUPLOAD} control={control} multiple={true} ref={register({ required: false })} />
                                        }
                                        {
                                                checkAttachments &&    
                                                <button type="button" className="btn btn-sm btn-themed mb-2" onClick={() => onDownload(item?.id)}><i className="fa fa-download mr-1"></i>Download Attachments</button>    
                                        }
                                        </Col>
                                        </Row>
                                   </div>
                                  </CardBody>
                                </Card>            
                        );
                    })
                }
                <Row>
                </Row>
                 {
                         !isDisabled && <FloatingButton />
                 } 
            </form>
        );
    }

    export default EnquiryForm;