import React, { useMemo, useState, useEffect } from "react";
import { Card, CardBody, CardHeader, Col, Row } from "reactstrap";
import classnames from "classnames";
import moment from "moment";
import { FaThumbsUp, FaThumbsDown, FaShoppingCart,FaRegFileAlt } from "react-icons/fa";
import { Config, ConfigEnum } from "util/Config";
import ReactApexChart from "react-apexcharts";
import { IconContext } from "react-icons";

///<summary>
///Author: Christopher Chan
///</summary>
export default () => {
  let configInstance = Config.getInstance();
  var token = configInstance.getValue(ConfigEnum._TOKEN);

  /// <summary>
  /// Author: Christopher Chan
  /// </summary>
  useEffect(() => {

  }, []);

  const _COLUMN = useMemo(() => [
    {
      Header: "Enquiry ID",
      accessor: "enquiryId",
    },
    {
      Header: "Customer",
      accessor: "customer.fullName",
    },
    {
      Header: "Created On",
      Cell: ({ row }) => {
        return moment(row.original.createdAt).format("Do MMMM YYYY");
      },
    },
    {
      Header: "Ongoing Days",
      Cell: ({ row }) => {
        console.log(row.original.completedAt);
        if (row.original.completedAt === null) {
          let currDate = moment();
          let days = currDate.diff(moment(row.original.createdAt), "days");

          return days > 0
            ? days + ` day${days > 1 ? "s" : ""}`
            : "Created Today";
        }

        return "-";
      },
    },
    {
      Header: "Completed On",
      Cell: ({ row }) => {
        if (row.original.completedAt) {
          return moment(row.original.completedAt).format("Do MMMM YYYY");
        }

        return "-";
      },
    },
    {
      Header: "Status",
      Cell: ({ row }) => {
        let status = row.original.status;
        let classes = classnames("label custom-status-label", {
          "label-secondary": status === 0,
          "label-danger": status === -1,
          "label-success": status === 1,
        });

        return <div className={classes}>{row.original.statusName}</div>;
      },
    },
    {
      Header: "Remarks",
      Cell: ({ row }) => {
        return row.original.remarks ?? "-";
      },
    },
  ]);

  const format = {
    series: [
      {
        name: "Session Duration",
        data: [45, 52, 38, 24, 33, 26, 21, 20, 6, 8, 15, 10],
      },
      {
        name: "Page Views",
        data: [35, 41, 62, 42, 13, 18, 29, 37, 36, 51, 32, 35],
      },
      {
        name: "Total Visits",
        data: [87, 57, 74, 99, 75, 38, 62, 47, 82, 56, 45, 47],
      },
    ],
    options: {
      chart: {
        height: 350,
        type: "line",
        zoom: {
          enabled: false,
        },
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        width: [5, 7, 5],
        curve: "smooth",
        dashArray: [0, 8, 5],
      },
      title: {
        text: "Sales Concluded",
        align: "left",
      },
      legend: {
        tooltipHoverFormatter: function (val, opts) {
          return (
            val +
            " - " +
            opts.w.globals.series[opts.seriesIndex][opts.dataPointIndex] +
            ""
          );
        },
      },
      markers: {
        size: 0,
        hover: {
          sizeOffset: 6,
        },
      },
      xaxis: {
        categories: [
          "01 Jan",
          "02 Jan",
          "03 Jan",
          "04 Jan",
          "05 Jan",
          "06 Jan",
          "07 Jan",
          "08 Jan",
          "09 Jan",
          "10 Jan",
          "11 Jan",
          "12 Jan",
        ],
      },
      tooltip: {
        y: [
          {
            title: {
              formatter: function (val) {
                return val + " (mins)";
              },
            },
          },
          {
            title: {
              formatter: function (val) {
                return val + " per session";
              },
            },
          },
          {
            title: {
              formatter: function (val) {
                return val;
              },
            },
          },
        ],
      },
      grid: {
        borderColor: "#f1f1f1",
      },
    },
  };

  const radialformat = {
    series: [44, 55, 67, 83],

    options: {
      chart: {
        height: 350,
        type: "radialBar",
      },
      plotOptions: {
        radialBar: {
          dataLabels: {
            name: {
              fontSize: "22px",
            },
            value: {
              fontSize: "16px",
            },
            total: {
              show: true,
              label: "Total",
              formatter: function (w) {
                // By default this function returns the average of all series. The below is just an example to show the use of custom formatter function
                return 249;
              },
            },
          },
        },
      },
      labels: ["Apples", "Oranges", "Bananas", "Berries"],
    },
  };

  const piechart = {
    series: [44, 55, 41, 17, 15],
    options: {
      chart: {
        type: "donut",
      },
      responsive: [
        {
          breakpoint: 480,
          options: {
            chart: {
              width: 200,
              height: 400,
            },
            legend: {
              position: "bottom",
            },
          },
        },
      ],
    },
  };

  const barchart = {
    series: [
      {
        name: "Net Profit",
        data: [44, 55, 57, 56, 61, 58, 63, 60, 66],
      },
      {
        name: "Revenue",
        data: [76, 85, 101, 98, 87, 105, 91, 114, 94],
      },
      {
        name: "Free Cash Flow",
        data: [35, 41, 36, 26, 45, 48, 52, 53, 41],
      },
    ],
    options: {
      chart: {
        type: "bar",
        height: 350,
      },
      title: {
        text: "Geographical Stats",
        align: "left",
      },
      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: "55%",
          endingShape: "rounded",
        },
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        show: true,
        width: 2,
        colors: ["transparent"],
      },
      xaxis: {
        categories: [
          "Feb",
          "Mar",
          "Apr",
          "May",
          "Jun",
          "Jul",
          "Aug",
          "Sep",
          "Oct",
        ],
      },
      yaxis: {
        title: {
          text: "$ (thousands)",
        },
      },
      fill: {
        opacity: 1,
      },
      tooltip: {
        y: {
          formatter: function (val) {
            return "$ " + val + " thousands";
          },
        },
      },
    },
  };

  return (
    <>
      <h1 className="page-header">Dashboard</h1>
      <div class="row">
        <Col md="3" className="mb-3">
          <Card className="factsheet-card" tag="a" onClick={() => {}}>
            <CardBody>
              <div className="dashboard-item">
                <div className="item-title blue">
                  <div class="row">
                    <div class="col-md-2">
                      <FaShoppingCart />
                    </div>
                    <div style={{ fontSize: "20px" }} class="col-md-10">
                      ENQUIRY
                      <div class="row">
                        <div style={{ fontSize: "20px" }} class="col-md-3">
                          100
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </CardBody>
          </Card>
        </Col>
        <Col md="3" className="mb-3">
          <Card className="factsheet-card" tag="a" onClick={() => {}}>
            <CardBody>
              <div className="dashboard-item">
                <div className="item-title blue">
                  <div class="row">
                    <div class="col-md-2">
                      <FaRegFileAlt />
                    </div>
                    <div style={{ fontSize: "20px" }} class="col-md-10">
                      QUOTATION
                      <div class="row">
                        <div style={{ fontSize: "20px" }} class="col-md-3">
                          100
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </CardBody>
          </Card>
        </Col>
        <div class="col-md-3">
          <Card
            style={{ border: "1px solid green" }}
            className="factsheet-card"
            tag="a"
            onClick={() => {}}
          >
            <CardBody>
              <div className="dashboard-item">
                <div className="item-title blue">
                  <div class="row">
                    <div class="col-md-2">
                    <IconContext.Provider
                      value={{ color: 'green' }}
                    >
                       <FaThumbsUp value={{ color: 'blue'}} />
                     </IconContext.Provider>                     
                    </div>
                    <div style={{ fontSize: "20px" }} class="col-md-10">
                      CONCLUDED
                      <div class="row">
                        <div style={{ fontSize: "20px" }} class="col-md-3">
                          100
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </CardBody>
          </Card>
        </div>
        <div class="col-md-3">
          <Card
            style={{ border: "1px solid red" }}
            className="factsheet-card"
            tag="a"
            onClick={() => {}}
          >
            <CardBody>
              <div className="dashboard-item">
                <div className="item-title blue">
                  <div class="row">
                    <div class="col-md-2">
                    <IconContext.Provider
                      value={{ color: '#ff0066' }}
                    >
                         <FaThumbsDown />
                     </IconContext.Provider>                           
                    </div>
                    <div style={{ fontSize: "20px" }} class="col-md-10">
                      LOST
                      <div class="row">
                        <div style={{ fontSize: "20px" }} class="col-md-3">
                          100
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </CardBody>
          </Card>
        </div>
      </div>
      <div class="row">
        <div id="chart" class="col-md-12">
          <ReactApexChart
            options={format.options}
            series={format.series}
            type="line"
            height={350}
          />
        </div>
      </div>
      <div class="row">
        <div id="radialchart" class="col-md-3">
          <ReactApexChart
            options={radialformat.options}
            series={radialformat.series}
            type="radialBar"
            height={350}
          />
        </div>
        <div id="radialchart" class="col-md-3">
          <ReactApexChart
            options={radialformat.options}
            series={radialformat.series}
            type="radialBar"
            height={350}
          />
        </div>
        <div id="radialchart" class="col-md-3">
          <ReactApexChart
            options={radialformat.options}
            series={radialformat.series}
            type="radialBar"
            height={350}
          />
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <ReactApexChart
            options={barchart.options}
            series={barchart.series}
            type="bar"
            height={350}
          />
        </div>        
      </div>
    </>
  );
};
