const _DEV_HOST = "http://localhost:8000";
const _SIT_HOST = "http://nicheapi.gzit.com.my";
const _PROD_HOST = "https://api.niche123.net";

/// <summary>
/// Author : Robin
/// Host for different Environment
/// </summary>
export class HostKey {
  static getApiHost = () => {
    switch (process.env.REACT_APP_ENV) {
      case "dev":
        return _DEV_HOST;

      case "sit":
        return _SIT_HOST;

      case "prod":
        return _PROD_HOST;

      default:
        return !process.env.NODE_ENV || process.env.NODE_ENV === "development"
          ? _DEV_HOST
          : _SIT_HOST;
    }
  };
}

export const _IMAGE_URL = `${HostKey.getApiHost()}`;

/// <summary>
/// Author : -
/// API related key or field when making ajax or api call
/// </summary>
export class ApiKey {
  static _API_SUCCESS_KEY = "success";
  static _API_MESSAGE_KEY = "message";
  static _API_DATA_KEY = "data";
  static _API_TOTAL_KEY = "totalCount";
  static _API_CONTENT_TYPE = "Content-Type";
  static _API_ACCEPT = "Accept";
  static _API_APPLICATION_JSON = "application/json";
  static _API_XML_HTTP_REQUEST_KEY = "XMLHttpRequest";
  static _API_FORM_URLENCODED = "application/x-www-form-urlencoded";
  static _API_FORM_DATA = "multipart/form-data";
  static _API_POST = "POST";
  static _API_GET = "GET";
  static _API_DELETE = "DELETE";
  static _API_PUT = "PUT";
}

/// <summary>
/// Author: Christopher Chan
/// </summary>
export class ApiUrl {
  static _API_LOGIN = "/auth/login";
  static _API_LOGOUT = "/auth/logout";
  static _API_VALIDATE_LOGIN = "/auth/user";

  static _API_CUSTOMER_LIST = "/customer/list";
  static _API_CREATE_CUSTOMER = "/customer/create";
  static _API_EDIT_CUSTOMER = "/customer/update";
  static _API_DELETE_CUSTOMER = "/customer/delete";
  static _API_GET_CUSTOMER = "/customer/get";
  static _API_UPDATE_CUSTOMER_PERSONAL = "/customer/updatePersonalInfo";
  static _API_UPDATE_CUSTOMER_CONTACT = "/customer/updateContactInfo";
  static _API_UPDATE_CUSTOMER_LOCATION = "/customer/updateLocationInfo";
  static _API_UPDATE_CUSTOMER_OTHER = "/customer/updateOtherInfo";
  static _API_DEACTIVATE_CUSTOMER = "/customer/deactivate";

  static _API_CREATE_ENQUIRY = "/enquiry/create";
  static _API_ENQUIRY_LIST = "/enquiry/list";
  static _API_UPDATE_ENQUIRY = "/enquiry/update";
  static _API_DELETE_ENQUIRY = "/enquiry/delete";
  static _API_UPDATE_ENQUIRY_STATUS = "/enquiry/update/status";
  static _API_ENQUIRY_SITES_NO_QUOTATION = "/enquiry/sites";
  static _API_GET_ENQUIRY = "/enquiry/get";
  static _API_DOWNLOAD_FILES = "/enquiry/download";

  static _API_QUOTATION_LIST = "/quotation/list";
  static _API_CREATE_QUOTATION = "/quotation/create";
  static _API_UPDATE_QUOTATION = "/quotation/update";
  static _API_DELETE_QUOTATION = "/quotation/delete";
  static _API_UPDATE_QUOTATION_STATUS = "/quotation/update/status";

  static _API_ELEVATOR_MODELS = "/elevator-models";
  static _API_MODIFY_ELEVATOR_PART = "/elevator-models/modify-elevator-part";
  static _API_CREATE_ELEVATOR_PART = "/elevator-models/create-elevator-part";
  static _API_DELETE_ELEVATOR_PART = "/elevator-models/delete-elevator-part";

  static _API_CONTRACT = "/contract";
  static _API_UPDATE_CONTRACT_STATUS = "/contract/update/status";
  static _API_GET_CONTRACT_SITES = "/contract/sites";
  static _API_GET_SITE_CONTRACTS = "/contract/site/contracts";
  static _API_UPLOAD_SIGNED_DOCUMENT = "/contract/uploadSignedDocument";
  static _API_EMPTY_CONTRACT_QUOTATION_LIST = "/quotation/emptyContract";

  static _API_GET_DEPARTMENT = "/department/list";
  static _API_GET_POSITION = "/position/list";
  static _API_GET_NATURE_OF_BUSINESS = "/natureofbusiness/list";
  static _API_GET_BLACKLIST = "/blacklist/list";

  static _API_GET_WAREHOUSE = "/warehouse/list";
  static _API_WAREHOUSE_CREATE = "/warehouse/post";
  static _API_WAREHOUSE_UPDATE = "/warehouse/update";
  static _API_WAREHOUSE_DELETE = "/warehouse/delete";

  static _API_ELEVATOR_MODELS = "/elevator-models";
  static _API_NUMBER_GENERATIONS = "/settings/number-generation";

  static _API_GET_CUSTOMER_LOGS = "/activityLogs/customerlog";
}

/// <summary>
/// Author : -
/// Url to navigate to pages
/// </summary>
export class WebUrl {
  static _LOGIN = "/login";
  static _DASHBOARD = "/dashboard";
  static _CUSTOMER = "/customer";
  static _EDIT_CUSTOMER = "/customer-add-edit";
  static _VIEW_CUSTOMER = "/customer-detail/:id";
  static _ENQUIRY = "/enquiry";
  static _VIEW_ENQUIRY = "/viewenquiry/:id";
  static _VIEW_QUOTATION = "/viewquotation/:id";
  static _QUOTATION = "/quotation";
  static _QUOTATION_CREATE_OR_UPDATE = "/quotation/:id";
  static _CONTRACT = "/contract";
  static _CONTRACT_CREATE_OR_UPDATE = "/contract/:id";
  static _WAREHOUSE = "/management/warehouses";
  static _SETTINGS = "/settings";
  static _ELEVATOR_MODELS = "/management/elevator-model";
  static _ELEVATOR_MODELS_CREATE_OR_UPDATE = "/management/elevator-model/:id";
}

/// <summary>
/// Author : -
/// </summary>
export class SessionKey {
  static _LANGUAGE = "language";
}

/// <summary>
/// Author : -
/// </summary>
export class Language {
  static _ENGLISH_GB = "en-GB";
  static _CHINESE_HK = "zh-HK";
  static _CHINESE_CN = "zh-CN";
}

/// <summary>
/// Author: Christopher Chan
/// </summary>
export class Status {
  static _DELETED = -2;
  static _REJECTED = -1;
  static _INACTIVE = 0;
  static _ACTIVE = 1;
  static _PENDING = 2;
  static _EXPIRED = 3;
}

/// <summary>
/// Author: Christopher Chan
/// </summary>
export const BtnTypes = {
  ADD: "add",
  DELETE: "delete",
  VIEW: "view",
  EDIT: "edit",
  MISC: "misc",
};

/// <summary>
/// Author: Christopher Chan
/// </summary>
export const InputTypes = {
  INPUT: "input",
  SELECT: "select",
  RADIO: "radio",
  PHONE: "phone",
  TEXTAREA: "textarea",
  ATTACHMENT: "attachment",
  DATEPICKER: "datepicker",
  CHECKBOX: "checkbox",
  FILEUPLOAD: "fileupload",
};

/// <summary>
/// Author: Lewis
/// </summary>
export const SelectField = {
  _PHONE_TYPES: [
    { label: "Mobile", value: "Mobile" },
    { label: "Home", value: "Home" },
    { label: "Work", value: "Work" },
    { label: "Fax", value: "Fax" },
    { label: "Other", value: "Other" },
  ],

  _EMAIL_TYPES: [
    { label: "Personal", value: "Personal" },
    { label: "Work", value: "Work" },
    { label: "Other", value: "Other" },
  ],

  _ADDRESS_TYPES: [
    { label: "Home", value: "Home" },
    { label: "Work", value: "Work" },
    { label: "Other", value: "Other" },
  ],

  _STATE_OPTIONS: [
    { label: "Johor", value: "Johor" },
    { label: "Kedah", value: "Kedah" },
    { label: "Kelantan", value: "Kelantan" },
    { label: "Malacca", value: "Malacca" },
    { label: "Negeri Sembilan", value: "Negeri Sembilan" },
    { label: "Pahang", value: "Pahang" },
    { label: "Penang", value: "Penang" },
    { label: "Perak", value: "Perak" },
    { label: "Perlis", value: "Perlis" },
    { label: "Sabah", value: "Sabah" },
    { label: "Sarawak", value: "Sarawak" },
    { label: "Selangor", value: "Selangor" },
    { label: "Terengganu", value: "Terengganu" },
  ],

  _COUNTRY_OPTIONS: [{ label: "Malaysia", value: "Malaysia" }],

  _GENDER_OPTIONS: [
    { label: "Male", value: "Male" },
    { label: "Female", value: "Female" },
  ],

  STATUS_OPTIONS: [
    { label: "Pending", value: 0 },
    { label: "Rejected", value: 1 },
    { label: "Approved", value: 2 },
  ],

  _BUILDING_STATUS: [
    { label: "Planning Stage", value: 1 },
    { label: "Under Construction", value: 2 },
    { label: "Under Renovation", value: 3 },
    { label: "Completed", value: 4 },
    { label: "Unknown", value: 0 },
  ],

  _BUILDING_OCCUPANCY: [
    { label: "Vacant", value: -1 },
    { label: "Occupied", value: 1 },
    { label: "Not Applicable", value: 0 },
  ],

  _SUPPORT_DISTANCE: [
    { label: "Remote Area", value: 0 },
    { label: "Out of Support Range", value: 1 },
    { label: "Within Support Range", value: 2 },
    { label: "Unknown", value: 3 },
  ],

  _JKKP_REQUIRED: [
    { label: "JKKP Required", value: 0 },
    { label: "JKKP Not Required", value: 1 },
  ],

  _URGENCY: [
    { label: "Low Urgency", value: 0 },
    { label: "Medium Urgency", value: 1 },
    { label: "High Urgency", value: 1 },
  ],

  _TYPES_OF_ACTIONS: [
    { label: "Supply Only", value: 0 },
    { label: "Supply and Install", value: 1 },
    { label: "Dismantle Only", value: 2 },
    { label: "Dismantle, Supply and Install", value: 3 },
  ],

  _PRODUCT_INVOLVED: [
    "Home Lift",
    "OKU Lift",
    "Dumbwaiter",
    "Good Hoist",
    "Others",
  ],

  _BLACKLISTS: [
    { label: "Neutral", value: "Neutral" },
    { label: "Payment", value: "Payment" },
    { label: "Lost Contract", value: "Lost Contract" },
    { label: "Criminal", value: "Criminal" },
    { label: "Deceased", value: "Deceased" },
  ],

  _DESIGNATION: [
    { label: "Mr.", value: "Mr." },
    { label: "Ms. ", value: "Ms." },
    { label: "Mrs.", value: "Mrs." },
    { label: "Dr.", value: "Dr." },
    { label: "Dato", value: "Dato" },
    { label: "Datin", value: "Datin" },
    { label: "Tan Sri", value: "Tan Sri" },
    { label: "YB", value: "YB" },
    { label: "Tengku", value: "Tengku" },
  ],
};

/// <summary>
/// Author: Christopher Chan
/// </summary>
export const Colors = {
  THEME: "#ff0066",
  OFF_THEME: "#ff0066",
  WHITE: "#FFF",
  RED: "#f64e60",
  GRAY: "#dfe1e4",
  BLACK: "#000000",
};
