import React, { useState, useEffect } from "react";
import { Card, CardHeader, CardBody, Row, Col } from "reactstrap";
import { Confirm, Report } from "notiflix";
import { report } from "util/Notiflix";
import { useForm, useFieldArray } from "react-hook-form";

import { InputTypes, ApiKey } from "util/Constant";
import { Config, ConfigEnum } from "util/Config";
import QuotationDao from "data/QuotationDao";

import InputHoc from "components/form/InputHoc.js";
import CustomInput from "reactstrap/lib/CustomInput";
import { Container, Button } from "react-floating-action-button";
import { tryCatch } from "util/Utility";

/// <summary>
/// Author: Christopher Chan
/// </summary>
const QuotationForm = ({ items, elevatorModels, isDisabled, quotation }) => {
  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
    reset,
    setValue,
  } = useForm({});
  const { fields, append, remove } = useFieldArray({
    control,
    name: `items`,
  });

  let configInstance = Config.getInstance();
  var token = configInstance.getValue(ConfigEnum._TOKEN);

  /// <summary>
  /// Author: Christopher Chan
  /// </summary>
  useEffect(() => {
    if (items.length > 0) {
      console.log(elevatorModels);
      let processedItems = [];

      items.map((item) => {
        processedItems.push({
          name: item.name,
          ...item,
        });
      });

      append(processedItems);
    } else {
      append({
        discount: 0,
        tax: 0,
      });
    }
  }, []);

  /// <summary>
  /// Author: Christopher Chan
  /// </summary>
  const onSubmit = (data) => {
    tryCatch(async () => {
      let quotationData = data;

      if (quotation) {
        quotationData["id"] = quotation.id;
      }

      let dao = new QuotationDao();
      let action = "";
      console.log(quotationData);
      if (quotationData.id) {
        action = dao.updateQuotation(quotationData, token);
      } else {
        action = dao.createQuotation(quotationData, token);
      }

      await action.then((responseJson) => {
        let data = responseJson[ApiKey._API_DATA_KEY];

        report("quotation", responseJson, data.quotationId, quotationData?.id);
      });
    });
  };

  /// <summary>
  /// Author: Christopher Chan
  /// </summary>
  const handleNameOnChange = (value, index) => {
    let itemExist = elevatorModels.find((x) => x.name === value);

    if (itemExist) {
      setValue(`items[${index}]`, {
        serialNumber: itemExist.serialNumber,
        description: itemExist.description,
        price: itemExist.price,
      });
    }
  };

  return (
    <form id="enquiryForm" onSubmit={handleSubmit(onSubmit)}>
      <Card className="mb-2">
        <CardHeader>Items ({fields.length})</CardHeader>
        <CardBody
          className="scrollable-container"
          style={{ maxHeight: "65vh" }}
        >
          {fields.map((item, index) => {
            return (
              <>
                {index !== 0 && <hr />}
                <Row key={item.id}>
                  <Col>
                    <Row>
                      <Col xs={8}>
                        <InputHoc
                          disabled={isDisabled}
                          name={`items[${index}].name`}
                          label="Item Name"
                          error={errors?.[index]?.name}
                          defaultValue={item?.name}
                          control={control}
                          rules={{ required: "required" }}
                          inputType={InputTypes.SELECT}
                          options={elevatorModels}
                          onChange={(val) => handleNameOnChange(val, index)}
                          creatable
                          ref={register({ required: 'Item Name is required.' })}
                        />
                      </Col>
                      <Col xs={2}>
                        <InputHoc
                          disabled={isDisabled}
                          name={`items[${index}].serialNumber`}
                          label="Serial Number"
                          ref={register({ required: "required" })}
                          error={errors?.[index]?.serialNumber}
                          defaultValue={item?.serialNumber}
                        />
                      </Col>
                      <Col xs={2}>
                        <InputHoc
                          disabled={isDisabled}
                          name={`items[${index}].quantity`}
                          label="Quantity"
                          ref={register({ required: "Quantity is required" })}
                          error={errors ? errors[index]?.quantity?.message : ""}
                          showErrorMsg={true}
                          defaultValue={item?.quantity}
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col xs={6}>
                        <InputHoc
                          disabled={isDisabled}
                          name={`items[${index}].description`}
                          label="Description"
                          ref={register({ required: "required" })}
                          error={errors?.[index]?.description}
                          defaultValue={item?.description}
                        />
                      </Col>
                      <Col xs={2}>
                        <InputHoc
                          disabled={isDisabled}
                          name={`items[${index}].price`}
                          label="Item Price (RM)"
                          type="number"
                          ref={register({ required: "required" })}
                          error={errors?.[index]?.price}
                          defaultValue={item?.price}
                        />
                      </Col>
                      <Col xs={2}>
                        <InputHoc
                          disabled={isDisabled}
                          name={`items[${index}].discount`}
                          label="Discount (RM)"
                          type="number"
                          ref={register({ required: "required" })}
                          error={errors?.[index]?.discount}
                          defaultValue={item?.discount}
                        />
                      </Col>
                      <Col xs={2}>
                        <InputHoc
                          disabled={isDisabled}
                          name={`items[${index}].tax`}
                          label="Tax (%)"
                          type="number"
                          max="100"
                          ref={register({ required: "required" })}
                          error={errors?.[index]?.tax}
                          defaultValue={item?.tax}
                        />
                      </Col>
                    </Row>
                  </Col>
                  <Col xs={1} className="flex-center">
                    {!isDisabled ? (
                      fields.length - 1 === index ? (
                        index === 0 ? (
                          <div
                            className="btn-round expand-theme mt-2"
                            onClick={append}
                          >
                            <i className="las la-plus"></i>
                          </div>
                        ) : (
                          [
                            <div
                              className="btn-round expand-theme mr-2"
                              onClick={append}
                            >
                              <i className="las la-plus"></i>
                            </div>,
                            <div
                              className="btn-round expand-red mr-2"
                              onClick={() => remove(index)}
                            >
                              <i className="las la-times"></i>
                            </div>,
                          ]
                        )
                      ) : (
                        <div
                          className="btn-round expand-red mt-2"
                          onClick={() => remove(index)}
                        >
                          <i className="las la-times"></i>
                        </div>
                      )
                    ) : (
                      <></>
                    )}
                  </Col>
                </Row>
              </>
            );
          })}
        </CardBody>
      </Card>
      <button type="submit" className="btn btn-themed btn-block">
        Save
      </button>
    </form>
  );
};

export default QuotationForm;
