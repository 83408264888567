import React, { useRef } from 'react';
import { Col, Row } from 'reactstrap';
import { useForm } from 'react-hook-form';
import { FaSearch } from 'react-icons/fa';

import CustomInput from 'components/form/CustomInput';

/// <summary>
/// Author: KurisuCodes
/// </summary>
export default ({ handleSearch }) => {
    const { register, handleSubmit } = useForm();

    return (
        <div className="table-search">
            <form className="w-100" onSubmit={handleSubmit(handleSearch)} autoComplete="off">
                <Row>
                    <Col xs={9}>
                        <CustomInput
                            register={register}
                            name="search"
                            label="Search user by keywords"
                            icon={<FaSearch />}
                            style={{ marginBottom: 9, height: 28 }}
                        />
                    </Col>
                    <Col xs={3}>
                        <button type="submit" className="btn btn-sm btn-themed w-100">
                            Search
                        </button>
                    </Col>
                </Row>
            </form>
        </div>
    )
};