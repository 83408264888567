import { ApiUrl } from 'util/Constant';
import DataAccessObject from './DataAccessObject';

/**
 * Author : Chris
 * DataAccessObject's fetch will return the promise.
 */
class CommonDao extends DataAccessObject {

    async getDepartmentList() {
        return this.get(ApiUrl._API_GET_DEPARTMENT);
    }

    async getPositionList() {
        return this.get(ApiUrl._API_GET_POSITION);
    }

    async getNatureOfBusinessList() {
        return this.get(ApiUrl._API_GET_NATURE_OF_BUSINESS);
    }

    async getBlacklist() {
        return this.get(ApiUrl._API_GET_BLACKLIST);
    }

    async getElevatorModels(token) {
        return this.get(ApiUrl._API_ELEVATOR_MODELS, token);
    }
}

export default CommonDao;
