import React from 'react';
import ReactSelect from 'react-select';
import { Controller } from 'react-hook-form';
import ImageUploader from 'react-images-upload';

/// <summary>
/// Author: Christopher Chan
/// </summary>
const Attachment = (props) => {
    return (
        <Controller
            as={<ImageUploader />}
            imgExtension={['.jpg', '.gif', '.png', '.gif']}
            maxFileSize={5242880}
            // singleImage={true}
            withPreview={true}
            withIcon={false}
            buttonText='Choose images'
            {...props}
        />
    );
};

Attachment.defaultProps = {
    defaultValue: null,
}

export default Attachment;