import { WebUrl } from "util/Constant";

const Menu = [
        { path: WebUrl._DASHBOARD, icon: 'las la-th-large', title: 'Dashboard' },
        { path: WebUrl._CUSTOMER, icon: 'las la-user', title: 'Customers' },
        { path: WebUrl._ENQUIRY, icon: 'las la-file-alt', title: 'Enquiry' },
        { path: WebUrl._QUOTATION, icon: 'las la-list-ul', title: 'Quotation' },
        {
                path: WebUrl._SETTINGS, icon: 'las la-cogs', title: 'Settings',
                children: [
                        { path: WebUrl._ELEVATOR_MODELS, title: 'Elevator Models' },
                ]
        },
];

export const AdminMenu = [
];

export default Menu;
