import React from 'react';
import LoadingOverlay from 'react-loading-overlay';
import { useRecoilValue } from 'recoil';
import { appState } from 'recoil/Atoms';

export const PageOverlayLoader = () => {
    const appStateValue = useRecoilValue(appState);
    return (
        <LoadingOverlay
            active={appStateValue.isBusy}
            styles={{
                overlay: (base) => ({
                    ...base,
                    zIndex: '10000',
                    position: 'fixed',
                })
            }}
            text={appStateValue.message}
            spinner
        />
    )
}
