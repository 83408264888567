import React from 'react';
import PerfectScrollbar from 'react-perfect-scrollbar';

import { PageSettings } from './../../config/page-settings.js';
import SidebarNav from './sidebar-nav.jsx';
import { Config, ConfigEnum } from 'util/Config';
import { HostKey } from "util/Constant";
import NoImagePlaceholder from "assets/img/user/No-Image-Placeholder.svg.png";

/// <summary>
/// Author: KurisuCodes
/// </summary>
export default () => {
        let configInstance = Config.getInstance();
        var user = configInstance.getValue(ConfigEnum._USER);
        console.log(user);
        return (
                <PageSettings.Consumer>
                        {({
                                toggleMobileSidebar,
                                pageSidebarTransparent
                        }) => (
                                <>
                                        <div id="sidebar" className={'sidebar ' + (pageSidebarTransparent ? 'sidebar-transparent' : '')}>
                                                <PerfectScrollbar className="height-full" options={{ suppressScrollX: true }}>
                                                        <div className="sidebar-user-container">
                                                                {user?.photo ? <img src={HostKey.getApiHost() + user?.photo} /> :
                                                                        <img src={NoImagePlaceholder} />}
                                                                <div>{user?.fullName}</div>
                                                        </div>
                                                        <hr />
                                                        <SidebarNav />
                                                </PerfectScrollbar>
                                        </div>
                                        <div className="sidebar-bg"></div>
                                        <div className="sidebar-mobile-dismiss" onClick={toggleMobileSidebar}></div>
                                </>
                        )}
                </PageSettings.Consumer>
        );
};