import React, { useMemo, useState, useEffect } from "react";
import ReactTable from 'components/react-table/ReactTable';
import { Card, CardBody } from 'reactstrap';
import { Block, Report } from "notiflix";

import classnames from 'classnames';

import ContractDao from "data/ContractDao.js";

import { Config, ConfigEnum } from 'util/Config';
import { ApiKey, _IMAGE_URL } from "util/Constant";
import CreateUpdateContractModal from 'components/modals/CreateUpdateContractModal';
import UploadContractModal from 'components/modals/UploadContractModal';
import WinLossButtons from "components/buttons/WinLossButtons";

/// <summary>
/// Author: Christopher Chan
/// </summary>
export default () => {
    const [isUploadModalOpen, setIsUploadModalOpen] = useState(false);
    const [isContractModalOpen, setIsContractModelOpen] = useState(false);
    const [projectModal, setProjectModal] = useState(false);
    const [selectedContract, setSelectedContract] = useState(null);
    const [contracts, setContracts] = useState([]);

    let configInstance = Config.getInstance();
    var token = configInstance.getValue(ConfigEnum._TOKEN);

    /// <summary>
    /// Author: Christopher Chan
    /// </summary>
    const toggleUploadModal = (refresh = false) => {
        setIsUploadModalOpen(!isUploadModalOpen);

        if (isUploadModalOpen) {
            setSelectedContract(null);
        }

        if (isUploadModalOpen && refresh) {
            getContractList();
        }
    };

    /// <summary>
    /// Author: Christopher Chan
    /// </summary>
    const toggleNewContractModal = (refresh = false) => {
        setIsContractModelOpen(!isContractModalOpen);

        if (isContractModalOpen && refresh) {
            getContractList();
        }
    };

    /// <summary>
    /// Author: Christopher Chan
    /// </summary>
    const toggleProjectModal = () => {
        setProjectModal(!projectModal)
    };



    const getContractList = async () => {
        Block.circle('#contractsTable');

        let dao = new ContractDao();
        await dao.getContracts(token).then((response) => {
            if (response[ApiKey._API_SUCCESS_KEY]) {
                var data = response[ApiKey._API_DATA_KEY];
                var contracts = [];
                console.log("Contract data", data);
                data.map((item) => {
                    contracts.push(item);
                });
                setContracts(contracts);
            }
            else {
                Report.Failure('Error', 'Failed to load contracts. Please try again later');
            }
        });

        Block.Remove('#contractsTable');
    }

    /// <summary>
    /// Author: Christopher Chan
    /// </summary>
    const updateContractStatus = async (data) => {
        let contract = contracts.filter(x => x.contractId === data.id)[0];

        if (data.status === 1) {
            setSelectedContract(contract);
        }
        else {
            let dao = new ContractDao();

            const formData = new FormData();

            formData.append("id", contract.id);
            formData.append("status", data.status);
            formData.append("remarks", data.remarks);

            await dao.updateContractStatus(formData, token).then(responseJson => {
                if (responseJson[ApiKey._API_SUCCESS_KEY]) {
                    Report.Success('Success', "Contract Status Updated", 'Okay', getContractList);
                } else {
                    Report.Failure('Error', 'Failed to update contract status. ' + responseJson[ApiKey._API_MESSAGE_KEY]);
                }
            });
        }
    }

    const _COLUMN = useMemo(() => [
        {
            Header: "Contract ID",
            Cell: ({ row }) => (
                <b>{row.original.contractId}</b>
            ),
        },
        {
            Header: "Quotation ID",
            accessor: "quotation.quotationId"
        },
        {
            Header: "Signed Document",
            Cell: ({ row }) => {
                if (!row.original.latestVersion.signedDocument) {
                    return <span className="text-red">Not signed yet.</span>
                }

                return (
                    <a href={`${_IMAGE_URL}/contract/signed_document/${row.original.latestVersion.signedDocument}`} target="_blank">
                        {row.original.latestVersion.signedDocument}
                    </a>
                );
            },
        },
        {
            Header: "Status",
            Cell: ({ row }) => {
                let status = row.original.status;
                let classes = classnames('label custom-status-label', {
                    'label-secondary': status === 0,
                    'label-danger': status === -1,
                    'label-success': status === 1,
                })

                return (
                    <div className={classes}>
                        {row.original.statusName}
                    </div>
                );
            }
        },
        {
            Header: "Remarks",
            Cell: ({ row }) => {
                return row.original.remarks ?? '-'
            },
        },
        {
            Header: "Action",
            Cell: ({ row }) => {
                if (row.original.status === 0) {
                    return <WinLossButtons id={row.original.contractId} type="Contract" apiCall={updateContractStatus} seperator={false} />
                }

                return null;
            },
            disableSortBy: true,
            disableFilters: true,
            style: { overflow: "visible" }
        }
    ]);

    /// <summary>
    /// Author: Christopher Chan
    /// </summary>
    useEffect(() => {
        if (selectedContract !== null) {
            toggleUploadModal();
        }
    }, [selectedContract]);

    /// <summary>
    /// Author: Christopher Chan
    /// </summary>
    useEffect(() => {
        getContractList();
    }, [])

    return (<>
        <div className="float-xl-right">
            <button type="type" className="btn btn-sm btn-themed mb-2" onClick={toggleNewContractModal} ><i className="fa fa-plus mr-1"></i>Add New Contract</button>
        </div>
        <h1 className="page-header">Contract Management</h1>
        <div id="contractsTable">
            <ReactTable columns={_COLUMN} data={contracts} />
        </div>
        <CreateUpdateContractModal isOpen={isContractModalOpen} toggle={toggleNewContractModal} />
        <UploadContractModal isOpen={isUploadModalOpen} toggle={toggleUploadModal} contract={selectedContract} />
    </>)
}
