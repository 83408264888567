import { fetch } from 'whatwg-fetch';
import getCountryList from 'react-select-country-list';
import { useState, useEffect } from 'react';
import moment from 'moment';
import Payment from 'payment';
import _ from 'lodash';
import { startCase } from 'lodash';
import { Report } from 'notiflix';

/// <summary>
/// Author : -
/// To Check if it is empty or null string
/// </summary>
export function stringIsNullOrEmpty(data) {
    return data == null || data == '' || data == undefined;
}

/// <summary>
/// Author : -
/// Convert array into form that use to post data
/// </summary>
export function createFormBody(params) {
    let formBody = [];

    for (let property in params) {
        let encodedKey = encodeURIComponent(property);
        let encodedValue = encodeURIComponent(params[property]);
        formBody.push(encodedKey + "=" + encodedValue);
    }

    formBody = formBody.join("&");
    return formBody;
}

/// <summary>
/// Author : -
/// Convert array into form that use to post data
/// </summary>
export function createMultiPartFormBody(params) {
    var formBody = new FormData();

    for (const [key, value] of Object.entries(params)) {
        formBody.append(key, value);
    }

    return formBody;
}

/// <summary>
/// Author : -
/// Used to Navigation to certain path
/// </summary>
export function navigateTo(history, url, state) {
    if (state !== undefined) {
        history.push({
            pathname: url,
            state: state
        });
    } else {
        history.push(url);
    }
}

/// <summary>
/// Author : -
/// </summary>
export const dataTableFilterCaseInsensitive = (filter, row) => {
    const id = filter.pivotId || filter.id;
    if (row[id] !== null && typeof row[id] === 'string') {
        return (
            row[id] !== undefined ?
                String(row[id].toLowerCase()).startsWith(filter.value.toLowerCase()) : true
        )
    } else {
        return (
            String(row[filter.id]) === filter.value
        )
    }
}

/// <summary>
/// Author: Christopher Chan
/// </summary>
export const useGetCountries = () => {
    const [groupedCountries, setGroupCountries] = useState();

    useEffect(() => {
        let listOfCountries = getCountryList().getData();

        let mainCountries = listOfCountries.filter((country) => {
            return ['Hong Kong', 'China', 'Singapore'].includes(country.label);
        });

        mainCountries.forEach((country) => {
            listOfCountries.splice(listOfCountries.indexOf(country), 1);
        })

        setGroupCountries([
            { label: '', options: mainCountries },
            { label: '', options: listOfCountries }
        ]);
    }, []);

    return groupedCountries;
}

/// <summary>
/// Author: Christopher Chan
/// </summary>
export const getListOfYears = (offset = 100) => {
    let years = [];
    for (var i = 0; i <= offset; i++) {
        years.push(moment().year() - i);
    }

    return years;
}

/// <summary>
/// Author: Christopher Chan
/// </summary>
export const generateRandString = (length = 6) => {
    return Math.random().toString(20).substr(2, length);
}

/// <summary>
/// Author: Lewis
/// </summary>
export const getCartItemPrices = (cartItems) => {
    var total = 0;
    var totalLocalPrice = 0;
    cartItems.map((item) => {
        total += item.hkDollar;
        totalLocalPrice += item.hkDollar * item.localCurrency;
    })
    return { hkDollar: total.toFixed(2), localPrice: totalLocalPrice.toFixed(2) };
}

/// <summary>
/// Author: Lewis
/// </summary>
function clearNumber(value = "") {
    return value.replace(/\D+/g, "");
}

/// <summary>
/// Author: Lewis
/// </summary>
export function formatCreditCardNumber(value) {
    if (!value) {
        return value;
    }

    const issuer = Payment.fns.cardType(value);
    const clearValue = clearNumber(value);
    let nextValue;

    switch (issuer) {
        case "amex":
            nextValue = `${clearValue.slice(0, 4)} ${clearValue.slice(
                4,
                10
            )} ${clearValue.slice(10, 15)}`;
            break;
        case "dinersclub":
            nextValue = `${clearValue.slice(0, 4)} ${clearValue.slice(
                4,
                10
            )} ${clearValue.slice(10, 14)}`;
            break;
        default:
            nextValue = `${clearValue.slice(0, 4)} ${clearValue.slice(
                4,
                8
            )} ${clearValue.slice(8, 12)} ${clearValue.slice(12, 19)}`;
            break;
    }

    return nextValue.trim();
}

/// <summary>
/// Author: Lewis
/// </summary>
export function formatCVC(value, prevValue, allValues = {}) {
    const clearValue = clearNumber(value);
    let maxLength = 4;

    if (allValues.number) {
        const issuer = Payment.fns.cardType(allValues.number);
        maxLength = issuer === "amex" ? 4 : 3;
    }

    return clearValue.slice(0, maxLength);
}

/// <summary>
/// Author: Lewis
/// </summary>
export function formatExpirationDate(value) {
    const clearValue = clearNumber(value);

    if (clearValue.length >= 3) {
        return `${clearValue.slice(0, 2)}/${clearValue.slice(2, 4)}`;
    }

    return clearValue;
}

/// <summary>
/// Author: Lewis
/// sort country list by popularity first
/// </summary>
export function sortByPopularCountry(countryList) {
    const countryPopularity = { CN: 1, HK: 2, SG: 3, UK: 4, US: 5, CA: 6 }

    countryList.map((item, index) => {
        if (countryPopularity[item.value] !== undefined) {
            item.popularity = countryPopularity[item.value];
        } else {
            item.popularity = index + Object.keys(countryPopularity).length + 1;
        }
    })

    countryList.sort((a, b) => (a.popularity > b.popularity ? 1 : -1))

    return countryList
}

/// <summary>
/// Author: Andrew
/// </summary>
export function getFullUrl(url, toReplace) {
    let fullUrl = url;
    toReplace.map((data, value) => {
        fullUrl = url.replace(`:${Object.keys(data)[0]}`, Object.values(data)[0]);
    });
    return fullUrl;
}

/// <summary>
/// Author: Christopher Chan
/// </summary>
export function propertyIsUndefined(data) {
    return data === undefined;
}

/// <summary>
/// Author: Jia Ren GOH
/// </summary>
export const doubleClickNavigate = (history, weburl) => {
    return {
        onDoubleClick: () => history.push(weburl),
    }
}

/// <summary>
/// Author: Jia Ren GOH
/// </summary>
export const doubleClickModal = (toggle) => {
        return {
            onDoubleClick: () => toggle(),
        }
}

/// <summary>
/// Author: Lewis
/// </summary>
export const convertObjCamelCaseToStartCase = (obj) => {
    return _.mapKeys(obj, (v, k) => startCase(k))
}

/// <summary>
/// Author: Christopher Chan
/// </summary>
export const tryCatch = (passedFunction) => {
    try {
        passedFunction();
    }
    catch (error) {
        Report.Init({ plainText: false });
        Report.Warning(
            'Oops..',
            'Unfortunately, an issue has occured that has prevented the system from performing as expected. ' +
            'Please try again and if the issue is not resolved, please contact any one of our helpful assistants.<br/><br/>' +
            `<b>Error</b>: <i>${error}</i>`
        );
    }
}

/// <summary>
/// Author: Samuel
/// </summary>
export const useQuery = (location) => {
        return new URLSearchParams(location.search);
}

/// <summary>
/// Author: Samuel
/// </summary>
export const cleanObject = (obj) => {
        return _.pickBy(obj, (value) => !stringIsNullOrEmpty(value));
}

/// <summary>
/// Author: Samuel
/// </summary>
export const convertToSelectOptions = (arr, targetLabelAttr, targetValueAttr, othersAttr = []) => {
        let tempArr = [];
        if (_.isArray(arr)) {
            tempArr = arr.map((item) => {
                let tempAttrs = {};
                othersAttr.forEach(attr => tempAttrs[attr] = item?.[attr]);
    
                return {
                    label: item?.[targetLabelAttr],
                    value: item?.[targetValueAttr],
                    ...tempAttrs
                }
            })
        }
    
        return tempArr;
}

/// <summary>
/// Author: Samuel
/// </summary>
export const handleSetPageOptions = (tableRef, totalCount = 0) => {
        if (tableRef.current) {
            tableRef.current.setPageOptions({ totalCount })
        }
    }
    