import React, { useMemo, useState, useRef, useEffect, forwardRef } from "react";
import ReactTable from 'components/react-table/ReactTable';
import { Card, CardHeader, CardBody, Row, Col, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { useHistory, Link } from 'react-router-dom';
import { InputTypes, SelectField, WebUrl, ApiKey } from "util/Constant";
import { createMultiPartFormBody, stringIsNullOrEmpty } from "util/Utility.js";
import InputHoc from "components/form/InputHoc.js";
import { useForm, useFieldArray } from "react-hook-form";
import { useRecoilState, useSetRecoilState, useResetRecoilState } from "recoil";
import { appState, customerFormState, natureOfBusinessesState, blacklistState } from "recoil/Atoms.js";
import { Block, Loading } from 'notiflix';
import Notiflix from "notiflix";
import CustomerDao from "data/CustomerDao.js";
import { Config, ConfigEnum } from 'util/Config';
import { Confirm, Report } from "notiflix";
import { report } from "util/Notiflix";

const OtherInfo = ({ jumpToStep, token, setCustomerFormState, natureOfBusinessesOptions, blacklistOptions, toggle, getCustomerList, selectedCustomer, promptCreateEnquiry, isDisabled }) => {
        const { register, handleSubmit, control, errors, reset, setValue } = useForm({});
        let configInstance = Config.getInstance();
        var token = configInstance.getValue(ConfigEnum._TOKEN);
        const history = useHistory();

        const { fields, append, remove } = useFieldArray({
            control,
            name: 'websites'
        });

        const {
                fields: websiteFields,
                append: websiteAppend,
                remove: websiteRemove
            } = useFieldArray({ control, name: "websites" });
        

        const onSubmit = async (data) => {
                data["id"] = selectedCustomer.id;
                Notiflix.Confirm.Init({ plainText: false });
                Notiflix.Confirm.Show(
                    'Confirm update?',
                    `Please confirm you would like to submit the update`,
                    'Confirm',
                    'No',
                    async () => {
                        let dao = new CustomerDao();
                        await dao.updateOtherInfo(data, token).then(responseJson => {
                                if (responseJson[ApiKey._API_SUCCESS_KEY]) {
                                    Report.Success('Success', 'Successfully update customer.', 'Okay', () => history.goBack());
                                } else {
                                    Report.Failure('Error', 'Operation Failed. ' + responseJson[ApiKey._API_MESSAGE_KEY]);
                                }
                            })
                    }
                )
        }
    
        /// <summary>
        /// Author: Christopher Chan
        /// </summary>
        useEffect(() => {
        
            if (selectedCustomer) {
                websiteAppend(selectedCustomer?.websites)
                //append(selectedCustomer?.websites);
    
                let formattedNob = selectedCustomer?.natureOfBusinesses.map(item => {
                    return { label: item.nob_name, value: item.id };
                });
    
                let formattedBlacklist = selectedCustomer?.blacklists.map(item => {
                    return { label: item.name, value: item.id };
                });
                
                setValue('natureOfBusinesses', formattedNob);
                setValue('blacklists', formattedBlacklist);
            } else {
                    websiteAppend();
            }
        }, [selectedCustomer]);
    
        return (
            <form onSubmit={handleSubmit(onSubmit)}>
                {!isDisabled && <button type="button" className="btn btn-sm btn-themed mb-2" onClick={() => append()}><i className="fa fa-plus mr-1"></i>Add Website</button>}
                {
                    websiteFields.map((item, index) => {
                        return (
                            <div className="mt-2 mb-2">
                                <strong>Website {index + 1}</strong>
                                <Row>
                                    <Col><InputHoc  disabled={isDisabled} name={`websites[${index}].url`} label="URL"  defaultValue={item?.url} ref={register({ required: 'Website is required.' })} /></Col>
                                    <Col xs={1}>{!isDisabled && <div className="btn-round expand-red m-t-5 pull-right" onClick={() => remove(index)}><i className="fas fa-trash-alt"></i></div>}</Col>
                                </Row>
                            </div>
                        );
                    })
                }
    
                <hr style={{ marginBottom: 5 }} />
    
                <InputHoc   disabled={isDisabled} label='Nature of Business' isMulti={true} name='natureOfBusinesses' inputType={InputTypes.SELECT} control={control} options={natureOfBusinessesOptions} ref={register} error={errors?.natureOfBusinesses?.message} />
                <InputHoc   disabled={isDisabled} label='Blacklist' isMulti={true} name='blacklists' inputType={InputTypes.SELECT} control={control} options={blacklistOptions} ref={register} error={errors?.blacklists?.message} />
    
                <hr style={{ marginBottom: 5 }} />
                {!isDisabled && <button type="submit" className="btn btn-themed btn-min-width pull-right mt-2">Submit Changes</button> }
            </form>
        );
    };
    
    export default OtherInfo;