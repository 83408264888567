import { isEmpty } from "lodash";
import { ApiUrl } from "util/Constant";
import { stringIsNullOrEmpty } from "util/Utility";
import DataAccessObject from "./DataAccessObject";

/**
 * Author : Sim
 * DataAccessObject's fetch will return the promise.
 */
class CustomerDao extends DataAccessObject {
  async getCustomerList(token, queryParams) {
    let url = ApiUrl._API_CUSTOMER_LIST;

    if (!isEmpty(queryParams)) {
      let queryStr = new URLSearchParams(queryParams).toString();
      if (!stringIsNullOrEmpty(queryStr)) {
        url += `?${queryStr}`;
      }
    }
    return this.get(url, token);
  }

  async createCustomer(data, token) {
    return this.post(ApiUrl._API_CREATE_CUSTOMER, data, token);
  }

  async createCustomerForm(data, token) {
    return this.postForm(ApiUrl._API_CREATE_CUSTOMER, data, token);
  }

  async editCustomer(data, token) {
    return this.postForm(ApiUrl._API_EDIT_CUSTOMER, data, token);
  }

  async delete(id, token) {
    return this.post(`${ApiUrl._API_DELETE_CUSTOMER}/${id}`, null, token);
  }

  async getCustomer(id, token) {
    return this.get(`${ApiUrl._API_GET_CUSTOMER}/${id}`, token);
  }

  async updatePersonalInfo(data, token) {
    return this.post(ApiUrl._API_UPDATE_CUSTOMER_PERSONAL, data, token);
  }

  async updateContactInfo(data, token) {
    return this.post(ApiUrl._API_UPDATE_CUSTOMER_CONTACT, data, token);
  }

  async updateLocationInfo(data, token) {
    return this.post(ApiUrl._API_UPDATE_CUSTOMER_LOCATION, data, token);
  }

  async updateOtherInfo(data, token) {
    return this.post(ApiUrl._API_UPDATE_CUSTOMER_OTHER, data, token);
  }

  async deactivate(data, token) {
    return this.post(ApiUrl._API_DEACTIVATE_CUSTOMER, data, token);
  }
}

export default CustomerDao;
