import React, { useState, useEffect, forwardRef } from 'react';
import classnames from 'classnames';

/// <summary>
/// Author: Christopher Chan
/// </summary>
const TextArea = forwardRef((props, ref) => {
    const {
        readOnly,
        icon,
        label,
        placeholder,
        showLabel,
        isEditable = true,
        style,
        error,
        ...rest
      } = props;
    const [hasError, setHasError] = useState();

    const _inputErrors = classnames(
        'form-control',
        'form-control-lg',
        'm-b-5',
        { 'has-error': hasError }
    );

    /// <summary>
    /// Author: Christopher Chan
    /// </summary>
    useEffect(() => {
        setHasError(error);
    }, [error]);

    return (
    <> 
      {showLabel && <label className="custom-input-label">{label}</label>}
      <div
        className={`input-container ${error ? "has-error" : ""} ${
          readOnly ? "disabled" : ""
        }`}
        style={style}
      >
        {icon && (
          <div className={`icon-container ${readOnly ? "disabled" : ""}`}>
            {icon}
          </div>
         )}
         <textarea ref={ref} {...rest}  className={`custom-form-control ${readOnly ? "disabled" : ""}`}></textarea>        
      </div>
 </>);
});

TextArea.defaultProps = {
    rows: 4,
    placeholder: ' '
}

export default TextArea;