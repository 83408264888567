import React, {
  useMemo,
  useState,
  useEffect,
  useRef,
  useCallback,
} from "react";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import Notiflix from "notiflix";
import { useForm, useFieldArray } from "react-hook-form";
import CustomerDao from "data/CustomerDao.js";

import { Config, ConfigEnum } from "util/Config";
import { InputTypes, SelectField, WebUrl, ApiKey } from "util/Constant";
import EnquiryDao from "data/EnquiryDao.js";
import CustomInput from "components/form/CustomInput";
import CustomSelect from "components/form/CustomSelect";
import CustomTag from "components/form/CustomTag";
import CustomerForm from "pages/customer/CustomerForm";
import TextArea from "components/form/Textarea";

import {
  FaUserTie,
  FaHashtag,
  FaMapMarker,
  FaBook,
  FaMapMarked,
  FaAddressCard,
  FaCity,
} from "react-icons/fa";
import CustomCheckboxList from "components/form/CustomCheckboxList";

const EnquiryForm = ({
  setAppState,
  toggle,
  enquiry,
  customerListOption,
  getCustomerList,
  onClosed,
  isOpen,
  onOpened,
  successCallback,
  isNewEnquiry,
}) => {
  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
    reset,
  } = useForm({});
  let configInstance = Config.getInstance();
  var token = configInstance.getValue(ConfigEnum._TOKEN);
  const _formRef = useRef();
  const [input, setInput] = useState("");
  const [tags, setTags] = useState([]);
  const [isKeyReleased, setIsKeyReleased] = useState(false);
  const [customerModal, setCustomerModal] = useState(false);
  const [isEditing, setIsEditing] = useState(false);

  useEffect(() => {
    console.log(enquiry);
    if (enquiry && enquiry.sites) {
      reset({ sites: enquiry.sites });
    }
    setIsEditing(false);
    if(enquiry) {
      setTags(enquiry.tags);
    }

  }, [enquiry]);

  const onChange = (e) => {
    const { value } = e.target;
    setInput(value);
  };

  const onKeyUp = () => {
    setIsKeyReleased(true);
  };

  const deleteTag = (index) => {
    setTags((prevState) => prevState.filter((tag, i) => i !== index));
  };

  /// <summary>
  /// Author: Nicholas
  /// </summary>
  const toggleCustomer = useCallback(() => {
    setCustomerModal((prev) => !prev);
  }, []);

  useEffect(() => {
    console.log(errors);
  }, [errors]);

  const onKeyDown = (e) => {
    const { key } = e;
    const trimmedInput = input.trim();

    if (
      key === "Enter" &&
      trimmedInput.length &&
      !tags.includes(trimmedInput)
    ) {
      e.preventDefault();
      setTags((prevState) => [...prevState, trimmedInput]);
      setInput("");
    }

    if (key === "Backspace" && !input.length && tags.length && isKeyReleased) {
      const tagsCopy = [...tags];
      const poppedTag = tagsCopy.pop();
      e.preventDefault();
      setTags(tagsCopy);
      setInput(poppedTag);
    }

    setIsKeyReleased(false);
  };

  const onSubmit = async (data) => {
    console.log(data);
    if (enquiry) {
      data["id"] = enquiry.id;
      data["customerId"] = enquiry.customer.id;
    }

    let inputTags = Array.from(tags);

    data["tags"] = inputTags;

    let dao = new EnquiryDao();
    let action = "";
    if (enquiry) {
      action = dao.updateEnquiry(data, token);
    } else {
      action = dao.createEnquiry(data, token);
    }

    await action.then((responseJson) => {
      if (responseJson[ApiKey._API_SUCCESS_KEY]) {
        let data = responseJson[ApiKey._API_DATA_KEY];

        Notiflix.Report.init({ plainText: false });
        Notiflix.Report.success(
          "Success",
          `Successfully ${enquiry ? "updated" : "created"} enquiry, <b>${
            data.enquiryId
          }</b>.`,
          "Okay",
          successCallback
        );
      } else {
        Notiflix.Report.failure(
          "Error",
          "Operation Failed. " + responseJson[ApiKey._API_MESSAGE_KEY]
        );
      }
    });

    setAppState((prevState) => ({ ...prevState, isBusy: false }));
  };

  return (
    <Modal size="lg" {...{ isOpen, toggle, onClosed, onOpened }} centered>
      <ModalHeader className="modal-themed" toggle={toggle}>
        <h2 style={{ marginBottom: 0 }}>
          {enquiry ? "MANAGEMENT / Edit Enquiry" : "MANAGEMENT /  New Enquiry"}
        </h2>
      </ModalHeader>
      <ModalBody className="scroll-component">
        <form onSubmit={handleSubmit(onSubmit)}>
          {isEditing == false && enquiry && (
            <button
              type="button"
              onClick={() => {
                setIsEditing(true);
              }}
              className="btn btn-themed pull-right"
            >
              <i className="fa fa-edit"></i>
            </button>
          )}
          {isEditing == true && (
            <button
              type="button"
              onClick={() => {
                setIsEditing(false);
              }}
              className="btn btn-themed pull-right"
            >
              <i className="fa fa-door-open"></i> Exit Edit Mode
            </button>
          )}
          <div style={{ fontSize: 16, fontWeight: "bold", marginBottom: 10 }}>
            Enquirer Details
          </div>
          <div class="row">
            <div class="col-md-6">
              <label className="custom-input-label">Customer *</label>
              <CustomSelect
                control={control}
                rules={{
                  required: true,
                }}
                name="customerId"
                label="Customer *"
                icon={<FaUserTie style={{ color: "#ff0066" }} />}
                options={customerListOption}
                placeholderLabel="Search customer *"
                defaultValue={enquiry?.customerId}
                showErrorMsg={true}
                readOnly={enquiry ? !isEditing : isEditing}
                error={errors?.customerId && "Customer is required"}
                errors={errors?.customerId && "Customer is required"}
              ></CustomSelect>
            </div>
            <div class="col-md-6">
              {isEditing && (
                <button
                  type="button"
                  className="btn btn-sm btn-themed mb-2"
                  style={{ marginTop: "2px" }}
                  onClick={() => toggleCustomer()}
                >
                  <i className="fa fa-plus mr-1"></i>New Customer
                </button>
              )}
            </div>
          </div>
          <div style={{ fontSize: 16, fontWeight: "bold", marginTop: 20 }}>
            Enquiry Details {enquiry && enquiry?.enquiryId}
          </div>
          <div class="row">
            <div class="col-md-6">
              <CustomInput
                {...register("title", {
                  required: "Title is required",
                })}
                name="title"
                label="Title *"
                isEditable={enquiry ? isEditing : !isEditing}
                defaultValue={enquiry?.title}
                icon={<FaAddressCard style={{ color: "#ff0066" }} />}
                error={errors?.title?.message}
                showErrorMsg={true}
                showLabel
              />
            </div>
            <div class="col-md-6">
              <CustomInput
                {...register("quantity", {
                  required: "Quantity is required",
                })}
                name="quantity"
                label="Quantity *"
                type="number"
                isEditable={enquiry ? isEditing : !isEditing}
                defaultValue={enquiry?.quantity}
                error={errors?.quantity?.message}
                showErrorMsg={true}
                showLabel
              />
            </div>
          </div>
          <div class="row">
            <div class="col-md-4">
              <CustomInput
                {...register("latitude", {
                  required: false,
                })}
                name="latitude"
                label="Latitude"
                isEditable={enquiry ? isEditing : !isEditing}
                defaultValue={enquiry?.latitude}
                icon={<FaMapMarked style={{ color: "#ff0066" }} />}
                error={errors?.username}
                showLabel
              />
            </div>
            <div class="col-md-4">
              <CustomInput
                {...register("longitude", {
                  required: false,
                })}
                name="longitude"
                label="Longitude"
                isEditable={enquiry ? isEditing : !isEditing}
                defaultValue={enquiry?.longitude}
                icon={<FaMapMarked style={{ color: "#ff0066" }} />}
                error={errors?.longitude?.message}
                showErrorMsg={true}
                showLabel
              />
            </div>
            <div class="col-md-4">
              <label className="custom-input-label">State</label>
              <CustomSelect
                control={control}
                name="state"
                label="State"
                readOnly={enquiry ? !isEditing : isEditing}
                icon={<FaCity style={{ color: "#ff0066" }} />}
                options={SelectField._STATE_OPTIONS}
                placeholderLabel="State / Federal Teritory"
                defaultValue={enquiry?.state}
                error={errors?.state?.message}
                showErrorMsg={true}
              ></CustomSelect>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12">
              <CustomInput
                {...register("siteaddress", {
                  required: false,
                })}
                name="siteaddress"
                label="Site Address "
                defaultValue={enquiry?.siteAddress}
                isEditable={enquiry ? isEditing : !isEditing}
                icon={<FaMapMarker style={{ color: "#ff0066" }} />}
              />
            </div>
          </div>
          <div class="row">
            <div class="col-md-4">
              <label className="custom-input-label">Building Status *</label>
              <CustomSelect
                control={control}
                name="buildingStatus"
                label="Building Status *"
                options={SelectField._BUILDING_STATUS}
                rules={{ required: true }}
                placeholderLabel="Building Status"
                defaultValue={enquiry?.buildingStatus}
                readOnly={enquiry ? !isEditing : isEditing}
                error={errors?.buildingStatus && "Building Status is required"}
                errors={errors?.buildingStatus && "Building Status is required"}
                showErrorMsg={true}
              ></CustomSelect>
            </div>
            <div class="col-md-4">
              <label className="custom-input-label">Building Occupancy *</label>
              <CustomSelect
                control={control}
                name="buildingOccupancy"
                label="Building Occupancy"
                options={SelectField._BUILDING_OCCUPANCY}
                rules={{ required: true }}
                placeholderLabel="Building Occupancy"
                defaultValue={enquiry?.buildingOccupancy}
                readOnly={enquiry ? !isEditing : isEditing}
                error={
                  errors?.buildingOccupancy && "Building Occupancy is required"
                }
                errors={
                  errors?.buildingOccupancy && "Building Occupancy is required"
                }
                showErrorMsg={true}
              ></CustomSelect>
            </div>
            <div class="col-md-4">
              <label className="custom-input-label">Support Distance *</label>
              <CustomSelect
                control={control}
                name="supportDistance"
                label="Support Distance"
                rules={{ required: true }}
                options={SelectField._SUPPORT_DISTANCE}
                placeholderLabel="Support Distance"
                defaultValue={enquiry?.supportDistance}
                readOnly={enquiry ? !isEditing : isEditing}
                error={
                  errors?.supportDistance && "Support Distance is required"
                }
                errors={
                  errors?.supportDistance && "Support Distance is required"
                }
                showErrorMsg={true}
              ></CustomSelect>
            </div>
          </div>
          <div class="row" style={{ marginTop: "10px" }}>
            <div class="col-md-4">
              <label className="custom-input-label">JKKP Requirement *</label>
              <CustomSelect
                control={control}
                name="jkkpRequirement"
                label="Required JKKP"
                rules={{ required: true }}
                options={SelectField._JKKP_REQUIRED}
                placeholderLabel="Required JKKP"
                defaultValue={enquiry?.jkkpRequirement}
                error={
                  errors?.jkkpRequirement && "JKKP Requirement is required"
                }
                errors={
                  errors?.jkkpRequirement && "JKKP Requirement is required"
                }
                showErrorMsg={true}
                readOnly={enquiry ? !isEditing : isEditing}
              ></CustomSelect>
            </div>
            <div class="col-md-4">
              <label className="custom-input-label">Types of Urgency *</label>
              <CustomSelect
                control={control}
                name="urgency"
                label="Types of Urgency"
                options={SelectField._URGENCY}
                placeholderLabel="Types of Urgency"
                rules={{ required: true }}
                defaultValue={enquiry?.urgency}
                error={errors?.urgency && "Urgency is required"}
                errors={errors?.urgency && "Urgency is required"}
                showErrorMsg={true}
                readOnly={enquiry ? !isEditing : isEditing}
              ></CustomSelect>
            </div>
            <div class="col-md-4">
              <label className="custom-input-label">Action *</label>
              <CustomSelect
                control={control}
                name="action"
                label="Types of Actions"
                rules={{ required: true }}
                options={SelectField._TYPES_OF_ACTIONS}
                placeholderLabel="Types of Action"
                defaultValue={enquiry?.action}
                error={errors?.action && "Action is required"}
                errors={errors?.action && "Action is required"}
                showErrorMsg={true}
                readOnly={enquiry ? !isEditing : isEditing}
              ></CustomSelect>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12">
              <label className="custom-input-label">Tags</label>
              <CustomTag
                control={control}
                {...register("tags", {
                  required: false,
                })}
                name="hash"
                label="Tags "
                isEditable={enquiry ? isEditing : !isEditing}
                icon={<FaHashtag style={{ color: "#ff0066" }} />}
                error={errors?.username}
                onKeyDown={onKeyDown}
                tags={tags}
                input={input}
                onKeyUp={onKeyUp}
                onChange={onChange}
                deleteTag={deleteTag}
              ></CustomTag>
            </div>
          </div>
          <div class="row">
            <div class="col-md-4">
              <CustomCheckboxList
                {...register("productInvolved", {
                  required: "Product Involved is required",
                })}
                name="productInvolved"
                title="Product Involved *"
                options={SelectField._PRODUCT_INVOLVED}
                defaultValue={enquiry?.productInvolved}
                isEditable={enquiry ? isEditing : !isEditing}
                error={errors?.productInvolved?.message}
                showErrorMsg={true}
              ></CustomCheckboxList>
            </div>
            <div class="col-md-8">
              <CustomInput
                {...register("remarks", {
                  required: false,
                })}
                name="remarks"
                label="Remarks "
                icon={<FaBook style={{ color: "#ff0066" }} />}
                error={errors?.username}
                rows="7"
                type="textarea"
                isEditable={enquiry ? isEditing : !isEditing}
                defaultValue={enquiry?.remarks}
                showLabel
              />
            </div>
          </div>
          {isEditing && enquiry && (
            <button
              type="submit"
              className="btn btn-themed btn-min-width pull-right mt-2"
            >
              Submit Changes
            </button>
          )}
          {isNewEnquiry && (
            <button
              type="submit"
              className="btn btn-themed btn-min-width pull-right mt-2"
            >
              Submit Enquiry
            </button>
          )}
        </form>
      </ModalBody>
      <ModalFooter>
        <button type="button" className="btn btn-red" onClick={toggle}>
          Cancel
        </button>
      </ModalFooter>
      <CustomerForm
        setAppState={setAppState}
        toggle={toggleCustomer}
        defaultTags={tags}
        isOpen={customerModal}
        newCust={true}
        successCallback={() => {
          getCustomerList();
          toggleCustomer();
        }}
      />
    </Modal>
  );
};

export default EnquiryForm;
