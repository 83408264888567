import React, { useState, useEffect } from "react";
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { Loading, Report } from "notiflix";
import { useForm } from "react-hook-form";

import ContractDao from "data/ContractDao.js";
import QuotationDao from "data/QuotationDao.js";

import { Config, ConfigEnum } from 'util/Config';
import { InputTypes, ApiKey, Colors } from "util/Constant";
import InputHoc from "components/form/InputHoc.js";

/// <summary>
/// Author: Christopher Chan
/// </summary>
export default ({ isOpen, toggle }) => {
    const [quotations, setQuotations] = useState([]);
    const { handleSubmit, control, errors } = useForm({});

    let configInstance = Config.getInstance();
    var token = configInstance.getValue(ConfigEnum._TOKEN);

    /// <summary>
    /// Author: Christopher Chan
    /// </summary>
    const onSubmit = async (data) => {
        Loading.Circle();

        let dao = new ContractDao();

        await dao.createOrUpdateContract(data, token).then(responseJson => {
            Loading.Remove();

            if (responseJson[ApiKey._API_SUCCESS_KEY]) {
                Report.Init({ plainText: false });
                Report.Success(
                    'Success',
                    `Successfully created contract, <b>${data.contractId}</b>.`,
                    'Okay',
                    () => toggle(true)
                );
            } else {
                Report.Failure('Error', 'Operation Failed. ' + responseJson[ApiKey._API_MESSAGE_KEY]);
            }
        })
    }

    /// <summary>
    /// Author: Christopher Chan
    /// </summary>
    useEffect(() => {
        if (isOpen) {
            (async () => {
                Loading.Init({ backgroundColor: "rgba(0, 0, 0, 0.5)", svgSize: "50px", svgColor: Colors.THEME });
                Loading.Circle();

                let dao = new QuotationDao();

                await dao.getQuotationList(token, true).then((response) => {
                    if (response[ApiKey._API_SUCCESS_KEY]) {
                        let data = response[ApiKey._API_DATA_KEY];

                        let quotations = data.map((item) => {
                            return {
                                label: item.quotationId,
                                value: item.id
                            }
                        });

                        setQuotations([...quotations]);
                    }
                    else {
                        Report.Failure('Error', 'Failed to load empty contract quotation. Please try again later');
                    }
                });

                Loading.Remove();
            })();
        }
    }, [isOpen]);

    return (
        <Modal isOpen={isOpen} toggle={() => toggle(false)} centered>
            <ModalHeader toggle={() => toggle(false)}>Add New Contract</ModalHeader>
            <form onSubmit={handleSubmit(onSubmit)}>
                <ModalBody>
                    <div id="inputLoad" className="mt-2 mb-2">
                        <InputHoc
                            name="id"
                            label="Quotation ID"
                            inputType={InputTypes.SELECT}
                            options={quotations}
                            control={control}
                            rules={{ required: 'Quotation ID is required.' }}
                            error={errors?.id?.message}
                        />
                    </div>
                </ModalBody>
                <ModalFooter>
                    <button type="submit" className="btn btn-themed btn-min-width">Add</button>
                </ModalFooter>
            </form>
        </Modal>
    );
};
