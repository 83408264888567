import { isEmpty } from "lodash";
import { ApiUrl } from "util/Constant";
import { stringIsNullOrEmpty } from "util/Utility";
import DataAccessObject from "./DataAccessObject";

/**
 * Author : Nicholas
 * DataAccessObject's fetch will return the promise.
 */
class ActivityLogDao extends DataAccessObject {
  async getActivityLog(token, queryParams) {
    let url = ApiUrl._API_ACTIVITY_LOG;

    if (!isEmpty(queryParams)) {
      let queryStr = new URLSearchParams(queryParams).toString();
      if (!stringIsNullOrEmpty(queryStr)) {
        url += `?${queryStr}`;
      }
    }
    return this.get(url, token);
  }

  async getUserActivityLog(token, userId) {
    let url = ApiUrl._API_USER_ACTIVITY_LOG + "?userId=" + userId;

    return this.get(url, token);
  }

  async getEventLog(token, queryParams) {
    let url = ApiUrl._API_EVENT_LOG;

    if (!isEmpty(queryParams)) {
      let queryStr = new URLSearchParams(queryParams).toString();
      if (!stringIsNullOrEmpty(queryStr)) {
        url += `?${queryStr}`;
      }
    }
    return this.get(url, token);
  }

  async getCustomerLog(token, queryParams) {
    let url = ApiUrl._API_GET_CUSTOMER_LOGS;

    if (!isEmpty(queryParams)) {
      let queryStr = new URLSearchParams(queryParams).toString();
      if (!stringIsNullOrEmpty(queryStr)) {
        url += `?${queryStr}`;
      }
    }
    return this.get(url, token);
  }
}

export default ActivityLogDao;
