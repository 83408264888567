import React, {
  useMemo,
  useState,
  useEffect,
  useRef,
  useCallback,
} from "react";
import ReactTable from "components/react-table/ReactTable";
import {
  Row,
  Col,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";
import Notiflix from "notiflix";
import { Block } from "notiflix";
import { useForm, useFieldArray } from "react-hook-form";
import { useHistory } from "react-router-dom";
import { useRecoilState, useSetRecoilState } from "recoil";
import classnames from "classnames";

import { Config, ConfigEnum } from "util/Config";
import { InputTypes, SelectField, WebUrl, ApiKey } from "util/Constant";
import {
  stringIsNullOrEmpty,
  doubleClickNavigate,
  handleSetPageOptions,
  doubleClickModal,
} from "util/Utility.js";
import { appState, siteListState, customerListState } from "recoil/Atoms.js";

import EnquiryDao from "data/EnquiryDao.js";
import CustomerDao from "data/CustomerDao.js";
import QuotationDao from "data/QuotationDao.js";
import InputHoc from "components/form/InputHoc.js";
import EnquiryForm from "./EnquiryForm";

const NewQuotation = ({
  setAppState,
  toggleQuotation,
  siteListOption,
  getEnquirySitesList,
}) => {
  const { register, handleSubmit, control, errors, reset } = useForm({
    defaultValues: {
      items: [
        {
          name: "",
          price: "",
          quantity: "",
          description: "",
          discount: "",
          tax: "",
        },
      ],
    },
  });
  const { fields, append, remove } = useFieldArray({
    control,
    name: "items",
  });

  let configInstance = Config.getInstance();
  var token = configInstance.getValue(ConfigEnum._TOKEN);

  useEffect(() => {
    return reset;
  }, []);

  const onSubmit = async (data) => {
    setAppState((prevState) => ({ ...prevState, isBusy: true }));

    let quotationData = data;
    // quotationData["enquiryId"] = enquiry.id;

    let dao = new QuotationDao();
    await dao.createQuotation(quotationData, token).then((responseJson) => {
      if (responseJson[ApiKey._API_SUCCESS_KEY]) {
        Notiflix.Report.Success(
          "Success",
          responseJson[ApiKey._API_MESSAGE_KEY]
        );
        getEnquirySitesList();
        toggleQuotation();
      } else {
        Notiflix.Report.Failure(
          "Error",
          "Operation Failed. " + responseJson[ApiKey._API_MESSAGE_KEY]
        );
      }
    });

    setAppState((prevState) => ({ ...prevState, isBusy: false }));
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="mt-2 mb-2">
        <Row>
          <Col>
            <InputHoc
              name="siteId"
              label="Enquiry Site"
              inputType={InputTypes.SELECT}
              options={siteListOption && siteListOption}
              control={control}
              rules={{ required: "Enquiry Site is required." }}
            />
          </Col>
        </Row>
      </div>
      <button
        type="button"
        className="btn btn-sm btn-themed mb-2"
        onClick={() => append()}
      >
        <i className="fa fa-plus mr-1"></i>Add Item
      </button>
      {fields.map((item, index) => {
        return (
          <div className="mt-2 mb-2">
            <Row>
              <Col style={{ alignSelf: "center" }}>
                <strong>Item {index + 1}</strong>
              </Col>
              {index !== 0 && (
                <Col xs={1}>
                  <div
                    className="btn-round expand-red m-t-5 pull-right"
                    onClick={() => remove(index)}
                  >
                    <i className="fas fa-trash-alt"></i>
                  </div>
                </Col>
              )}
            </Row>
            <Row>
              <Col>
                <InputHoc
                  name={`items[${index}].name`}
                  label="Item Name"
                  ref={register({ required: "Item Name is required." })}
                />
              </Col>
              <Col>
                <InputHoc
                  name={`items[${index}].price`}
                  label="Item Price"
                  ref={register({
                    required: "Item Price is required.",
                    pattern: {
                      value: /^[0-9]+(\.[0-9][0-9]?)?$/i,
                      message: "Invalid Item Price",
                    },
                  })}
                />
              </Col>
            </Row>
            <Row>
              <Col>
                <InputHoc
                  name={`items[${index}].serialNumber`}
                  label="Serial Number"
                  ref={register({ required: "Serial Number is required." })}
                />
              </Col>
              <Col>
                <InputHoc
                  name={`items[${index}].description`}
                  label="Description"
                  ref={register({ required: "Description is required." })}
                />
              </Col>
            </Row>
            <Row>
              <Col>
                <InputHoc
                  name={`items[${index}].discount`}
                  label="Discount Percentage"
                  ref={register({
                    required: false,
                    pattern: {
                      value: /^[0-9]+(\.[0-9][0-9]?)?$/i,
                      message: "Invalid Discount Percentage",
                    },
                  })}
                />
              </Col>
              <Col>
                <InputHoc
                  name={`items[${index}].tax`}
                  label="Tax Percentage"
                  ref={register({
                    required: false,
                    pattern: {
                      value: /^[0-9]+(\.[0-9][0-9]?)?$/i,
                      message: "Invalid Tax Percentage",
                    },
                  })}
                />
              </Col>
            </Row>
          </div>
        );
      })}

      <button
        type="submit"
        className="btn btn-themed btn-min-width pull-right mt-2"
      >
        Submit Quotation
      </button>
    </form>
  );
};

///<summary>
///Author: Christopher Chan
///</summary>
export default () => {
  const [enquiry, setEnquiry] = useState();
  const [enquiryList, setEnquiryList] = useState([]);
  const [modal, setModal] = useState(false);
  const [quotationModal, setQuotationModal] = useState(false);
  const [siteListOption, setSiteListState] = useRecoilState(siteListState);
  const history = useHistory();
  const setAppState = useSetRecoilState(appState);
  const [customerListOption, setCustomerListState] =
    useRecoilState(customerListState);
  const [listQueryParams, setListQueryParams] = useState();

  let configInstance = Config.getInstance();
  let dao = useMemo(() => new EnquiryDao(), []);
  var token = configInstance.getValue(ConfigEnum._TOKEN);
  const [isNewEnquiry, setIsNewEnquiry] = useState(false);
  const _history = useHistory();
  const _tableRef = useRef();

  /// <summary>
  /// Author: Christopher Chan
  /// </summary>
  const getEnquiryList = useCallback(
    async (params) => {
      Block.circle("#enquiryTable");

      await dao.getEnquiryList(token, params).then((response) => {
        if (response[ApiKey._API_SUCCESS_KEY]) {
          var data = response[ApiKey._API_DATA_KEY];
          var enquiries = [];

          data.map((item) => {
            var enq = {
              id: item.id,
              enquiryId: item.enquiryId,
              customerId: item.customerId,
              customer: item.customer,
              sites: item.sites,
              status: item.status,
              statusName: item.statusName,
              remarks: item.remarks,
              creation_date: item.creation_date,
              modified_date: item.modified_date,
              created_by: item.created_by,
              last_modified_by: item.last_modified_by,
              deleted_by: item.deleted_by,
              deleted_date: item.deleted_date,
              title: item.title,
              quantity: item.quantity,
              latitude: item.latitude,
              longitude: item.longitude,
              state: item.state,
              siteAddress: item.siteAddress,
              buildingStatus: item.buildingStatus,
              buildingStatusName: item.buildingStatusName,
              buildingOccupancy: item.buildingOccupancy,
              buildingOccupancyName: item.buildingOccupancyName,
              supportDistance: item.supportDistance,
              supportDistanceName: item.supportDistanceName,
              jkkpRequirement: item.jkkpRequirement,
              jkkpRequirementName: item.jkkpRequirementName,
              urgency: item.urgency,
              urgencyName: item.urgencyName,
              action: item.action,
              actionName: item.actionName,
              productInvolved: item.productInvolved,
              tags: item.tags,
            };
            enquiries.push(enq);
          });
          setEnquiryList(enquiries);
          handleSetPageOptions(_tableRef, response[ApiKey._API_TOTAL_KEY] ?? 0);
        } else {
          Notiflix.Report.failure(
            "Error",
            "Failed to load enquiries. Please try again later"
          );
        }
      });

      Block.remove("#enquiryTable");
    },
    [dao, token]
  );

  /// <summary>
  /// Author: Christopher Chan
  /// </summary>
  const getCustomerList = async () => {
    let dao = new CustomerDao();
    await dao.getCustomerList(token).then((response) => {
      if (response[ApiKey._API_SUCCESS_KEY]) {
        var data = response[ApiKey._API_DATA_KEY];
        var customerList = [];
        data.map((item) => {
          var custOption = {
            label: item.fullName + " (" + item.icNumber + ")",
            value: item.id,
          };
          customerList.push(custOption);
        });
        setCustomerListState(customerList);
      } else {
        Notiflix.Report.failure(
          "Error",
          "Failed to load customer. Please try again later"
        );
      }
    });
  };

  /// <summary>
  /// Author: Christopher Chan
  /// </summary>
  const deleteEnquiry = async (row) => {
    var id = row.id;
    let dao = new EnquiryDao();

    await dao.delete(id, token).then((responseJson) => {
      if (responseJson[ApiKey._API_SUCCESS_KEY]) {
        Notiflix.Report.Success("Success", "Enquiry Deleted");
        getEnquiryList();
      } else {
        Notiflix.Report.Failure(
          "Error",
          "Failed to delete enquiry. " + responseJson[ApiKey._API_MESSAGE_KEY]
        );
      }
    });
  };

  const getEnquirySitesList = async () => {
    let dao = new EnquiryDao();
    await dao.getEnquirySitesWithoutQuotation(token).then((response) => {
      if (response[ApiKey._API_SUCCESS_KEY]) {
        var data = response[ApiKey._API_DATA_KEY];
        var siteOption = [];
        data.map((item) => {
          var option = {
            label: item.siteProjectTitle + " (" + item.enquiryId + ")",
            value: item.id,
          };
          siteOption.push(option);
        });
        setSiteListState(siteOption);
      } else {
        Notiflix.Report.failure(
          "Error",
          "Failed to load enquiry sites. Please try again later"
        );
      }
    });
  };

  /// <summary>
  /// Author: Christopher Chan
  /// </summary>
  const promptCreateQuotation = (enquiryId, siteId) => {
    Notiflix.Confirm.Init({ plainText: false });
    Notiflix.Confirm.Show(
      "Proceed with Quotation Creation",
      `Would you like to proceed with creating a quotation for this enquiry, <b>${enquiryId}</b>?`,
      "Proceed",
      "No",
      () => {
        history.push(
          WebUrl._QUOTATION_CREATE_OR_UPDATE.replace(":id", "create"),
          { siteId }
        );
      },
      toggle
    );
  };

  /// <summary>
  /// Author: Christopher Chan
  /// </summary>
  const toggle = () => {
    setIsNewEnquiry(true);
    setModal((prev) => !prev);
  };

  /// <summary>
  /// Author: Christopher Chan
  /// </summary>
  const toggleQuotation = () => {
    setQuotationModal(!quotationModal);
    setEnquiry(null);
  };

  /// <summary>
  /// Author: Nicholas Lai
  /// </summary>
  const tableClickEvent = (data) => {
    setIsNewEnquiry(false);
    setEnquiry(data);
    setModal((prev) => !prev);
  };

  /// <summary>
  /// Author: Christopher Chan
  /// </summary>
  useEffect(() => {
    listQueryParams && getEnquiryList(listQueryParams);
    getCustomerList();
    getEnquirySitesList();
  }, [getEnquiryList, listQueryParams]);

  const _COLUMN = useMemo(() => [
    {
      Header: "Enquiry ID",
      Cell: ({ row }) => <b>{row.original.enquiryId}</b>,
    },
    {
      Header: "Title",
      Cell: ({ row }) => <b>{row.original.title}</b>,
    },
    {
      Header: "Short Name",
      accessor: "customer.shortName",
    },
    {
      Header: "Full Name",
      accessor: "customer.fullName",
    },
    {
      Header: "Email",
      accessor: "customer.email",
    },
    {
      Header: "Phone",
      accessor: "customer.mobileNumber",
    },
    {
      Header: "Remarks",
      Cell: ({ row }) => {
        return row.original.remarks ?? "-";
      },
    },
    {
      Header: "Status",
      Cell: ({ row }) => {
        let status = row.original.status;
        let classes = classnames("label custom-status-label", {
          "label-secondary": status === "0",
          "label-danger": status === "-1",
          "label-success": status === "1",
          "label-danger": status === "-2",
        });

        return <div className={classes}>{row.original.statusName}</div>;
      },
      disableSortBy: true,
      disableFilters: true,
      style: { overflow: "visible" },
      sticky: "right"
    },
    {
      Header: "Action",
      Cell: ({ row }) => (
        <>
          <div
            className="btn-table"
            title="View Enquiry"
            onClick={() => tableClickEvent(row.original)}
          >
            <i className="fa fa-eye"></i>
          </div>
          {row.original.deleted_date === null && [
            <div
              className="btn-table"
              title="Create Enquiry"
              onClick={() =>
                history.push(
                  WebUrl._QUOTATION_CREATE_OR_UPDATE.replace(":id", "create")
                )
              }
            >
              <i className="fa fa-file"></i>
            </div>,
            <div
              className="btn-table last-btn"
              title="Delete Customer"
              onClick={() => {
                Notiflix.Confirm.show(
                  "Confirmation",
                  "Do you sure to delete enquiry?",
                  "Yes",
                  "No",
                  function () {
                    deleteEnquiry(row.original);
                  }
                );
              }}
            >
              <i className="fa fa-trash"></i>
            </div>,
          ]}
          {/* {
                    row.original.status === 0 &&
                    <WinLossButtons id={row.original.enquiryId} type="Enquiry" apiCall={updateEnquiryStatus} />
                } */}
        </>
      ),
      disableSortBy: true,
      disableFilters: true,
      style: { overflow: "visible" },
      sticky: "right",
      width: 90,
    },
  ]);

  return (
    <>
      <div className=" float-xl-right">
        <button
          type="type"
          className="btn btn-sm btn-themed mb-2"
          style={{ marginTop: "10px" }}
          onClick={toggle}
        >
          <i className="fa fa-plus mr-1"></i>Add Enquiry
        </button>
      </div>
      <h1 className="page-header">Enquiry Management</h1>
      <div id="enquiryTable">
        <ReactTable
          columns={_COLUMN}
          data={enquiryList && enquiryList}
          getRowProps={(row) => {
            return {
              onDoubleClick: () => tableClickEvent(row.original),
            };
          }}
          filterable
          canPreviousPage={true}
          canNextPage={true}
          ref={_tableRef}
          setQueryParams={setListQueryParams}
          disabledApiPagination={false}
        />
      </div>
      {modal && (
        <EnquiryForm
          setAppState={setAppState}
          toggle={toggle}
          isOpen={modal}
          enquiry={enquiry && enquiry}
          customerListOption={customerListOption}
          getEnquiryList={getEnquiryList}
          promptCreateQuotation={promptCreateQuotation}
          getCustomerList={getCustomerList}
          isNewEnquiry={isNewEnquiry}
          onClosed={() => {
            setEnquiry(null);
          }}
          successCallback={() => {
            getEnquiryList();
            toggle();
          }}
        />
      )}

      <Modal isOpen={quotationModal} toggle={toggleQuotation} centered>
        <ModalHeader toggle={toggleQuotation}>
          {"Add New Quotation"}
        </ModalHeader>
        <ModalBody>
          <NewQuotation
            setAppState={setAppState}
            toggleQuotation={toggleQuotation}
            siteListOption={siteListOption}
            getEnquirySitesList={getEnquirySitesList}
          />
        </ModalBody>
        <ModalFooter>
          <button
            type="button"
            className="btn btn-red"
            onClick={toggleQuotation}
          >
            Cancel
          </button>
        </ModalFooter>
      </Modal>
    </>
  );
};
