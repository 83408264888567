import React, { useState, useEffect } from 'react';
import { Config, ConfigEnum } from 'util/Config';
import { ApiKey } from "util/Constant";
import { appState, authCredentialState } from './Atoms';
import AuthenticationDao from 'data/AuthenticationDao';
import { useRecoilState, useSetRecoilState } from 'recoil';
import { Loading } from 'notiflix';
import { Colors } from 'util/Constant';

Loading.init({
    backgroundColor: "rgb(255, 255, 255)",
    svgColor: Colors.THEME,
    messageColor: Colors.GREY,
});

/// <summary>
/// Author: Lewis
/// </summary>
const useAuthController = (props) => {
    const { loginCallback, validateCredentialsCallback, logoutCallback } = props

    let dao = new AuthenticationDao();
    let configInstance = Config.getInstance();
    const [authCredential, setAuthCredential] = useRecoilState(authCredentialState);
    const [errorMessages, setErrorMessages] = useState('');
    const setAppState = useSetRecoilState(appState);

    /// <summary>
    /// Author: Sim
    /// </summary>
    const login = async (data) => {
        Loading.circle('Authenticating...');

        await dao.login(data).then(responseJson => {
            if (responseJson[ApiKey._API_SUCCESS_KEY]) {
                let responseData = responseJson[ApiKey._API_DATA_KEY];
                configInstance.setData(ConfigEnum._TOKEN, responseData['accessToken']);
                configInstance.setData(ConfigEnum._USER, responseData);
                setAuthCredential(responseData);
                loginCallback.success();
                return
            }
            setErrorMessages(responseJson[ApiKey._API_MESSAGE_KEY]);
            loginCallback.fail();
        })

        Loading.remove();
    }

    /// <summary>
    /// Author: Sim
    /// </summary>
    const validateCredentials = async () => {
        Loading.circle();

        var token = configInstance.getValue(ConfigEnum._TOKEN);

        await dao.validateLogin(token).then(responseJson => {
            if (responseJson[ApiKey._API_SUCCESS_KEY]) {
                let responseData = responseJson[ApiKey._API_DATA_KEY];
                validateCredentialsCallback.success();
                setAuthCredential(responseData);
                updateLoginUser(responseData, token);
                return
            }
            setErrorMessages(responseJson[ApiKey._API_MESSAGE_KEY]);
            validateCredentialsCallback.fail();
        })

        Loading.remove();
    }

    const updateLoginUser = (userData, token) => {
        var currentToken = configInstance.getValue(ConfigEnum._TOKEN);
        if (currentToken !== token) {
            configInstance.setData(ConfigEnum._TOKEN, token);
        }
        configInstance.setData(ConfigEnum._USER, userData);
    }


    /// <summary>
    /// Author: Sim
    /// </summary>
    const logout = async () => {
        Loading.circle('Logging out...');
        setAppState(prevState => ({ ...prevState, isBusy: true }));

        var token = configInstance.getValue(ConfigEnum._TOKEN);

        await dao.logout(token).then(responseJson => {
            if (responseJson[ApiKey._API_SUCCESS_KEY]) {
                logoutCallback.success();
                configInstance.setData(ConfigEnum._TOKEN, '');
                configInstance.setData(ConfigEnum._USER, null);
                setAuthCredential({});
                return
            }
            setErrorMessages(responseJson[ApiKey._API_MESSAGE_KEY]);
            logoutCallback.fail();
        })

        setAppState(prevState => ({ ...prevState, isBusy: false }));
        Loading.remove();
    }

    return { login, validateCredentials, logout, authCredential, errorMessages }
};

useAuthController.defaultProps = {
    loginCallback: { success: () => void (0), fail: () => void (0) },
    validateCredentialsCallback: { success: () => void (0), fail: () => void (0) },
    logoutCallback: { success: () => void (0), fail: () => void (0) }
}

export default useAuthController;