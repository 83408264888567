import React, {
  useMemo,
  useState,
  useRef,
  useEffect,
  useCallback,
} from "react";
import ReactTable from "components/react-table/ReactTable";
import Notiflix from "notiflix";
import { useHistory } from "react-router-dom";
import { ApiKey, _IMAGE_URL,HostKey } from "util/Constant";
import { handleSetPageOptions } from "util/Utility.js";
import { Config, ConfigEnum } from "util/Config";
import CommonDao from "data/CommonDao.js";
import CustomerDao from "data/CustomerDao.js";
import { useRecoilState, useSetRecoilState } from "recoil";
import {
  appState,
  natureOfBusinessesState,
  blacklistState,
} from "recoil/Atoms.js";
import { Block } from "notiflix";
import NoImagePlaceholder from "assets/img/user/No-Image-Placeholder.svg.png";
import CustomerForm from "pages/customer/CustomerForm";
let configInstance = Config.getInstance();
var token = configInstance.getValue(ConfigEnum._TOKEN);

///<summary>
///Author: Sim
///</summary>
const Customer = (props) => {
  const _history = useHistory();
  const [customer, setCustomer] = useState(null);
  const [customerList, setCustomerList] = useState([]);
  const [modal, setModal] = useState(false);
  const [enquiryModal, setEnquiryModal] = useState(false);
  const history = useHistory();

  const [natureOfBusinessesOptions, setNatureOfBusinessesState] =
    useRecoilState(natureOfBusinessesState);
  const [blacklistOptions, setBlacklistState] = useRecoilState(blacklistState);
  const setAppState = useSetRecoilState(appState);
  const _tableRef = useRef();
  const [listQueryParams, setListQueryParams] = useState();
  const [tags, setTags] = useState([]);
  const [isNewCust, setIsNewCust] = useState(false);
  const [open, setOpen] = useState(false);
  let configInstance = Config.getInstance();
  let dao = useMemo(() => new CustomerDao(), []);
  var token = configInstance.getValue(ConfigEnum._TOKEN);

  const getCustomerList = useCallback(
    async (params) => {
      Block.circle("#customersTable");

      await dao.getCustomerList(token, params).then((response) => {
        if (response[ApiKey._API_SUCCESS_KEY]) {
          var data = response[ApiKey._API_DATA_KEY];
          var customers = [];

          data.map((item) => {
            customers.push(item);
          });

          setCustomerList(customers);
          handleSetPageOptions(_tableRef, response[ApiKey._API_TOTAL_KEY] ?? 0);
        } else {
          Notiflix.Report.failure(
            "Error",
            "Failed to load customer. Please try again later"
          );
        }
      });

      Block.remove("#customersTable");
    },
    [token, dao]
  );

  useEffect(() => {
    listQueryParams && getCustomerList(listQueryParams);
    getNatureOfBusinessList();
    getBlacklist();
  }, [getCustomerList, listQueryParams]);

  const getNatureOfBusinessList = async () => {
    let dao = new CommonDao();
    await dao.getNatureOfBusinessList().then((response) => {
      if (response[ApiKey._API_SUCCESS_KEY]) {
        var data = response[ApiKey._API_DATA_KEY];
        var selectOptions = [];

        data.map((item) => {
          selectOptions.push({ label: item.nob_name, value: item.id });
        });

        setNatureOfBusinessesState(selectOptions);
      }
    });
  };

  const getBlacklist = async () => {
    let dao = new CommonDao();
    await dao.getBlacklist().then((response) => {
      if (response[ApiKey._API_SUCCESS_KEY]) {
        var data = response[ApiKey._API_DATA_KEY];
        var selectOptions = [];

        data.map((item) => {
          selectOptions.push({ label: item.name, value: item.id });
        });

        setBlacklistState(selectOptions);
      }
    });
  };

  const deleteCustomer = async (row) => {
    var id = row.id;
    let dao = new CustomerDao();

    await dao.delete(id, token).then((responseJson) => {
      if (responseJson[ApiKey._API_SUCCESS_KEY]) {
        Notiflix.Report.success("Success", "Customer Deleted");
        getCustomerList();
      } else {
        Notiflix.Report.failure(
          "Error",
          "Failed to delete customer. " + responseJson[ApiKey._API_MESSAGE_KEY]
        );
      }
    });
  };

  const _COLUMN = useMemo(() => [
    //  {
    //    Header : "Profile",
    //    width: 100,
    //    Cell : ({row}) => (
    //      <>
    //        <div>
    //                  <img style={{height: "50%", width : "15%"}}
    //                    src={
    //                      row.original?.photo
    //                        ? HostKey.getApiHost() + row.original?.photo
    //                        : NoImagePlaceholder
    //                    }
    //                  />
    //                </div>
    //      </>
    //    )
    //  },
    {
      Header: "Name",
      width: 200,
      Cell: ({ row }) => (
        <>
          <div  onMouseOver={() => setOpen(true)} onMouseOut={() => setOpen(false)} style={{color: row.original.is_active ? 'black' : 'grey'}}>   
             {row.original.honorific} {row.original.fullName} (<small>{row.original.shortName}</small>)
             {/* {open && <img
                       src={ 
                         row.original?.photo
                           ? HostKey.getApiHost() + row.original?.photo
                           : NoImagePlaceholder
                       }
                     /> } */}
          </div>
        </>
      ),     
    },
    {
      Header: "Company Name",
      Cell: ({ row }) => (
        <>
          <div style={{color: row.original.is_active ? 'black' : 'grey'}}>   
             {row.original.companyName}
          </div>
        </>
      ),     
    },
    {
      Header: "Phone",
      width: 150,
      Cell: ({ row }) => (
        <>
          <div style={{color: row.original.is_active ? 'black' : 'grey'}}>   
             {row.original.mobileNumber}
          </div>
        </>
      ),     
    },  
    {
      Header: "Email",
      width: 200,
      Cell: ({ row }) => (
        <>
          <div style={{color: row.original.is_active ? 'black' : 'grey'}}>   
             {row.original.email}
          </div>
        </>
      ),     
    },  
    {
      Header: " ",
      Cell: ({ row }) => (
        <>
         <div
            className="btn-table"
            title="Create Enquiry"
            onClick={() => tableClickEvent(row.original)}
          >
            <i className="fa fa-file"></i>
          </div>
          <div
            className="btn-table"
            title="View Customer"
            onClick={() => tableClickEvent(row.original)}
          >
            <i className="fa fa-eye"></i>
          </div>
          {row.original.deleted_date === null && [
            <div
              className="btn-table last-btn"
              title="Delete Customer"
              onClick={() => {
                Notiflix.Confirm.show(
                  "Confirmation",
                  "Do you sure to delete customer?",
                  "Yes",
                  "No",
                  function () {
                    deleteCustomer(row.original);
                  }
                );
              }}
            >
              <i className="fa fa-trash"></i>
            </div>,
          ]}
        </>
      ),
      disableSortBy: true,
      disableFilters: true,
      style: { overflow: "visible", textAlign: "right" },
      sticky: "right"
    },
  ]);

  // ///<summary>
  // ///Author: Nicholas Lai
  // ///</summary>
  const toggle = () => {
    setIsNewCust(true);
    setCustomer(null);
    setModal((prev) => !prev);
  };

  const toggleEnquiry = () => {
    setEnquiryModal(!enquiryModal);
  };

  const tableClickEvent = (data) => {
    setIsNewCust(false);
    setTags(data.tags);
    setCustomer(data);
    setModal((prev) => !prev);
  };

  /// <summary>
  /// Author: Christopher Chan
  /// </summary>
  useEffect(() => {
    if (customer) {
      toggleEnquiry();
    }
  }, [customer]);

  const conditionalRowStyles = [
    {
      when: row => row.is_active == false,
      style: {
        backgroundColor: 'green',
        color: 'white',
        '&:hover': {
          cursor: 'pointer',
        },
      },
    },
    // You can also pass a callback to style for additional customization
   
  ];


  return (
    <>
      <div className="float-xl-right">
        <button
          type="type"
          className="btn btn-sm btn-themed mb-2"
          onClick={() => {
            toggle();
          }}
        >
          <i className="fa fa-plus mr-1"></i>New Customer
        </button>
      </div>
      <h1 className="page-header">Customers</h1>
      <div id="customersTable">
        <ReactTable
          columns={_COLUMN}
          data={customerList && customerList}
          getRowProps={(row) => {
            return {
              onDoubleClick: () => tableClickEvent(row.original),
            };
          }}
          filterable
          canPreviousPage={true}
          canNextPage={true}
          ref={_tableRef}
          setQueryParams={setListQueryParams}
          disabledApiPagination={false}
          conditionalRowStyles={conditionalRowStyles}
        />
      </div>
      {modal && (
        <CustomerForm
          setAppState={setAppState}
          toggle={toggle}
          isOpen={modal}
          getCustomerList={getCustomerList}
          customer={customer && customer}
          defaultTags={tags}
          newCust={isNewCust}
          onClosed={() => {
            setCustomer(null);
          }}
          successCallback={() => {
            getCustomerList();
            toggle();
          }}
        />
      )}
    </>
  );
};

export default Customer;
