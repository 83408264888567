import React, { useState, useEffect } from "react";
import {  Row, Col } from 'reactstrap';
import { InputTypes, SelectField, ApiKey } from "util/Constant";
import InputHoc from "components/form/InputHoc.js";
import { useForm } from "react-hook-form";
import Notiflix from "notiflix";
import CustomerDao from "data/CustomerDao.js";
import { Config, ConfigEnum } from 'util/Config';
import {  Report } from "notiflix";
import { useHistory } from 'react-router-dom';

const PersonalInfo = ({ selectedCustomer, isDisabled}) => {
        const { register, handleSubmit, control, errors, watch, reset } = useForm({});
        const history = useHistory();
        const [showCompanyFields, setShowCompanyFields] = useState(false);
        const watchIsCompany = watch('isCompany');
        let configInstance = Config.getInstance();
        var token = configInstance.getValue(ConfigEnum._TOKEN);
        useEffect(() => {
            if (watchIsCompany) {
                setShowCompanyFields(true);
            } else {
                setShowCompanyFields(false);
            }
        }, [watchIsCompany])
    
        /// <summary>
        /// Author: Christopher Chan
        /// </summary>
        useEffect(() => {
            if (selectedCustomer) {
                if (selectedCustomer?.companyName) {
                    setShowCompanyFields(true);
                }
    
                reset({
                    ...selectedCustomer,
                    isCompany: selectedCustomer?.companyName !== null
                });
            }
        }, [selectedCustomer])
    
        const onSubmit = (data) => {
                data["id"] = selectedCustomer.id;
                Notiflix.Confirm.Init({ plainText: false });
                Notiflix.Confirm.Show(
                    'Confirm update?',
                    `Please confirm you would like to submit the update`,
                    'Confirm',
                    'No',
                    async () => {
                        let dao = new CustomerDao();
                        await dao.updatePersonalInfo(data, token).then(responseJson => {
                                if (responseJson[ApiKey._API_SUCCESS_KEY]) {
                                    Report.Success('Success', 'Successfully update customer.', 'Okay', () => history.goBack());
                                } else {
                                    Report.Failure('Error', 'Operation Failed. ' + responseJson[ApiKey._API_MESSAGE_KEY]);
                                }
                            })
                    }
                )
        }
    
        return (
            <form onSubmit={handleSubmit(onSubmit)}>
                <strong>Personal Details</strong>
                <Row>
                    <Col xl="6"><InputHoc label='Honorific' disabled={isDisabled} name='honorific' inputType={InputTypes.INPUT} ref={register({ required: 'Honorific is required.' })} error={errors?.honorific?.message} /></Col>
                    <Col xl="6"><InputHoc label='Short Name' disabled={isDisabled} name='shortName' inputType={InputTypes.INPUT} ref={register({ required: 'Short Name is required.' })} error={errors?.shortName?.message} /></Col>
                </Row>
                <Row>
                    <Col xl="12"><InputHoc label='Full Name' disabled={isDisabled} name='fullName' inputType={InputTypes.INPUT}  error={errors?.fullName?.message} /></Col>
                </Row>
                <Row>
                    <Col xl="6"><InputHoc label='IC Number'  disabled={isDisabled}name='icNumber' inputType={InputTypes.INPUT} error={errors?.icNumber?.message} /></Col>
                    <Col xl="6"><InputHoc label='Gender' disabled={isDisabled} isMulti={false} name='gender' inputType={InputTypes.SELECT} control={control} options={SelectField._GENDER_OPTIONS} rules={{ required: true }} error={errors?.gender} /></Col>
                </Row>
                <Row>
                    <Col xl="12">
                        <InputHoc
                            disabled={isDisabled}
                            label="Enter Company Details?"
                            inputType={InputTypes.CHECKBOX}
                            name="isCompany"
                            options={[
                                { label: 'Yes', value: true }
                            ]}
                            ref={register}
                            formGroup={false}
                            inline
                            labelClasses="mr-2"
                        />
                    </Col>
                </Row>
                {showCompanyFields &&
                    <>
                        <strong className="mt-2">Company Details</strong>
                        <Row>
                            <Col xl="6"><InputHoc disabled={isDisabled} label='Company Name' name='companyName' inputType={InputTypes.INPUT} ref={register({ required: 'Company Name is required.' })} error={errors?.companyName?.message} /></Col>
                            <Col xl="6"><InputHoc disabled={isDisabled} label='SSM Number' name='ssmNumber' inputType={InputTypes.INPUT} ref={register({ required: 'SSM Number is required.' })} error={errors?.ssmNumber?.message} /></Col>
                        </Row>
                        <Row>
                            <Col xl="6"><InputHoc disabled={isDisabled} label='Department' name='department' inputType={InputTypes.INPUT} ref={register({ required: 'Department is required.' })} error={errors?.department?.message} /></Col>
                            <Col xl="6"><InputHoc disabled={isDisabled} label='Job Title' name='jobTitle' inputType={InputTypes.INPUT} ref={register({ required: 'Job Title is required.' })} error={errors?.jobTitle?.message} /></Col>
                        </Row>
                    </>
                }
                <hr style={{ marginBottom: 5 }} />
                {!isDisabled && <button type="submit" className="btn btn-themed btn-min-width pull-right mt-2"> Submit Changes</button> }
            </form>
        )
    };

    export default PersonalInfo;