import React, { useState, useEffect } from 'react';
import ReactSelect from 'react-select';
import CreatableSelect from 'react-select/creatable';
import { Controller } from 'react-hook-form';
import classnames from 'classnames';
import { Colors } from 'util/Constant';

/// <summary>
/// Author: Christopher Chan
/// </summary>
const Select = (props) => {
    const {
        error,
        options,
        isMulti,
        selectClasses,
        creatable,
        readOnly,
        onChange: propsOnChange,
        disabled,
        ...rest
    } = props;
    const [defaultValue, setDefaultValue] = useState(null);

    const _selectStyles = {
        container: (provided) => ({
            ...provided,
            marginBottom: '5px',
            paddingRight: 0,
            height: isMulti && 'auto !important'
        }),
        control: (provided, state) => ({
            ...provided,
            borderTop: 0,
            borderRight: 0,
            borderLeft: 0,
            borderRadius: 0,
            height: isMulti ? 'auto' : '36px',
            minHeight: '39px',
            boxShadow: 0,
            borderWidth: (readOnly || disabled) ? 0 : 2,
            backgroundColor: '#FFF',
            borderColor: error ? Colors.RED : state.isFocused ? Colors.THEME : Colors.GRAY,
            marginTop: '-9px',
            '&:hover': {
                borderColor: error ? Colors.RED : state.isFocused ? Colors.THEME : Colors.GRAY,
            }
        }),
        option: (provided, { isDisabled, isFocused, isSelected }) => ({
            ...provided,
            backgroundColor: isDisabled
                ? null
                : isSelected
                    ? Colors.THEME
                    : isFocused
                        ? Colors.OFF_THEME
                        : null,
            ':active': {
                ...provided[':active'],
                backgroundColor:
                    !isDisabled && (isSelected ? Colors.THEME : Colors.OFF_THEME),
            },
            color: isSelected
                ? Colors.WHITE
                : isFocused
                ? Colors.WHITE
                : Colors.OFF_THEME,
        }),
        valueContainer: (provided) => ({
            ...provided,
            height: isMulti ? 'auto' : '36px',
            marginTop: isMulti && '10px',
            paddingLeft: 0
        }),
        indicatorsContainer: (provided) => ({
            ...provided,
            height: '36px',
            display: (readOnly || disabled) ? 'none' : 'flex'
        }),
        indicatorSeparator: (provided) => ({
            ...provided,
            display: 'none'
        }),
        placeholder: (provided, state) => ({
            ...provided,
            display: 'none'
        }),
        singleValue: (provided) => ({
            ...provided,
            paddingLeft: '2px',
            transform: 'translateY(-10%)',
        }),
        input: (provided) => ({
            ...provided,
            transform: 'translateY(-25%)',
            position: isMulti ? 'relative' : 'absolute',
            height: isMulti && '14px',
            top: '50%',
        }),
        menuPortal: (provided) => ({
            ...provided,
            zIndex: 9999
        }),
        multiValueRemove: (provided) => ({
            ...provided,
            display: (readOnly || disabled) ? 'none' : 'flex'
        })
    };

    const [toggleClass, setToggleClass] = useState();

    /// <summary>
    /// Author: Christopher Chan
    /// </summary>
    useEffect(() => {
        setToggleClass(props.defaultValue);
        setDefaultValue(props.defaultValue);
    }, [props.defaultValue]);

    return (
        <Controller
            {...rest}
            render={
                ({ field : {onChange, value}, ...otherProps }) => {
                    if (creatable) {
                        return (
                            <CreatableSelect
                                className={
                                    classnames({
                                        [selectClasses]: selectClasses,
                                        'react-select-selected': toggleClass || value,
                                        'form-control': true
                                    })
                                }
                                onFocus={
                                    () => { setToggleClass(true) }
                                }
                                onMenuClose={
                                    () => { setToggleClass(value != null) }
                                }
                                onChange={
                                    (e) => {
                                        setToggleClass(e.value !== null);
                                        onChange(e.value);
                                        setDefaultValue(null);
                                        propsOnChange && propsOnChange(e.value);
                                    }
                                }
                                value={(options.length > 0) && options?.filter(x => x.value == (defaultValue !== null ? defaultValue : value))}
                                styles={_selectStyles}
                                options={options}
                                menuPortalTarget={document.body}
                                isDisabled={readOnly || disabled}
                                {...otherProps}
                            />
                        );
                    }

                    return (
                        <ReactSelect
                            className={
                                classnames({
                                    [selectClasses]: selectClasses,
                                    'react-select-selected': toggleClass || value,
                                    'form-control': true
                                })
                            }
                            onFocus={
                                () => { setToggleClass(true) }
                            }
                            onMenuClose={
                                () => { setToggleClass(value != null) }
                            }
                            onChange={
                                (e) => {
                                    setToggleClass(isMulti ? e !== null : e.value !== null);
                                    onChange(isMulti ? e : e.value);
                                    setDefaultValue(null);
                                    propsOnChange && propsOnChange(isMulti ? e : e.value);
                                }
                            }
                            value={isMulti ? value : (options.length > 0) && options?.filter(x => x.value == (defaultValue !== null ? defaultValue : value))}
                            styles={_selectStyles}
                            options={options}
                            isMulti={isMulti}
                            menuPortalTarget={document.body}
                            isDisabled={readOnly || disabled}
                            {...otherProps}
                        />
                    );
                }
            }
        />
    );
};

Select.defaultProps = {
    defaultValue: null,
    menuPlacement: 'auto',
}

export default Select;