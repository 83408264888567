import React, {
  useState,
  forwardRef,
  useImperativeHandle,
  useCallback,
  useEffect,
  useRef,
} from "react";
import { useTranslation } from "react-i18next";
import {
  useTable,
  usePagination,
  useFlexLayout,
  useFilters,
  useSortBy,
  useResizeColumns,
} from "react-table";
import { isFunction } from "lodash";
import classNames from "classnames";
import ReactTooltip from "react-tooltip";
import { useHistory, useLocation } from "react-router";
import { useSticky } from "react-table-sticky";
import { FaCaretUp, FaCaretDown } from "react-icons/fa";

import { stringIsNullOrEmpty, useQuery } from "util/Utility";

import FilterBar from "./FilterBar";
import PageButtons from "./PageButtons";
import RowsSelection from "./RowsSelection";

/// <summary>
/// Author: KurisuCodes
/// </summary>
const TableTitle = ({ tableTitle }) => {
  if (typeof tableTitle === "string") {
    return <h3 className="text-theme m-b-0">{tableTitle}</h3>;
  }

  return tableTitle;
};

/// <summary>
/// Author: KurisuCodes
/// </summary>
const ThCell = ({
  column,
  t,
  isActionCol,
  isSortAscending,
  isSortDescending,
  ...restProps
}) => {
  const headerTxt = t(column.render("Header"));
  const isFirstCall = useRef(true);

  useEffect(() => {
    !isFirstCall.current && (isFirstCall.current = true);
  }, [t, column.isResizing]);

  return (
    <>
      <div
        {...restProps}
        data-for="react-table-tooltip"
        onMouseEnter={(e) => {
          if (isFirstCall.current) {
            let el = e.target;
            if (el.scrollWidth > el.clientWidth) {
              el.setAttribute("data-tip", headerTxt);
              ReactTooltip.rebuild();
            } else {
              el.setAttribute("data-tip", "");
            }
            isFirstCall.current = false;
          }
        }}
      >
        {headerTxt}

        {!isActionCol && (
          <span style={{ marginLeft: 10 }}>
            {!isSortDescending && <FaCaretUp size={22} />}
            {!isSortAscending && <FaCaretDown size={22} />}
          </span>
        )}
      </div>
    </>
  );
};

/// <summary>
/// Author: KurisuCodes
/// </summary>
const TdCell = (props) => {
  const { cell, ...restProps } = props;

  const isFirstCall = useRef(true);
  const tipText = useRef(cell.value);

  useEffect(() => {
    !isFirstCall.current && (isFirstCall.current = true);
  }, [cell.column.isResizing]);

  const getTdCellProps = (cell) => {
    if (cell?.column?.Cell.name !== "Cell") {
      return {
        onMouseEnter: (e) => {
          if (tipText.current !== cell.value) {
            isFirstCall.current = true;
            tipText.current = cell.value;
          }
          if (isFirstCall.current) {
            let el = e.target;
            if (el.scrollWidth > el.clientWidth) {
              el.setAttribute("data-for", "react-table-tooltip");
              el.setAttribute("data-tip", tipText.current);
              ReactTooltip.rebuild();
            } else {
              el.setAttribute("data-tip", "");
            }
            isFirstCall.current = false;
          }
        },
      };
    }
    return;
  };

  return (
    <div {...restProps} {...getTdCellProps(cell)}>
      {cell.render("Cell")}
    </div>
  );
};

/// <summary>
/// Author: KurisuCodes
/// </summary>
const ReactTable = forwardRef((props, ref) => {
  const {
    tableTitle,
    columns: propsColumns,
    data,
    pageOptions: propsPageOptions,
    getRowProps = () => ({}),
    setQueryParams,
    subHeader,
    showFilter = true,
    doubleClickHandler = () => {},
    disableUrlQuery,
    disabledApiPagination,
    disabledApiFilter, // if disable api filter please disable api pagination too, otherwise it will filter one page only
    disabledApiSort, // if disable api sort please disable api pagination too, otherwise it will sort one page only
    defaultOpenFilter,    
  } = props;

  const { t } = useTranslation();
  const _history = useHistory();
  const _location = useLocation();
  const queryParams = useQuery(_location);
  const [pageOptions, setPageOptions] = useState(propsPageOptions);
  const [searchParams, setSearchParams] = useState({});
  const topBarRef = useRef();
  const [sortByState, setSortByState] = useState("");

  // const defaultColumn = React.useMemo(
  //     () => ({
  //         // When using the useFlexLayout:
  //         minWidth: 30, // minWidth is only used as a limit for resizing
  //         width: 150, // width is used for both the flex-basis and flex-grow
  //         maxWidth: 200 // maxWidth is only used as a limit for resizing
  //     }),
  //     []
  // );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    footerGroups,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    columns,
    setHiddenColumns,
    state: { pageIndex, pageSize },
    setAllFilters,
    rows,
    setSortBy,
  } = useTable(
    {
      columns: propsColumns,
      data,
      initialState: { pageSize: pageOptions.pageSize },
      autoResetPage: false,
      // defaultColumn
    },
    useFilters,
    useSortBy,
    usePagination,
    useFlexLayout,
    useResizeColumns,
    useSticky
  );

  /// <summary>
  /// Author: KurisuCodes
  /// </summary>
  const handleSearch = useCallback(
    (data) => {
      setPageOptions((prev) => ({ ...prev, page: 1 }));
      gotoPage(0);
      setSearchParams(data);
    },
    [gotoPage]
  );

  /// <summary>
  /// Author: KurisuCodes
  /// </summary>
  useEffect(() => {
    if (isFunction(setQueryParams)) {
      let tempObj = {};
      /// <summary>
      /// Author: KurisuCodes
      /// </summary>
      const groupParamsByKey = (params) =>
        [...params.entries()].reduce((acc, tuple) => {
          const [key, val] = tuple;

          if (acc.hasOwnProperty(key)) {
            if (Array.isArray(acc[key])) {
              acc[key] = [...acc[key], val];
            } else {
              acc[key] = [acc[key], val];
            }
          } else {
            acc[key] = val;
          }

          return acc;
        }, {});
      const params = new URLSearchParams(_location.search.substring(1));

      if (!disabledApiPagination) {
        tempObj.pageIndex = pageOptions.page;
        tempObj.pageSize = pageOptions.pageSize;
      }

      if (!disabledApiFilter) {
        Object.assign(tempObj, searchParams);
      } else {
        let tempFilters = Object.entries(searchParams).map(([key, val]) => ({
          id: key,
          value: val,
        }));
        setAllFilters(tempFilters);
      }

      if (!disabledApiSort) {
        Object.assign(tempObj, groupParamsByKey(params));
      } else {
        let tempSortBy = Object.values(groupParamsByKey(params)).map((val) => {
          let key = val.slice(0, -2);
          let desc = val.slice(-2) === ":0" ? false : true;
          return { id: key, desc };
        });
        setSortBy(tempSortBy);
      }

      setQueryParams((prev) => {
        console.log(tempObj);
        if (JSON.stringify(prev) === JSON.stringify(tempObj)) {
          return prev;
        } else {
          return tempObj;
        }
      });
    }
  }, [
    pageOptions.page,
    pageOptions.pageSize,
    setQueryParams,
    searchParams,
    _location.search,
    sortByState,
    disableUrlQuery,
    disabledApiPagination,
    disabledApiFilter,
    disabledApiSort,
    setAllFilters,
    setSortBy,
  ]);

  /// <summary>
  /// Author: KurisuCodes
  /// </summary>
  useImperativeHandle(
    ref,
    () => ({
      setPageOptions: (newPageOptions) => {
        setPageOptions((prev) => ({ ...prev, ...newPageOptions }));
      },
    }),
    []
  );

  return (
    <>
      <div className="table-topbar" ref={topBarRef}>
        {tableTitle && (
          <div className="d-flex">
            <TableTitle tableTitle={tableTitle} />
          </div>
        )}
        {showFilter && <FilterBar handleSearch={handleSearch} />}
        {subHeader && <div className="sub-header">{subHeader}</div>}
      </div>
      <div className="table-wrapper">
        <div
          {...getTableProps({ style: { minWidth: "100%" } })}
          className="table table-td-valign-middle dataTable -sticky"
        >
          <div className="thead">
            {headerGroups.map((headerGroup) => (
              <div
                {...headerGroup.getHeaderGroupProps(
                  ({ style, ...restHeaderGroupProps }) => ({
                    style: { ...style, minWidth: "fit-content" },
                    ...restHeaderGroupProps,
                  })
                )}
                className="tr"
              >
                {/* <div className="th text-right" style={{ boxSizing: 'border-box', minWidth: 40, width: 40 }}>
                                      No.
                                  </div> */}
                {headerGroup.headers.map((column, index) => {
                  let isActionCol = index === headerGroup.headers.length - 1;
                  let sortBy = queryParams.get("sortBy") ?? "";
                  let isSortAscending = sortBy.includes(`${column.id}:0`);
                  let isSortDescending = sortBy.includes(`${column.id}:1`);
                  let { key, ...restHeaderProps } = column.getHeaderProps({
                    style: {
                      ...column.headerStyle,
                      cursor: isActionCol ? "unset" : "pointer",
                    },
                    className: classNames(column.headerClassName, "th"),
                  });

                  /// <summary>
                  /// Author: KurisuCodes
                  /// </summary>
                  const onClick = () => {
                    if (!isActionCol) {
                      let tempQuery = _location.search;

                      if (!stringIsNullOrEmpty(tempQuery)) {
                        let tempSortBy = sortBy.includes(":0") ? ":1" : ":0";

                        if (!stringIsNullOrEmpty(sortBy)) {
                          tempQuery = tempQuery.replace(
                            `sortBy=${sortBy}`,
                            `sortBy=${column.id}${tempSortBy}`
                          );
                        }
                      } else {
                        tempQuery = `?sortBy=${column.id}:0`;
                      }

                      _history.replace({
                        pathname: _location.pathname,
                        search: tempQuery,
                      });
                    }
                  };

                  return (
                    <ThCell
                      {...{
                        column,
                        t,
                        key,
                        ...restHeaderProps,
                        onClick,
                        isActionCol,
                        isSortAscending,
                        isSortDescending,
                      }}
                    />
                  );
                })}
              </div>
            ))}
          </div>
          <div {...getTableBodyProps()} className="tbody">
            {page.length > 0 ? (
              page.map((row, index) => {
                prepareRow(row);

                let pageOffset =
                  pageOptions.page !== 1
                    ? pageOptions.pageSize * (pageOptions.page - 1)
                    : 0;
                let { style, ...rowProps } = row.getRowProps(getRowProps(row));

                return (
                  <div
                    id={row.original.id}
                    onDoubleClick={() => doubleClickHandler(row.original)}
                    {...rowProps}
                    style={{ ...style, minWidth: "fit-content" }}
                    className="tr"
                  >
                    {/* <div className="td text-right" style={{ boxSizing: 'border-box', minWidth: 40, width: 40 }}>{++index + pageOffset}.</div> */}
                    {row.cells.map((cell, cellIndex) => {
                      let { key, ...restCellProps } = cell.getCellProps({
                        style: cell.column.style,
                        className: classNames(cell.column.className, "td"),
                      });
                      return <TdCell {...{ cell, key, ...restCellProps }} />;
                    })}
                  </div>
                );
              })
            ) : (
              <div className="tr">
                <div
                  colSpan={propsColumns.length + 1}
                  className="text-center td"
                  style={{ padding: "8px" }}
                >
                  No Data Available
                </div>
              </div>
            )}
          </div>
          <div className="tfoot">
            {footerGroups.map((group) => (
              <div {...group.getFooterGroupProps()} className="tr">
                {group.headers.map(
                  (column) =>
                    column.Footer.name !== "emptyRenderer" && (
                      <div {...column.getFooterProps()} className="td">
                        {column.render("Footer")}
                      </div>
                    )
                )}
              </div>
            ))}
          </div>
        </div>
      </div>
      <div className="table-bottombar">
        <RowsSelection
          pageSize={pageSize}
          setPageSize={setPageSize}
          pageOptions={pageOptions}
          setPageOptions={setPageOptions}
        />
        <PageButtons
          pageOptions={pageOptions}
          setPageOptions={setPageOptions}
          canNextPage={canNextPage}
          canPreviousPage={canPreviousPage}
          pageCount={pageCount}
          pageIndex={pageIndex}
          previousPage={previousPage}
          nextPage={nextPage}
          gotoPage={gotoPage}
        />
      </div>
      <ReactTooltip id={`react-table-tooltip`} type="dark" />
    </>
  );
});

ReactTable.defaultProps = {
  data: [],
  pageOptions: {
    page: 1,
    pageSize: 10,
    totalCount: 0,
  },
  getRowProps: () => ({}),
};

export default ReactTable;
