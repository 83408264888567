import { ApiUrl } from 'util/Constant';
import DataAccessObject from './DataAccessObject';

/// <summary>
/// Author: Christopher Chan
/// </summary>
class QuotationDao extends DataAccessObject {

    async getQuotationList(token, withEmptyContracts = false) {
        let url = ApiUrl._API_QUOTATION_LIST;

        if (withEmptyContracts) {
            url += '?withEmptyContracts=true';
        }

        return this.get(url, token);
    }

    async createQuotation(data, token) {
        return this.post(ApiUrl._API_CREATE_QUOTATION, data, token);
    }

    async updateQuotation(data, token) {
        return this.post(ApiUrl._API_UPDATE_QUOTATION, data, token);
    }

    async delete(id, token) {
        return this.post(`${ApiUrl._API_DELETE_QUOTATION}/${id}`, null, token);
    }

    // async createProjectContract(data, token) {
    //     return this.post(ApiUrl._API_CREATE_PROJECT_CONTRACT, data, token);
    // }

    async updateQuotationStatus(data, token) {
        return this.post(ApiUrl._API_UPDATE_QUOTATION_STATUS, data, token);
    }
}

export default QuotationDao;
