import React, { useMemo, useState, useRef, useEffect, forwardRef } from "react";
import ReactTable from 'components/react-table/ReactTable';
import { Card, CardHeader, CardBody, Row, Col, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { InputTypes, SelectField, WebUrl, ApiKey } from "util/Constant";
import InputHoc from "components/form/InputHoc.js";
import { useForm, useFieldArray } from "react-hook-form";
import Notiflix from "notiflix";
import CustomerDao from "data/CustomerDao.js";
import { Config, ConfigEnum } from 'util/Config';
import { Confirm, Report } from "notiflix";
import { report } from "util/Notiflix";
import { useHistory, useLocation,useParams } from 'react-router-dom';

const LocationInfo = ({ jumpToStep, setCustomerFormState, selectedCustomer, isDisabled }) => {
        const { register, handleSubmit, control, errors, reset } = useForm();
        let configInstance = Config.getInstance();
        var token = configInstance.getValue(ConfigEnum._TOKEN);
        const history = useHistory();

        const { fields, append, remove } = useFieldArray({
            control,
            name: 'addresses'
        });
    
        const onSubmit = (data) => {
             data["id"] = selectedCustomer.id;
             Notiflix.Confirm.Init({ plainText: false });
             Notiflix.Confirm.Show(
                  'Confirm update?',
                  `Please confirm you would like to submit the update`,
                  'Confirm',
                   'No',
                    async () => {
                        let dao = new CustomerDao();
                        await dao.updateLocationInfo(data, token).then(responseJson => {
                                if (responseJson[ApiKey._API_SUCCESS_KEY]) {
                                    Report.Success('Success', 'Successfully update customer.', 'Okay', () => history.goBack());
                                } else {
                                    Report.Failure('Error', 'Operation Failed. ' + responseJson[ApiKey._API_MESSAGE_KEY]);
                                }
                            })
                    }
                )
        }
    
        /// <summary>
        /// Author: Christopher Chan
        /// </summary>
        useEffect(() => {
            return reset;
        }, []);
    
        /// <summary>
        /// Author: Christopher Chan
        /// </summary>
        useEffect(() => {
            if (selectedCustomer) {
                append(selectedCustomer.addresses);
            }
            else {
                append();
            }
        }, [selectedCustomer])
    
        return (
            <form onSubmit={handleSubmit(onSubmit)}>
                {!isDisabled && <button type="type" className="btn btn-sm btn-themed mb-2" onClick={() => append()}><i className="fa fa-plus mr-1"></i>Add Address</button>}
                {
                    fields.map((item, index) => {
                        return (
                            <div className="mt-2 mb-2">
                                <strong>Address {index + 1}</strong>
                                <Row>
                                    <Col><InputHoc   disabled={isDisabled} defaultValue={item?.addressLine1} name={`addresses[${index}].addressLine1`} label="Address Line 1" ref={register({ required: 'Address Line 1 is required.' })} /></Col>
                                    <Col><InputHoc   disabled={isDisabled} defaultValue={item?.addressLine2} name={`addresses[${index}].addressLine2`} label="Address Line 2" ref={register({ required: false })} /></Col>
                                </Row>
                                <Row>
                                    <Col><InputHoc   disabled={isDisabled} defaultValue={item?.city} name={`addresses[${index}].city`} label="City" ref={register({ required: 'City is required.' })} /></Col>
                                    <Col><InputHoc   disabled={isDisabled} defaultValue={item?.postcode} name={`addresses[${index}].postcode`} label="Postcode" ref={register({ required: 'Postcode is required.' })} /></Col>
                                </Row>
                                <Row>
                                    <Col><InputHoc   disabled={isDisabled} defaultValue={item?.state} name={`addresses[${index}].state`} label="State" inputType={InputTypes.SELECT} options={SelectField._STATE_OPTIONS} control={control} rules={{ required: 'State is required.' }} /></Col>
                                    <Col><InputHoc   disabled={isDisabled} defaultValue={item?.country} name={`addresses[${index}].country`} label="Country" inputType={InputTypes.SELECT} options={SelectField._COUNTRY_OPTIONS} control={control} rules={{ required: 'Country is required.' }} /></Col>
                                </Row>
                                <Row>
                                    <Col><InputHoc   disabled={isDisabled} defaultValue={item?.category} name={`addresses[${index}].category`} label="Type" inputType={InputTypes.SELECT} options={SelectField._ADDRESS_TYPES} control={control} rules={{ required: 'Type is required.' }} /></Col>
                                    <Col className="pull-right">{index !== 0 && <div className="btn-round expand-red m-t-5 pull-right" onClick={() => remove(index)}><i className="fas fa-trash-alt"></i></div>}</Col>
                                </Row>
                            </div>
                        );
                    })
                }
                <hr style={{ marginBottom: 5 }} />
                {!isDisabled && <button type="submit" className="btn btn-themed btn-min-width pull-right mt-2">Submit Changes</button> }
            </form>
        );
    };
    
    export default LocationInfo;