import React from "react";
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { Loading, Report } from "notiflix";
import { useForm } from "react-hook-form";

import ContractDao from "data/ContractDao.js";

import { Config, ConfigEnum } from 'util/Config';
import { InputTypes, ApiKey, Colors } from "util/Constant";
import InputHoc from "components/form/InputHoc.js";

/// <summary>
/// Author: Christopher Chan
/// </summary>
export default ({ isOpen, toggle, contract }) => {
    const { register, handleSubmit, control } = useForm();

    let configInstance = Config.getInstance();
    var token = configInstance.getValue(ConfigEnum._TOKEN);

    /// <summary>
    /// Author: Christopher Chan
    /// </summary>
    const onSubmit = async (data) => {
        Loading.Init({ backgroundColor: "rgba(0, 0, 0, 0.5)", svgSize: "50px", svgColor: Colors.THEME });
        Loading.Circle();

        const formData = new FormData();

        formData.append("id", contract.id);
        formData.append("status", 1);
        formData.append("signedDocument", data.signedDocument);

        let dao = new ContractDao();
        await dao.updateContractStatus(formData, token).then(responseJson => {
            if (responseJson[ApiKey._API_SUCCESS_KEY]) {
                Report.Success(
                    'Success',
                    'Signed Document Uploaded',
                    'Okay',
                    () => toggle(true)
                );
            } else {
                Report.Failure('Error', 'Operation Failed. ' + responseJson[ApiKey._API_MESSAGE_KEY]);
            }
        });

        Loading.Remove();
    }

    return (
        <Modal isOpen={isOpen} toggle={() => toggle(false)} centered>
            <ModalHeader toggle={() => toggle(false)}>Upload Signed Document</ModalHeader>
            <form onSubmit={handleSubmit(onSubmit)} enctype="multipart/form-data">
                <ModalBody>
                    <div className="mt-2 mb-2">
                        <InputHoc
                            name='signedDocument'
                            label="Signed Document"
                            inputType={InputTypes.FILEUPLOAD}
                            control={control}
                            multiple={false}
                            ref={register({ required: true })}
                        />
                    </div>
                </ModalBody>
                <ModalFooter>
                    <button type="submit" className="btn btn-themed btn-min-width">Upload</button>
                </ModalFooter>
            </form>
        </Modal>
    );
};
