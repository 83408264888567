import React, { useEffect, useContext } from "react";
import { useHistory } from "react-router";
import { Notify } from "notiflix";
import { useForm } from "react-hook-form";
import { FaUserAlt, FaLock, FaPowerOff } from "react-icons/fa";

import { PageSettings } from "../../config/page-settings.js";
import { WebUrl } from "util/Constant.js";
import useAuthController from "recoil/useAuthController.js";
import CustomInput from "components/form/CustomInput";

Notify.init({
  position: "center-top",
});

/// <summary>
/// Author: KurisuCodes
/// </summary>
export default () => {
  const _history = useHistory();
  const _context = useContext(PageSettings);
  const { register, handleSubmit, control } = useForm();
  const { login } = useAuthController({
    loginCallback: {
      success: () => {
        _history.push(WebUrl._DASHBOARD);
      },
      fail: (error) => {
        Notify.failure(error);
      },
    },
  });

  /// <summary>
  /// Author: KurisuCodes
  /// </summary>
  useEffect(() => {
    _context.setOptions("pageHeader", false);
    _context.setOptions("pageSidebar", false);
    _context.setOptions("pageContentFullWidth", true);

    return () => {
      _context.setOptions("pageHeader", true);
      _context.setOptions("pageSidebar", true);
      _context.setOptions("pageContentFullWidth", false);
    };
  }, []);

  /// <summary>
  /// Author: KurisuCodes
  /// </summary>
  const onSubmit = (data) => {
    let credentials = {
      username: data["username"],
      password: data["password"],
      portal: "NEUP",
    };
    login(credentials);
  };

  return (
    <div className="login-container">
      <div className="content-container">
        <div className="title-row">
          <h1 className="title black">NE</h1>
          <h1 className="title white" style={{ color: "#ff0066" }}>
            Cs
          </h1>
        </div>

        <div>
          <form onSubmit={handleSubmit(onSubmit)}>
            <CustomInput
              control={control}
              {...register("username")}
              name="username"
              label="Username"
              icon={<FaUserAlt style={{ color: "#ff0066" }} />}
            />
            <CustomInput
              control={control}
              {...register("password")}
              name="password"
              type="password"
              label="Password"
              icon={<FaLock style={{ color: "#ff0066" }} />}
            />
            <div className="login-btn-div m-t-20">
              <div className="d-flex justify-content-center w-100">
                <button type="submit" className="btn btn-lg btn-themed w-100">
                  <FaPowerOff style={{ marginRight: 5 }} /> Login
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};
