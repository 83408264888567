import React, { useState, useEffect, forwardRef } from 'react';
import classnames from 'classnames';
import { Controller } from 'react-hook-form';
import ReactDatepicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import Input from './Input';

/// <summary>
/// Author: Christopher Chan
/// </summary>
const Datepicker = (props) => {

    const [toggleClass, setToggleClass] = useState(props.defaultValue ?? false);

    return (
        <Controller
            {...props}
            render={({ value, ...otherProps }) => (
                <ReactDatepicker
                    selected={value}
                    customInput={<Input />}
                    showMonthDropdown
                    showYearDropdown
                    dropdownMode={'select'}
                    disabled={props?.disabled}
                    onFocus={() => { setToggleClass(true) }}
                    wrapperClassName={classnames({ 'datepicker-selected': toggleClass })}
                    {...otherProps}
                    onChange={(e) => { otherProps.onChange(e); setToggleClass(Boolean(e)) }}
                    onBlur={({ target: { value } }) => { otherProps.onBlur(value); setToggleClass(Boolean(value)) }}
                />
            )}
        />
    );
};

Datepicker.defaultProps = {
    defaultValue: null,
}

export default Datepicker;