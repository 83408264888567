import { ApiUrl, OTPKey } from 'util/Constant';
import DataAccessObject from './DataAccessObject';

/**
 * Author : Sim
 * DataAccessObject's fetch will return the promise.
 */
class AuthenticationDao extends DataAccessObject {

    async login(data) {
        return this.post(ApiUrl._API_LOGIN, data);
    }

    async validateLogin(token) {
        return this.get(ApiUrl._API_VALIDATE_LOGIN, token);
    }

    async logout(token) {
        return this.post(ApiUrl._API_LOGOUT, null, token);
    }
}

export default AuthenticationDao;
