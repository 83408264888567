import React, { useMemo, useState, useRef, useEffect, forwardRef } from "react";
import ReactTable from 'components/react-table/ReactTable';
import { Card, CardHeader, CardBody, Row, Col, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { InputTypes, SelectField, WebUrl, ApiKey } from "util/Constant";
import InputHoc from "components/form/InputHoc.js";
import { useForm, useFieldArray } from "react-hook-form";
import Notiflix from "notiflix";
import CustomerDao from "data/CustomerDao.js";
import { Config, ConfigEnum } from 'util/Config';
import { Confirm, Report } from "notiflix";
import { report } from "util/Notiflix";
import { useHistory, useLocation,useParams } from 'react-router-dom';

const ContactInfo = ({ jumpToStep, setCustomerFormState, selectedCustomer, isDisabled }) => {
        const { register, handleSubmit, control, errors, reset } = useForm();
        let configInstance = Config.getInstance();
        var token = configInstance.getValue(ConfigEnum._TOKEN);
        const history = useHistory();

        const {
            fields: phoneFields,
            append: phoneAppend,
            remove: phoneRemove
        } = useFieldArray({ control, name: "phones" });
        const {
            fields: emailFields,
            append: emailAppend,
            remove: emailRemove
        } = useFieldArray({ control, name: "emails" });
    
        const onSubmit = (data) => {
                data["id"] = selectedCustomer.id;
                Notiflix.Confirm.Init({ plainText: false });
                Notiflix.Confirm.Show(
                    'Confirm update?',
                    `Please confirm you would like to submit the update`,
                    'Confirm',
                    'No',
                    async () => {
                        let dao = new CustomerDao();
                        await dao.updateContactInfo(data, token).then(responseJson => {
                                if (responseJson[ApiKey._API_SUCCESS_KEY]) {
                                    Report.Success('Success', 'Successfully update customer.', 'Okay', () => history.goBack());
                                } else {
                                    Report.Failure('Error', 'Operation Failed. ' + responseJson[ApiKey._API_MESSAGE_KEY]);
                                }
                            })
                    }
                )
        }
    
        /// <summary>
        /// Author: Christopher Chan
        /// </summary>
        useEffect(() => {
            return reset;
        }, []);
    
        /// <summary>
        /// Author: Christopher Chan
        /// </summary>
        useEffect(() => {
            if (selectedCustomer) {
                phoneAppend(selectedCustomer.phones);
                emailAppend(selectedCustomer.emails);
            }
            else {
                phoneAppend();
                emailAppend();
            }
        }, [selectedCustomer])
    
        return (
            <form onSubmit={handleSubmit(onSubmit)}>
                <strong className="mt-1">Phone</strong>
                {
                    phoneFields.map((item, index) => {
                        return (
                            <Row key={item.id}>
                                <Col xs={6}>
                                    <InputHoc
                                        disabled={isDisabled}
                                        name={`phones[${index}].number`}
                                        label="Phone Number"
                                        ref={register({ required: 'Phone Number is required.' })}
                                        defaultValue={item?.number}
                                    />
                                </Col>
                                <Col xs={4}>
                                    <InputHoc
                                        disabled={isDisabled}
                                        name={`phones[${index}].type`}
                                        label="Type"
                                        inputType={InputTypes.SELECT}
                                        options={SelectField._PHONE_TYPES}
                                        control={control}
                                        rules={{ required: 'Phone Type is required.' }}
                                        defaultValue={item?.type}
                                    />
                                </Col>
                                <Col xs={2} className="flex-center">
                                    {!isDisabled && index == 0 ? <div className="btn-round expand-theme m-t-5" onClick={() => phoneAppend()}><i className="fas fa-plus"></i></div> :
                                      !isDisabled &&   <div className="btn-round expand-red m-t-5" onClick={() => phoneRemove(index)}><i className="fas fa-trash-alt"></i></div>
                                    }
                                </Col>
                            </Row>
                        );
                    })
                }
                <strong className="mt-5">Email</strong>
                {
                    emailFields.map((item, index) => {
                        return (
                            <Row key={item.id}>
                                <Col xs={6}>
                                    <InputHoc
                                        disabled={isDisabled}
                                        name={`emails[${index}].email`}
                                        label="Email"
                                        ref={register({ required: 'Email is required.', pattern: { value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i, message: "invalid email address" } })}
                                        defaultValue={item?.email}
                                    />
                                </Col>
                                <Col xs={4}>
                                    <InputHoc
                                        disabled={isDisabled}
                                        name={`emails[${index}].type`}
                                        label="Type"
                                        inputType={InputTypes.SELECT}
                                        options={SelectField._EMAIL_TYPES}
                                        control={control}
                                        rules={{ required: 'Email Type is required.' }}
                                        defaultValue={item?.type}
                                    />
                                </Col>
                                <Col xl={2} className="flex-center">
                                    {!isDisabled && index == 0 ? <div className="btn-round expand-theme m-t-5" onClick={() => emailAppend()}><i className="fas fa-plus"></i></div> :
                                       !isDisabled &&  <div className="btn-round expand-red m-t-5" onClick={() => emailRemove(index)}><i className="fas fa-trash-alt"></i></div>
                                    }
                                </Col>
                            </Row>
                        );
                    })
                }
                <hr style={{ marginBottom: 5 }} />
                {!isDisabled && <button type="submit" className="btn btn-themed btn-min-width pull-right mt-2">Submit Changes</button> }
            </form>
        );
    };
    
    export default ContactInfo;