import React, { useState, useEffect, forwardRef } from "react";
import { SelectField } from "util/Constant";
import { CardHeader } from "reactstrap";
import { set } from "lodash";

/// <summary>
/// Author: Christopher Chan
/// </summary>
const CustomCheckboxList = forwardRef((props, ref) => {
  const {
    readOnly,
    icon,
    label,
    placeholder,
    showLabel,
    isEditable = true,
    style,
    showErrorMsg,
    error,
    type = "text",
    values,
    name,
    title,
    options,
    defaultValue,
    ...rest
  } = props;

  const handleChange = (data) => {
    setRadioValue(data);
  };

  const [radioValue, setRadioValue] = useState(defaultValue);
  return (
    <>
      {showLabel && <label className="custom-input-label">{label}</label>}
      <div className="checkList">
        <CardHeader>
          <b>{title}</b>{" "}
        </CardHeader>
        <div className="list-container">
          {options.map((item, index) => (
            <div key={index}>
              <input
                ref={ref}
                {...rest}
                onClick={(data) => handleChange(item)}
                defaultChecked={radioValue == item}
                className="radioButton"
                value={item}
                name={name}
                type="radio"
                disabled={readOnly || !isEditable}
              />
              <span>{item}</span>
            </div>
          ))}
        </div>
      </div>
      {error && showErrorMsg && <span className="input-error">{error}</span>}
    </>
  );
});

export default CustomCheckboxList;
