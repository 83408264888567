import React, {
  useMemo,
  useState,
  useRef,
  useEffect,
  useCallback,
} from "react";
import ReactTable from "components/react-table/ReactTable";
import {
  Row,
  Col,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";
import Notiflix from "notiflix";
import { Tab, Tabs, Card, CardContent } from "@mui/material";
import TabPanel, { a11yTabProps } from "components/panel/TabPanel";
import { SelectField, ApiKey, _IMAGE_URL } from "util/Constant";
import { Config, ConfigEnum } from "util/Config";
import CustomerDao from "data/CustomerDao.js";
import { useForm } from "react-hook-form";
import { stringIsNullOrEmpty } from "util/Utility.js";
import NoImagePlaceholder from "assets/img/user/No-Image-Placeholder.svg.png";
import CustomInput from "components/form/CustomInput";
import CustomSelect from "components/form/CustomSelect";
import CustomTag from "components/form/CustomTag";
import { FileUpload } from "components/form";
import TextArea from "components/form/Textarea";
import VerticalTimeline from "components/VerticalTimeline";
import ActivityLogDao from "data/ActivityLogDao";

import {
  FaIdCard,
  FaHashtag,
  FaBook,
  FaFlag,
  FaHatCowboy,
  FaMailBulk,
  FaPhone,
  FaMobile,
  FaUserTag,
  FaTransgender,
  FaBuilding,
  FaInternetExplorer,
  FaMapMarker,
  FaSuitcase,
  FaWarehouse,
} from "react-icons/fa";

let configInstance = Config.getInstance();
var token = configInstance.getValue(ConfigEnum._TOKEN);

const CustomerForm = ({
  setAppState,
  enquiry,
  toggle,
  customer,
  onClosed,
  isOpen,
  onOpened,
  newCust,
  successCallback,
}) => {
  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
    reset,
  } = useForm({});

  let configInstance = Config.getInstance();
  var token = configInstance.getValue(ConfigEnum._TOKEN);
  const _formRef = useRef();
  const [input, setInput] = useState("");
  const [tags, setTags] = useState([]);
  const [isKeyReleased, setIsKeyReleased] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [image, setImage] = useState();
  const [tabValue, setTabValue] = useState(0);
  const [systemLogs, setSystemLogs] = useState([]);
  const [systemQuery, setSystemQuery] = useState();

  useEffect(() => {
    if (enquiry && enquiry.sites) {
      reset({ sites: enquiry.sites });
    }
  }, [enquiry]);

  useEffect(() => {
    setIsEditing(false);
  }, []);

  useEffect(() => {
    setIsEditing(false);
  }, [customer]);

  const onChange = (e) => {
    const { value } = e.target;
    setInput(value);
  };

  const onKeyUp = () => {
    setIsKeyReleased(true);
  };

  const deleteTag = (index) => {
    setTags((prevState) => prevState.filter((tag, i) => i !== index));
  };

  const _SYSTEM_LOG_FILTER = [
    { label: "Last 7 Days", value: "7" },
    { label: "Last 30 Days", value: "30" },
    { label: "Last 60 Days", value: "60" },
    { label: "This Year", value: "This Year" },
  ];

  // ///<summary>
  // ///Author: Nicholas Lai
  // ///</summary>
  const deactivate = () => {
    Notiflix.Confirm.init({ plainText: false });
    Notiflix.Confirm.show(
        'Confirm deactivate?',
        `Please confirm you would like to deactivate customer`,
        'Confirm',
        'No',
        async () => {
            let dao = new CustomerDao();
            await dao.deactivate(customer, token).then(responseJson => {
                    if (responseJson[ApiKey._API_SUCCESS_KEY]) {
                      Notiflix.Report.success('Success', 'Successfully deactivate customer.', 'Okay',successCallback);
                    } else {
                      Notiflix.Report.failure('Error', 'Operation Failed. ' + responseJson[ApiKey._API_MESSAGE_KEY]);
                    }
                })
        }
    )
  };

  useEffect(() =>{
    console.log(errors);
  }, [errors])

  const onKeyDown = (e) => {
    const { key } = e;
    const trimmedInput = input.trim();

    if (
      key === "Enter" &&
      trimmedInput.length &&
      !tags.includes(trimmedInput)
    ) {
      e.preventDefault();
      setTags((prevState) => [...prevState, trimmedInput]);
      setInput("");
    }

    if (key === "Backspace" && !input.length && tags.length && isKeyReleased) {
      const tagsCopy = [...tags];
      const poppedTag = tagsCopy.pop();
      e.preventDefault();
      setTags(tagsCopy);
      setInput(poppedTag);
    }

    setIsKeyReleased(false);
  };

  useEffect(() => {
    if (systemQuery) {
      getSystemLog();
    }
  }, [systemQuery]);

  const getSystemLog = async () => {
    let dao = new ActivityLogDao();
    var obj = {
      pageIndex: 1,
      pageSize: 10,
      systemQuery: systemQuery ? systemQuery : 7,
      customerId: customer?.id,
    };

    await dao.getCustomerLog(token, obj).then((response) => {
      if (response[ApiKey._API_SUCCESS_KEY]) {
        var data = response[ApiKey._API_DATA_KEY];
        let arr = [];
        for (var i = 0; i < data.length; i++) {
          let formatArray = [];
          for (var j = 0; j < data[i].length; j++) {
            var obj = {
              date: data[i][j].datetime,
              description: data[i][j].title,
              title: data[i][j].name,
              theme: {
                title: "pink",
                main: "pink",
              },
            };
            formatArray.push(obj);
          }
          arr.push(formatArray);
        }
        setSystemLogs(arr);
      } else {
        Notiflix.Report.failure(
          "Error",
          "Failed to load customers. Please try again later"
        );
      }
    });
  };

  useEffect(() => {
    if (
      customer
    ) {
      reset({
        ...customer,
      });

      getSystemLog();
      setTags(customer.tags)
    }
  }, [customer]);

  useEffect(() => {
    console.log(customer);
    console.log(tags);
  }, [tags]);

  const onSubmit = async (data) => {
    console.log(data);
    setAppState((prevState) => ({ ...prevState, isBusy: true }));

    let customerData = {};

    let formData = new FormData();
    formData.append(`honorific`, data.honorific);
    formData.append(`shortName`, data.shortName);
    formData.append(`fullName`, data.fullName);
    formData.append(`icNumber`, data.icNumber);
    formData.append(`gender`, data.gender);
    formData.append(`companyName`, data.companyName);
    formData.append(`ssmNumber`, data.ssmNumber);
    formData.append(`department`, data.department);
    formData.append(`jobTitle`, data.jobTitle);
    formData.append(`mobileNumber`, data.mobileNumber);
    formData.append(`houseNumber`, data.workNumber);
    formData.append(`email`, data.email);
    formData.append(`website`, data.website);
    formData.append(`billingAddress`, data.billingAddress);
    formData.append(`blackList`, data.blackList);
    formData.append(`region`, data.region);
    formData.append(`remarks`, data.remarks);
    
    if (Array.isArray(data.photo)) {
      formData.append(`photo`, data.photo[0]);
    }

    let inputTags = Array.from(tags);
    console.log(inputTags);
    formData.append(`tags`, inputTags);
    customerData["tags"] = inputTags;

    let dao = new CustomerDao();

    if (isEditing) {
      formData.append(`id`, customer.id);
      console.log(formData);
      await dao.editCustomer(formData, token).then((responseJson) => {
        if (responseJson[ApiKey._API_SUCCESS_KEY]) {
          let customer = responseJson[ApiKey._API_DATA_KEY];

          Notiflix.Report.init({ plainText: false });
          Notiflix.Report.success(
            "Success",
            `Successfully update customer, <b>${customer.shortName}</b>.`,
            "Okay",
            successCallback
          );
        } else {
          Notiflix.Report.failure(
            "Error",
            responseJson[ApiKey._API_MESSAGE_KEY]
          );
        }
      });
    } else {
      await dao.createCustomerForm(formData, token).then((responseJson) => {
        if (responseJson[ApiKey._API_SUCCESS_KEY]) {
          let customer = responseJson[ApiKey._API_DATA_KEY];

          Notiflix.Report.init({ plainText: false });
          Notiflix.Report.success(
            "Success",
            `Successfully created customer, <b>${customer.shortName}</b>.`,
            "Okay",
            successCallback
          );
        } else {
          Notiflix.Report.failure(
            "Error",
            responseJson[ApiKey._API_MESSAGE_KEY]
          );
        }
      });
    }

    setAppState((prevState) => ({ ...prevState, isBusy: false }));
  };

  return (
    <Modal {...{ isOpen, toggle, onClosed, onOpened }} size="lg" centered>
      <form onSubmit={handleSubmit(onSubmit)}> 
      <ModalHeader className="modal-themed" toggle={toggle}>
        <h2 style={{ marginBottom: 0 }}>
          {customer
            ? "MANAGEMENT / Edit Customer"
            : "MANAGEMENT /  New Customer"}
        </h2>
      </ModalHeader>
      <ModalBody className="scroll-component">
         <Tabs
            variant="scrollable"
            value={tabValue}
            onChange={(e, value) => {
              setTabValue(value);
            }}
            sx={{
              borderBottom: 1,
              borderColor: "divider",
              marginBottom: "1rem",
            }}
          >
            <Tab label={"Customer Profile"} {...a11yTabProps(0)} />
            {customer && <Tab label={"Event"} {...a11yTabProps(1)} />}
          </Tabs>      
          <TabPanel value={tabValue} index={0}>
          {isEditing == false && customer && (
            <button
              type="button"
              onClick={() => {
                setIsEditing(true);
              }}
              className="btn btn-themed pull-right"
            >
              <i className="fa fa-edit"></i>
            </button>
          )}
          {isEditing == true && (
            <button
            type="button"
            onClick={() => {
              deactivate();
            }}
            style={{marginLeft : "5px"}}
            className="btn btn-themed pull-right">
              <i className="fa fa-ban"></i>
            </button>
          )}
          
          {isEditing == true && (
            <button
              type="button"
              onClick={() => {
                setIsEditing(false);
              }}
              className="btn btn-themed pull-right"
            >
              <i className="fa fa-door-open"></i> Exit Edit Mode
            </button>
          )}
          <div style={{ fontSize: 16, fontWeight: "bold", marginBottom: 20 }}>
            Customer Details{" "}
          </div>
          <div class="row">
          <Col xs={6}>
              <label className="custom-input-label">Profile Image</label>
              {!isEditing && customer ? (
                <div className="user-img__container">
                  {
                    customer?.photo ? (
                      <img src={_IMAGE_URL  + customer?.photo} />
                    ) :   <img src={NoImagePlaceholder} />
                  }
                
                </div>
              ) : (
                <FileUpload
                  control={control}
                  name="photo"
                  setImage={setImage}
                />
              )}
            </Col>  
          </div>
          <div class="row">
            <div class="col-md-4">
              <label className="custom-input-label">Honorific *</label>
              <CustomSelect
                control={control}
                rules={{
                  required: true,
                }}
                name="honorific"
                label="Designation *"
                icon={<FaHatCowboy style={{ color: "#ff0066" }} />}
                options={SelectField._DESIGNATION}
                placeholderLabel="Designation"
                showErrorMsg={true}
                defaultValue={customer?.honorific}
                readOnly={customer ? !isEditing : isEditing}
                error={errors?.honorific && "Designation is required"}
                errors={errors?.honorific && "Designation is required"}
              ></CustomSelect>
            </div>
            <div class="col-md-4">
              <CustomInput
                {...register("shortName", {
                  required: "Short Name is required",
                })}
                name="shortName"
                label="Short Name"
                icon={<FaUserTag style={{ color: "#ff0066" }} />}
                error={errors?.shortName?.message}
                showErrorMsg={true}
                defaultValue={customer?.shortName}
                readOnly={customer ? !isEditing : isEditing}
                showLabel
              />
            </div>
            <div class="col-md-4">
              <CustomInput
                {...register("fullName", {
                  required: false,
                })}
                name="fullName"
                label="Full Name"
                icon={<FaUserTag style={{ color: "#ff0066" }} />}
                defaultValue={customer?.fullName}
                readOnly={customer ? !isEditing : isEditing}
                showLabel
              />
            </div>
          </div>
          <div class="row">
            <div class="col-md-4">
              <CustomInput
                {...register("icNumber", {
                  required: false,
                })}
                name="icNumber"
                label="IC Number "
                icon={<FaIdCard style={{ color: "#ff0066" }} />}
                defaultValue={customer?.icNumber}
                readOnly={customer ? !isEditing : isEditing}
                showLabel
              />
            </div>
            <div class="col-md-4">
              <label className="custom-input-label">Gender *</label>
              <CustomSelect
                control={control}
                rules={{
                  required: true,
                }}
                name="gender"
                label="Gender *"
                icon={<FaTransgender style={{ color: "#ff0066" }} />}
                options={SelectField._GENDER_OPTIONS}
                placeholderLabel="Gender"
                showErrorMsg={true}
                defaultValue={customer?.gender}
                readOnly={customer ? !isEditing : isEditing}
                error={errors?.gender && "Gender is required"}
                errors={errors?.gender && "Gender is required"}
              ></CustomSelect>
            </div>
            <div class="col-md-4">
              <label className="custom-input-label">Flag Stat</label>
              <CustomSelect
                control={control}
                rules={{
                  required: false,
                }}
                name="blackList"
                label="Blacklist *"
                icon={<FaFlag style={{ color: "#ff0066" }} />}
                options={SelectField._BLACKLISTS}
                placeholderLabel="Flag Stat"
                defaultValue={customer?.flagStat}
                readOnly={customer ? !isEditing : isEditing}
              ></CustomSelect>
            </div>
          </div>
          <div class="row">
            <div class="col-md-4">
              <CustomInput
                {...register("workNumber", {
                  required: false,
                })}
                name="workNumber"
                label="Work Phone "
                icon={<FaPhone style={{ color: "#ff0066" }} />}
                defaultValue={customer?.houseNumber}
                readOnly={customer ? !isEditing : isEditing}
                showLabel
              />
            </div>
            <div class="col-md-4">
              <CustomInput
                {...register("mobileNumber", {
                  required: "Mobile Number is required",
                })}
                name="mobileNumber"
                label="Mobile Phone "
                icon={<FaMobile style={{ color: "#ff0066" }} />}
                error={errors?.mobileNumber?.message}
                showErrorMsg={true}
                defaultValue={customer?.mobileNumber}
                readOnly={customer ? !isEditing : isEditing}
                showLabel
              />
            </div>
            <div class="col-md-4">
              <CustomInput
                {...register("email", {
                  required: false,
                })}
                name="email"
                label="Email "
                icon={<FaMailBulk style={{ color: "#ff0066" }} />}
                defaultValue={customer?.email}
                readOnly={customer ? !isEditing : isEditing}
                showLabel
              />
            </div>
          </div>
          <div class="row">
            <div class="col-md-4">
              <CustomInput
                {...register("jobTitle", {
                  required: false,
                })}
                name="jobTitle"
                label="Designation "
                icon={<FaSuitcase style={{ color: "#ff0066" }} />}
                defaultValue={customer?.jobTitle}
                readOnly={customer ? !isEditing : isEditing}
                showLabel
              />
            </div>
            <div class="col-md-4">
              <CustomInput
                {...register("department", {
                  required: false,
                })}
                name="department"
                label="Department"
                icon={<FaWarehouse style={{ color: "#ff0066" }} />}
                defaultValue={customer?.department}
                readOnly={customer ? !isEditing : isEditing}
                showLabel
              />
            </div>
            <div class="col-md-4">
              <CustomInput
                {...register("region", {
                  required: false,
                })}
                name="region"
                label="Region "
                icon={<FaBuilding style={{ color: "#ff0066" }} />}
                defaultValue={customer?.region}
                readOnly={customer ? !isEditing : isEditing}
                showLabel
              />
            </div>
          </div>
          <div class="row">
            <div class="col-md-4">
              <CustomInput
                {...register("companyName", {
                  required: false,
                })}
                name="companyName"
                label="Company Name "
                icon={<FaBuilding style={{ color: "#ff0066" }} />}
                defaultValue={customer?.companyName}
                readOnly={customer ? !isEditing : isEditing}
                showLabel
              />
            </div>
            <div class="col-md-4">
              <CustomInput
                {...register("ssmNumber", {
                  required: false,
                })}
                name="ssmNumber"
                label="SSM Registration Number "
                icon={<FaUserTag style={{ color: "#ff0066" }} />}
                defaultValue={customer?.ssmNumber}
                readOnly={customer ? !isEditing : isEditing}
                showLabel
              />
            </div>
            <div class="col-md-4">
              <CustomInput
                {...register("website", {
                  required: false,
                })}
                name="website"
                label="Website "
                icon={<FaInternetExplorer style={{ color: "#ff0066" }} />}
                defaultValue={customer?.website}
                readOnly={customer ? !isEditing : isEditing}
                showLabel
              />
            </div>
          </div>
          <Row>
            <Col xs={12}>
              <CustomInput
                {...register("billingAddress", {
                  required: false,
                })}
                name="billingAddress"
                label="Billing Address "
                icon={<FaMapMarker style={{ color: "#ff0066" }} />}
                defaultValue={customer?.billingAddress}
                readOnly={customer ? !isEditing : isEditing}
                showLabel
              />
            </Col>
          </Row>          
          <Row>
            <Col xs={12}>
              <label className="custom-input-label">Tags</label>
              <CustomTag
                control={control}
                {...register("tags", {
                  required: false,
                })}
                name="hash"
                label="Tags "
                icon={<FaHashtag style={{ color: "#ff0066" }} />}
                error={errors?.username}
                onKeyDown={onKeyDown}
                tags={tags}
                input={input}
                onKeyUp={onKeyUp}
                onChange={onChange}
                readOnly={customer ? !isEditing : isEditing}
                deleteTag={deleteTag}
              ></CustomTag>
            </Col>                              
          </Row>     
          <Row >
            <Col xs={12}>
               <TextArea
                {...register("remarks", {
                  required: false,
                })}
                name="remarks"
                label="Remarks "
                icon={<FaBook style={{ color: "#ff0066" }} />}
                error={errors?.username}
                rows="5"
                type="textarea"
                readOnly={customer ? !isEditing : isEditing}
                defaultValue={enquiry?.remarks}
                showLabel
              />
            </Col>    
          </Row>
          </TabPanel> 
          <TabPanel value={tabValue} index={1}>
            <Row>
              <Col xs={6}>
                <div
                  className="activities-title"
                  style={{ display: "inline-block" }}
                >
                  System Log
                </div>
                <CustomSelect
                  control={control}
                  name="Filter"
                  placeholder="Filter"
                  onChange={(data) => {
                    setSystemQuery(data);
                  }}
                  options={_SYSTEM_LOG_FILTER}
                  styles={{ display: "inline-block" }}
                />
                {systemLogs.length > 0 && (
                  <VerticalTimeline items={systemLogs} />
                )}
              </Col>
             
            </Row>
          </TabPanel>
      </ModalBody>
      <ModalFooter>
        <button
          type="button"
          className="btn btn-red mr-auto btn-min-width"
          onClick={toggle}
        >
          Cancel
        </button>
        {isEditing && customer && (
            <button
              type="submit"
              className="btn btn-themed btn-min-width pull-right mt-2"
            >
              Submit Changes
            </button>
          )}
          {!customer && (
            <button
              type="submit"
              className="btn btn-themed btn-min-width pull-right mt-2"
            >
              Submit New Customer
            </button>
          )}
      </ModalFooter>
      </form>
    </Modal>
  );
};

export default CustomerForm;
