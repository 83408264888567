import React, { useState, useEffect } from "react";
import { Card, CardHeader, CardBody, Row, Col } from "reactstrap";
import { useParams } from "react-router-dom";
import EnquiryDao from "data/EnquiryDao.js";
import CustomerDao from "data/CustomerDao";
import { ApiKey } from "util/Constant";
import { Config, ConfigEnum } from "util/Config";
import NavigationButton from "components/buttons/NavigationButton";
import EnquiryForm from "components/enquiry/EnquiryForm";
import { useRecoilState } from "recoil";
import { customerListState } from "recoil/Atoms.js";
import Notiflix from "notiflix";
import classnames from "classnames";

///<summary>
///Author: Nicholas Lai
///</summary>
export default () => {
  const enquiryDao = new EnquiryDao();
  const customerDao = new CustomerDao();
  const [customerListOption, setCustomerListState] =
    useRecoilState(customerListState);
  const [isDisabled, setIsDisabled] = useState(true);
  const [enquiry, setEnquiry] = useState();
  let { id } = useParams();
  let configInstance = Config.getInstance();
  var token = configInstance.getValue(ConfigEnum._TOKEN);
  let classes = classnames("label custom-status-label", {
    "label-secondary": enquiry?.status === 0,
    "label-danger": enquiry?.status === -1,
    "label-success": enquiry?.status === 1,
    "label-danger": enquiry?.status === -2,
  });

  /// <summary>
  /// Author: Nicholas Lai
  /// </summary>
  useEffect(() => {
    getEnquiryInfo(id);

    getCustomerList();
  }, []);

  // ///<summary>
  // ///Author: Nicholas Lai
  // ///</summary>
  const toggleFalse = () => {
    setIsDisabled(false);
  };

  // ///<summary>
  // ///Author: Nicholas Lai
  // ///</summary>
  const toggleTrue = () => {
    setIsDisabled(true);
  };

  const getEnquiryInfo = async (id) => {
    await enquiryDao.getEnquiry(id, token).then((response) => {
      console.log(response);
      if (response[ApiKey._API_SUCCESS_KEY]) {
        var data = response[ApiKey._API_DATA_KEY];
        setEnquiry(data);
        console.log(data);
      }
    });
  };

  /// <summary>
  /// Author: Christopher Chan
  /// </summary>
  const getCustomerList = async () => {
    await customerDao.getCustomerList(token).then((response) => {
      if (response[ApiKey._API_SUCCESS_KEY]) {
        var data = response[ApiKey._API_DATA_KEY];
        var customerList = [];
        data.map((item) => {
          var custOption = {
            label: item.fullName + " (" + item.icNumber + ")",
            value: item.id,
          };
          customerList.push(custOption);
        });
        setCustomerListState(customerList);
      } else {
        Notiflix.Report.Failure(
          "Error",
          "Failed to load customer. Please try again later"
        );
      }
    });
  };

  return (
    <>
      <h1 className="page-header">
        <NavigationButton />
        {"View Enquiry"}
      </h1>
      <Card className="mb-2">
        <CardHeader>
          Enquiry {enquiry && enquiry.enquiryId}
          <div
            style={{ display: "inline-block", marginLeft: "10px" }}
            className={classes}
          >
            {enquiry && enquiry.statusName}
          </div>
        </CardHeader>
        <CardBody
          className="scrollable-container"
          style={{ maxHeight: "65vh" }}
        >
          <Row>
            <Col>
              Customer Shortname <b>{enquiry && enquiry.customer.shortName}</b>
            </Col>
            <Col>
              Customer Fullname <b>{enquiry && enquiry.customer.fullName}</b>
            </Col>
          </Row>
          <Row>
            <Col>
              Customer Phone <b>{enquiry && enquiry?.customer?.mobileNumber}</b>
            </Col>
            <Col>
              Customer Email <b>{enquiry?.customer?.emails}</b>
            </Col>
          </Row>
          <Row>
            <Col>
              Remarks <b>{enquiry && enquiry.remarks}</b>
            </Col>
          </Row>
        </CardBody>
      </Card>
      <div className="float-xl-right">
        {isDisabled && (
          <button
            type="type"
            className="btn btn-sm btn-themed mb-2"
            style={{ marginRight: "10px" }}
            onClick={toggleFalse}
          >
            <i className="fa fa-edit mr-1"></i>Edit
          </button>
        )}
        {!isDisabled && (
          <button
            type="type"
            className="btn btn-sm btn-themed mb-2"
            onClick={toggleTrue}
          >
            <i className="fa fa-times mr-1"></i>Cancel
          </button>
        )}
      </div>

      <EnquiryForm
        enquiry={enquiry && enquiry}
        isDisabled={isDisabled}
        setIsDisabled={setIsDisabled}
        customerListOption={customerListOption}
      />
    </>
  );
};
