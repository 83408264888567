import React, { useState, useEffect } from "react";
import { Card, CardHeader, CardBody, Row, Col } from "reactstrap";
import { Confirm, Report } from "notiflix";
import { report } from "util/Notiflix";
import { useForm, useFieldArray } from "react-hook-form";
import { useHistory, useLocation } from "react-router-dom";
import { appState, siteListState, customerListState } from "recoil/Atoms.js";
import { useRecoilState, useSetRecoilState } from "recoil";

import { InputTypes, ApiKey } from "util/Constant";
import { Config, ConfigEnum } from "util/Config";

import EnquiryDao from "data/EnquiryDao";
import CommonDao from "data/CommonDao";
import QuotationDao from "data/QuotationDao";
import ContractDao from "data/ContractDao";

import InputHoc from "components/form/InputHoc.js";
import NavigationButton from "components/buttons/NavigationButton";
import { tryCatch } from "util/Utility";
import setting from "../../settings.json";
import CustomInput from "components/form/CustomInput";
import CustomSelect from "components/form/CustomSelect";

/// <summary>
/// Author: Christopher Chan
/// </summary>
const ItemsCard = ({
  register,
  control,
  items,
  errors,
  elevatorModels,
  setValue,
}) => {
  const { fields, append, remove } = useFieldArray({
    control,
    name: `items`,
  });
  const [isEditing, setIsEditing] = useState(false);

  /// <summary>
  /// Author: Christopher Chan
  /// </summary>
  useEffect(() => {
    if (items.length > 0) {
      let processedItems = [];

      items.map((item) => {
        processedItems.push({
          name: item.name,
          ...item,
        });
      });

      append(processedItems);
    } else {
      append({
        discount: setting.Discount,
        tax: setting.Tax,
      });
    }
  }, []);

  /// <summary>
  /// Author: Christopher Chan
  /// </summary>
  const handleNameOnChange = (value, index) => {
    let itemExist = elevatorModels.find((x) => x.name === value);

    if (itemExist) {
      setValue(`items[${index}]`, {
        serialNumber: itemExist.serialNumber,
        description: itemExist.description,
        price: itemExist.price,
      });
    }
  };

  return (
    <Card className="mb-2">
      <CardHeader>Items ({fields.length})</CardHeader>
      <CardBody className="scrollable-container">
        {fields.map((item, index) => {
          return (
            <>
              {index !== 0 && <hr />}
              <Row key={item.id}>
                <Col>
                  <Row>
                    <Col xs={8}>
                      <label className="custom-input-label">Item Name *</label>
                      <CustomSelect
                        control={control}
                        rules={{
                          required: true,
                        }}
                        name={`items[${index}].name`}
                        label="Item Name *"
                        options={elevatorModels}
                        placeholderLabel="Search Item Name *"
                        defaultValue={item?.name}
                        showErrorMsg={true}
                        error={errors && "Item Name is required"}
                        errors={errors && "Item Name is required"}
                      ></CustomSelect>
                    </Col>

                    <Col xs={4}>
                      <CustomInput
                        {...register(`items[${index}].quantity`, {
                          required: "Quantity is required",
                        })}
                        name={`items[${index}].quantity`}
                        label="Quantity *"
                        defaultValue={item?.quantity}
                        error={errors ? errors[index]?.quantity?.message : ""}
                        showErrorMsg={true}
                        showLabel
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={12}>
                      <CustomInput
                        {...register(`items[${index}].description`, {
                          required: "Description is required",
                        })}
                        name={`items[${index}].description`}
                        label="Description *"
                        defaultValue={item?.description}
                        error={
                          errors ? errors[index]?.description?.message : ""
                        }
                        showErrorMsg={true}
                        showLabel
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={4}>
                      <CustomInput
                        {...register(`items[${index}].price`, {
                          required: "Price is required",
                        })}
                        name={`items[${index}].price`}
                        label="Price *"
                        defaultValue={item?.price}
                        error={errors ? errors[index]?.price?.message : ""}
                        showErrorMsg={true}
                        showLabel
                      />
                    </Col>
                    <Col xs={4}>
                      <CustomInput
                        {...register(`items[${index}].discount`, {
                          required: "Discount is required",
                        })}
                        name={`items[${index}].discount`}
                        label="Discount *"
                        defaultValue={item?.discount}
                        error={errors ? errors[index]?.discount?.message : ""}
                        showErrorMsg={true}
                        showLabel
                      />
                    </Col>
                    <Col xs={4}>
                      <CustomInput
                        {...register(`items[${index}].tax`, {
                          required: "Tax is required",
                        })}
                        name={`items[${index}].tax`}
                        label="Tax *"
                        defaultValue={item?.tax}
                        error={errors ? errors[index]?.tax?.message : ""}
                        showErrorMsg={true}
                        showLabel
                      />
                    </Col>
                  </Row>
                </Col>
                <Col xs={1} className="flex-center">
                  {fields.length - 1 === index ? (
                    index === 0 ? (
                      <div
                        className="btn-round expand-theme mt-2"
                        onClick={append}
                      >
                        <i className="las la-plus"></i>
                      </div>
                    ) : (
                      [
                        <div
                          className="btn-round expand-theme mr-2"
                          onClick={append}
                        >
                          <i className="las la-plus"></i>
                        </div>,

                        <div
                          className="btn-round expand-red mr-2"
                          style={{ marginTop: "10px" }}
                          onClick={() => remove(index)}
                        >
                          <i className="las la-times"></i>
                        </div>,
                      ]
                    )
                  ) : (
                    <div
                      className="btn-round expand-red mt-2"
                      onClick={() => remove(index)}
                    >
                      <i className="las la-times"></i>
                    </div>
                  )}
                </Col>
              </Row>
            </>
          );
        })}
      </CardBody>
    </Card>
  );
};

///<summary>
///Author: Christopher Chan
///</summary>
export default () => {
  const history = useHistory();
  const state = useLocation()?.state;
  const quotation = state != undefined ? state?.quotation : null;
  const enquiry = state != undefined ? state?.enquiry : null;
  const siteId = state != undefined ? state?.siteId : null;
  const [siteList, setSitesList] = useState([]);
  const [siteOptions, setSiteOptions] = useState([]);
  const [selectedSite, setSelectedSite] = useState(null);
  const [elevatorModels, setElevatorModels] = useState([]);
  const setAppState = useSetRecoilState(appState);
  const [isEditing, setIsEditing] = useState(false);

  const {
    register,
    control,
    handleSubmit,
    formState: { errors },
    watch,
    setValue,
  } = useForm();
  console.log(enquiry);
  const watchSiteSelection = watch("siteId");

  let configInstance = Config.getInstance();
  var token = configInstance.getValue(ConfigEnum._TOKEN);

  /// <summary>
  /// Author: Christopher Chan
  /// </summary>
  const getSitesList = async () => {
    tryCatch(async () => {
      let dao = new EnquiryDao();
      await dao.getEnquirySitesWithoutQuotation(token).then((response) => {
        if (response[ApiKey._API_SUCCESS_KEY]) {
          var data = response[ApiKey._API_DATA_KEY];
          var siteOption = [];
          console.log(data);
          data.map((item) => {
            var option = {
              label: item.title + " (" + item.enquiryId + ")",
              value: item.id,
            };
            siteOption.push(option);
          });

          setSiteOptions(siteOption);
          setSitesList(data);
        } else {
          Report.failure(
            "Error",
            "Failed to load enquiry sites. Please try again later"
          );
        }
      });
    });
  };

  /// <summary>
  /// Author: Christopher Chan
  /// </summary>
  const getElevatorModels = () => {
    tryCatch(async () => {
      let dao = new CommonDao();
      await dao.getElevatorModels(token).then((response) => {
        if (response[ApiKey._API_SUCCESS_KEY]) {
          var data = response[ApiKey._API_DATA_KEY];

          var options = data.map((item) => {
            return {
              label: `${item.name} (${item.serialNumber})`,
              value: item.name,
              ...item,
            };
          });

          setElevatorModels(options);
        } else {
          Report.Failure("Error", "Failed to retrieve elevator models.");
        }
      });
    });
  };

  /// <summary>
  /// Author: Christopher Chan
  /// </summary>
  const createContract = (id) => {
    tryCatch(async () => {
      let dao = new ContractDao();

      await dao.createOrUpdateContract({ id }, token).then((responseJson) => {
        if (responseJson[ApiKey._API_SUCCESS_KEY]) {
          let data = responseJson[ApiKey._API_DATA_KEY];

          Report.Init({ plainText: false });
          Report.Success(
            "Success",
            `Successfully created contract, <b>${data.contractId}</b>.`,
            "Okay",
            () => history.goBack()
          );
        } else {
          Report.Failure(
            "Oops",
            "Failed to create contract. " +
              responseJson[ApiKey._API_MESSAGE_KEY]
          );
        }
      });
    });
  };

  /// <summary>
  /// Author: Christopher Chan
  /// </summary>
  const promptCreateContract = (quotation) => {
    Confirm.init({ plainText: false });
    Confirm.show(
      "Proceed with Contract Creation",
      `Would you like to proceed with creating a contract for this quotation, <b>${quotation.quotationId}</b>?`,
      "Proceed",
      "No",
      () => createContract(quotation.id),
      () => history.goBack()
    );
  };

  /// <summary>
  /// Author: Christopher Chan
  /// </summary>
  const onSubmit = (data) => {
    console.log(data);
    tryCatch(async () => {
      let quotationData = data;

      if (quotation) {
        quotationData["id"] = quotation.id;
      }

      let dao = new QuotationDao();
      let action = "";
      if (quotationData.id) {
        action = dao.updateQuotation(quotationData, token);
      } else {
        action = dao.createQuotation(quotationData, token);
      }

      await action.then((responseJson) => {
        let data = responseJson[ApiKey._API_DATA_KEY];

        report("quotation", responseJson, data.quotationId, quotationData?.id);
      });
    });
  };

  const onSubmitForm = async (data) => {
    setAppState((prevState) => ({ ...prevState, isBusy: true }));

    const formData = new FormData();

    if (data.items !== null) {
      data.items.map((item, index) => {
        console.log(item);
      });
    }

    setAppState((prevState) => ({ ...prevState, isBusy: false }));
  };

  /// <summary>
  /// Author: Christopher Chan
  /// </summary>
  useEffect(() => {
    if (watchSiteSelection) {
      setSelectedSite(siteList.find((x) => x.id === watchSiteSelection));
    }
  }, [watchSiteSelection]);

  /// <summary>
  /// Author: Christopher Chan
  /// </summary>
  useEffect(() => {
    if (siteList.length > 0) {
      setValue("siteId", siteId);
    }
  }, [siteList]);

  /// <summary>
  /// Author: Christopher Chan
  /// </summary>
  useEffect(() => {
    getSitesList();
    getElevatorModels();
  }, []);

  return (
    <>
      <ol className="breadcrumb float-xl-right">
        {quotation ? (
          <>
            <li className="breadcrumb-item">Update Quotation</li>
            <li className="breadcrumb-item active">{quotation.quotationId}</li>
          </>
        ) : (
          <li className="breadcrumb-item active">Add Quotation</li>
        )}
      </ol>
      <h1 className="page-header">
        <NavigationButton />
        {quotation
          ? `Update Quotation - ${quotation.quotationId}`
          : "Add Quotation"}
      </h1>
      <form id="enquiryForm" onSubmit={handleSubmit(onSubmit)}>
        <Row>
          <Col>
            {!quotation && (
              <Card className="mb-2">
                <CardHeader>Select Enquiry:</CardHeader>
                <CardBody>
                  <CustomSelect
                    control={control}
                    rules={{
                      required: true,
                    }}
                    name="siteId"
                    label="Customer *"
                    options={siteOptions}
                    placeholderLabel="Search Enquiry *"
                    defaultValue={enquiry?.siteId}
                    showErrorMsg={true}
                    readOnly={quotation ? !isEditing : isEditing}
                    error={errors?.siteId && "Enquiry is required"}
                    errors={errors?.siteId && "Enquiry is required"}
                  ></CustomSelect>
                </CardBody>
              </Card>
            )}

            <ItemsCard
              setValue={setValue}
              register={register}
              control={control}
              items={quotation ? quotation?.latestVersion?.items : []}
              errors={errors?.items}
              elevatorModels={elevatorModels}
            />
          </Col>
          <Col xs={2}>
            <InputHoc
              {...register("files", {
                required: false,
              })}
              name={"files"}
              label="Quotation Attachments Files"
              inputType={InputTypes.FILEUPLOAD}
              control={control}
              multiple={true}
            />
          </Col>
          <Col xs={2}>
            <button type="submit" className="btn btn-themed btn-block">
              Save
            </button>
            <hr />
            {selectedSite && (
              <>
                <h5>Site Details</h5>
                <ul>
                  <li>{selectedSite?.siteProjectTitle}</li>
                  <li>{selectedSite?.address?.fullAddress}</li>
                </ul>
                <br />
                <h5>Customer Details</h5>
                <ul>
                  <li>{selectedSite?.customer?.fullName}</li>
                  <li>{selectedSite?.customer?.companyName}</li>
                </ul>
              </>
            )}
          </Col>
        </Row>
      </form>
    </>
  );
};
