import React from 'react';
import moment from 'moment';

/// <summary>
/// Author: KurisuCodes
/// </summary>
export default ({ item, isLast }) => (
    <div className="vertical-item">
        <div className="vertical-item-date">
            <div className="date">{moment(item.date).format('DD.MMM.YYYY')}</div>
            <div className="time">{moment(item.date).format('hh:mm A (ddd)')}</div>
        </div>
        <div className={`vertical-item-dot ${item.theme.main}`}></div>
        <div className="vertical-item-content">
            <div className={`maintext ${item.theme.title}`}>{item.title}</div>
            <div className={`subtext ${item.theme.main}`}>{item.description}</div>
        </div>
        {
            !isLast && <div className="vertical-item-connector"></div>
        }
    </div>
);