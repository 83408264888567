import AuthenticationDao from 'data/AuthenticationDao';
import { atom, selector } from 'recoil';
import { ApiKey, PaymentMethod } from '../util/Constant';

/// <summary>
/// Author: Christopher Chan
/// </summary>
export const appState = atom({
    key: 'appState',
    default: {
        isBusy: false,
        message: 'Loading...'
    }
});

export const isLoggedInState = atom({
    key: 'isLoggedIn',
    default: true,
})

export const authCredentialState = atom({
    key: 'authCredentialState',
    default: {}
})

/// <summary>
/// Author: Lewis
/// </summary>
export const loginFormState = atom({
    key: 'loginFormState',
    default: {
        username: '',
        password: '',
        otpMethod: '',
        oneTimePassword: '',
    },
})

/// <summary>
/// Author: Sim
/// </summary>
export const customerFormState = atom({
    key: 'customerFormState',
    default: {
        'id': '',
        'honorific': '',
        'shortName': '',
        'fullName': '',
        'icNumber': '',
        'gender': '',
        'companyName': '',
        'ssmNumber': '',
        'department': '',
        'jobTitle': '',
        'tag': '',
        'phones': [],
        'emails': [],
        'addresses': [],
        'websites': [],
        'remarks': '',
        'natureOfBusinesses': [],
        'relationships': [],
        'blacklists': []
    }
})

/// <summary>
/// Author: Sim
/// </summary>
export const natureOfBusinessesState = atom({
    key: 'natureOfBusinessesState',
    default: []
})

/// <summary>
/// Author: Sim
/// </summary>
export const blacklistState = atom({
    key: 'blacklistState',
    default: []
})

/// <summary>
/// Author: Sim
/// </summary>
export const enquiryFormState = atom({
    key: 'enquiryFormState',
    default: {
        'id': '',
        'customerId': '',
        'sites': []
    }
})

/// <summary>
/// Author: Sim
/// </summary>
export const customerListState = atom({
    key: 'customerListState',
    default: []
})

/// <summary>
/// Author: Sim
/// </summary>
export const enquiryListState = atom({
    key: 'enquiryList',
    default: []
});

/// <summary>
/// Author: Sim
/// </summary>
export const siteListState = atom({
    key: 'siteList',
    default: []
});


/// <summary>
/// Author: Christopher Chan
/// </summary>
export const cartState = atom({
    key: 'cartState',
    default: [
        {
            servicesName: 'Add Incorporation',
            hkDollar: 19.80,
            ownershipCompany: 'QQQ Limited',
            localCurrency: 2.3,
        },
        {
            servicesName: 'New Package (Com Sec)',
            ownershipCompany: 'XYZ Limited',
            hkDollar: 24.99,
            localCurrency: 4.99,
        },
        {
            servicesName: 'Modification (Registration Name)',
            ownershipCompany: 'AAA Limited',
            hkDollar: 12.50,
            localCurrency: 1.75,
        },
        {
            servicesName: 'Query and Confirmation (Compliance Act)',
            ownershipCompany: 'AAA Limited',
            hkDollar: 14.89,
            localCurrency: 2.05,
        },
        {
            servicesName: 'Add Branch Address',
            ownershipCompany: 'AAA Limited',
            hkDollar: 19.80,
            localCurrency: 2.3,
        },
        {
            servicesName: 'Query and Confirmation (Mergers and Retirements)',
            ownershipCompany: 'QQQ Limited',
            hkDollar: 17.25,
            localCurrency: 2.20,
        },
    ]
});


/// <summary>
/// Author: Lewis
/// </summary>
export const notificationState = atom({
    key: 'notificationState',
    default: [
        { id: 1, headline: "Successfully Transfered", notificationType: 'success', description: "Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC, making it over 2000 years old. Richard McClintock, a Latin." },
        { id: 2, headline: "Appointment Cancelled", notificationType: 'warning', description: "Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC, making it over 2000 years old. Richard McClintock, a Latin." },
        { id: 3, headline: "New Email", notificationType: 'info', description: "Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC, making it over 2000 years old. Richard McClintock, a Latin." }
    ]
})

/// <summary>
/// Author: Lewis
/// </summary>
export const alertNotification = atom({
    key: 'alertNotification',
    default: []
})