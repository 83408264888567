import React, { useState, useEffect } from "react";
import { Card, CardHeader, CardBody, Row, Col } from 'reactstrap';
import {  Report } from "notiflix";
import { useHistory, useLocation , useParams} from 'react-router-dom';

import {  ApiKey } from "util/Constant";
import { Config, ConfigEnum } from 'util/Config';
import CommonDao from "data/CommonDao";
import NavigationButton from 'components/buttons/NavigationButton';
import { tryCatch } from 'util/Utility';
import QuotationForm from "components/quotation/QuotationForm";
import classnames from 'classnames';

///<summary>
///Author: Nicholas Lai
///</summary>
export default () => {
        
        const history = useHistory();
        const state = useLocation()?.state;
        const quotation = state != undefined ? state?.quotation : null;
        const [isDisabled, setIsDisabled] = useState(true);
        const [className, setClassName] = useState([]);
        const [elevatorModels, setElevatorModels] = useState([]);
        let configInstance = Config.getInstance();
        var token = configInstance.getValue(ConfigEnum._TOKEN);
        let classes = classnames('label custom-status-label', {
                'label-secondary': quotation?.status === 0,
                'label-danger': quotation?.status === -1,
                'label-success': quotation?.status === 1,
                'label-danger': quotation?.status === -2
            })
    
        /// <summary>
        /// Author: Nicholas Lai
        /// </summary>
        useEffect(() => {   
                getElevatorModels();
                let status = quotation && quotation.status;              
        }, []);

        /// <summary>
        /// Author: Nicholas Lai
        /// </summary>
        const getElevatorModels = () => {
                tryCatch(async () => {
                let dao = new CommonDao();
                await dao.getElevatorModels(token).then((response) => {
                        if (response[ApiKey._API_SUCCESS_KEY]) {
                        var data = response[ApiKey._API_DATA_KEY];

                        var options = data.map((item) => {
                                return {
                                label: `${item.name} (${item.serialNumber})`,
                                value: item.name,
                                ...item,
                                };
                        });

                        setElevatorModels(options);
                        } else {
                        Report.Failure('Error', 'Failed to retrieve elevator models.');
                        }
                });
                });
        }
    
        // ///<summary>
        // ///Author: Nicholas Lai
        // ///</summary>
        const toggleFalse = () => {
            setIsDisabled(false);
        };
    
        // ///<summary>
        // ///Author: Nicholas Lai
        // ///</summary>
        const toggleTrue = () => {
            setIsDisabled(true);
        };
    
        return <>
         <h1 className="page-header">
                <NavigationButton />
                {'View Quotation'}
        </h1>
        <Card className="mb-2">
            <CardHeader>Quotation {quotation && quotation.quotationId}
            <div style={{display:'inline-block', marginLeft:'10px'}} className={classes}> 
                        {
                               quotation && quotation.statusName
                        }
            </div>
            </CardHeader>
            <CardBody className="scrollable-container" style={{ maxHeight: '65vh' }} >
                <Row>
                        <Col>Site Project Title <b>{quotation && quotation.enquiry.title}</b> </Col>
                        <Col>Enquiry ID <b>{quotation && quotation.enquiry.enquiryId}</b></Col>
                </Row>
                <Row>
                        <Col>Customer <b>{quotation && quotation.enquiry.customer.fullName}</b></Col>
                        <Col>Status  <div style={{display:'inline'}} className={className}>
                         <b>{quotation && quotation.statusName}</b>
                        </div></Col>
                      
                </Row>
            </CardBody>
        </Card>

        <div style={{textAlign:'right'}}>
            <button type="type" className="btn btn-sm btn-themed mb-2" style={{marginRight:'10px'}} onClick={toggleFalse}><i className="fa fa-edit mr-1"></i>Edit</button> 
            {!isDisabled &&  <button type="type" className="btn btn-sm btn-themed mb-2" onClick={toggleTrue}><i className="fa fa-edit mr-1"></i>Cancel</button>}
        </div> 
       
        <QuotationForm 
                items={quotation ? quotation?.latestVersion?.items : []} 
                 elevatorModels={elevatorModels} 
                 quotation={quotation && quotation}
                 isDisabled={isDisabled}/>     
        </>;
    }