import React, { useEffect, forwardRef, useState } from "react";
import { Card, CardBody, Row, Col } from 'reactstrap';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { InputTypes, ApiKey } from "util/Constant";
import { Config, ConfigEnum } from 'util/Config';
import ElevatorModelDao from "data/ElevatorModelDao";
import InputHoc from "components/form/InputHoc.js";
import { useForm, useFieldArray } from "react-hook-form";
import { Report } from 'notiflix';
import NavigationButton from 'components/buttons/NavigationButton';
import { tryCatch } from 'util/Utility';
import Notiflix from "notiflix";

const _YEAR_OPTIONS = [
    { label: "1 Year", value: 1 },
    { label: "2 Years", value: 2 },
    { label: "5 Years", value: 5 },
]

const _MONTH_OPTIONS = [
    { label: "3 months", value: 3 },
    { label: "6 months", value: 6 },
    { label: "1 Year", value: 1 },
    { label: "2 Years", value: 2 },
]

/// <summary>
/// Author: Christopher Chan
/// </summary>
const ModelPartsArray = forwardRef(({ register, control, errors, data, token }, ref) => {
    const { fields, append, remove } = useFieldArray({
        control,
        name: 'parts'
    });

    const updatePart = (data, index) => {
        console.log(data);
        var partName = document.getElementsByName(`parts[${index}].partName`)[0].value;
        var serialNumber = document.getElementsByName(`parts[${index}].serialNumber`)[0].value;    
        var remark = document.getElementsByName(`parts[${index}].remark`)[0].value;

        var obj = {
            partName : partName,
            serialNumber : serialNumber,
            lifespanYear : data.lifespanYear,
            serviceTimeFrame : data.serviceTimeFrame,
            remark : remark,
            id : data.partId
        }
        console.log(obj);
        Notiflix.Confirm.show(
          'Update Part ('+data.partName+')?',
          `Are you sure you would like to update</b>`,
          'Update Now',
          'No',
          () => updatePartHandler(obj)
        )
      } 

      const createPart = (item, index) => {
        console.log(data);
        var partName = document.getElementsByName(`parts[${index}].partName`)[0].value;
        var serialNumber = document.getElementsByName(`parts[${index}].serialNumber`)[0].value;    
        var remark = document.getElementsByName(`parts[${index}].remark`)[0].value;

        var obj = {
            partName : partName,
            serialNumber : serialNumber,
            lifespanYear : item.lifespanYear,
            serviceTimeFrame : item.serviceTimeFrame,
            remark : remark,
            id : data.details.id
        }
        console.log(obj);
        Notiflix.Confirm.show(
          'Create New Part ('+partName+')?',
          `Are you sure you would like to add new part</b>`,
          'Create Now',
          'No',
          () => createPartHandler(obj)
        )
      } 

      const deletePart = (data,index) => {
       console.log(data);
        Notiflix.Confirm.show(
          'delete Part ('+data.partName+')?',
          `Are you sure you would like to delete</b>`,
          'DELETE Now',
          'No',
          () => deletePartHandler(data.partId,index)
        )
      } 

      

      const updatePartHandler = (data) => {
        tryCatch(async () => {
            let dao = new ElevatorModelDao();

            await dao.updateElevatorPart(data, token).then(responseJson => {
                if (responseJson[ApiKey._API_SUCCESS_KEY]) {
                    Report.success('Success', 'Successfully update model parts.', 'Okay');
                } else {
                    Report.failure('Error', 'Operation Failed. ' + responseJson[ApiKey._API_MESSAGE_KEY]);
                }
            })
        });
      }

      
      const createPartHandler = (data) => {
        tryCatch(async () => {
            let dao = new ElevatorModelDao();

            await dao.createElevatorPart(data, token).then(responseJson => {
                if (responseJson[ApiKey._API_SUCCESS_KEY]) {
                    Report.success('Success', 'Successfully create model part.', 'Okay');
                } else {
                    Report.failure('Error', 'Operation Failed. ' + responseJson[ApiKey._API_MESSAGE_KEY]);
                }
            })
        });
      }

      const deletePartHandler = (data,index) => {
        tryCatch(async () => {
            let dao = new ElevatorModelDao();
            console.log(data);
            await dao.deleteElevatorPart(data, token).then(responseJson => {
                if (responseJson[ApiKey._API_SUCCESS_KEY]) {
                    Report.success('Success', 'Successfully delete model parts.', 'Okay', remove(index));
                } else {
                    Report.failure('Error', 'Operation Failed. ' + responseJson[ApiKey._API_MESSAGE_KEY]);
                }
            })
        });
      }

    return <>
         <button type="button" onClick={append} className="btn btn-themed btn-min-width">Add New Part</button>    
         <br/>
        {           
            fields.map((item, index) => {
                console.log(item);
                return (
                    <form>
                        <Row key={item.id}>
                            <Col className="custom-col">
                                {index + 1}.
                            </Col>
                            <Col>
                                <InputHoc 
                                    name={`parts[${index}].partName`} 
                                    label="Part Name *"
                                    error={errors?.parts ? errors?.parts[index]?.partName?.message : ""}
                                    {...register(`parts[${index}].partName`, {
                                        required: "Part Name is required",
                                    })}
                                    showErrorMsg={true} 
                                    defaultValue={item?.partName}
                                />
                            </Col>
                            <Col xs={2}>
                                <InputHoc 
                                    name={`parts[${index}].serialNumber`} 
                                    label="Serial Number (optional) *"
                                    {...register(`parts[${index}].serialNumber`, {
                                        required: false,
                                    })}
                                    defaultValue={item?.serialNumber}
                                />
                            </Col>
                            <Col xs={1}>
                                <InputHoc 
                                    name={`parts[${index}].lifespanYear`} 
                                    label="Lifespan Year *"
                                    error={errors?.parts ? errors?.parts[index]?.lifespanYear?.message : ""}
                                    control={control}
                                    ref={register}
                                    inputType={InputTypes.SELECT}
                                    options={_YEAR_OPTIONS}
                                    showErrorMsg={true} 
                                    defaultValue={item?.lifespanYear}
                                    onChange={(e) => {
                                        fields[index].lifespanYear = e;
                                    }}
                                />
                            </Col>
                            <Col xs={1}>
                            <InputHoc 
                                    name={`parts[${index}].serviceTimeFrame`} 
                                    label="Service Time Frame *"
                                    error={errors?.parts ? errors?.parts[index]?.serviceTimeFrame?.message : ""}
                                    control={control}
                                    ref={register}
                                    inputType={InputTypes.SELECT}
                                    options={_MONTH_OPTIONS}
                                    showErrorMsg={true} 
                                    defaultValue={item?.serviceTimeFrame}
                                    onChange={(e) => {
                                        fields[index].serviceTimeFrame = e;
                                    }}
                                />

                            </Col>
                            <Col xs={2}>
                                <InputHoc name={`parts[${index}].remark`} 
                                    label="Remarks"
                                    {...register(`parts[${index}].remark`, {
                                        required: false,
                                    })}
                                    defaultValue={item?.remark} />
                            </Col>
                            <Col xs={2} >
                                {
                                    data && item.partName ? (
                                        <div className="btn-round expand-red mt-2" onClick={() => updatePart(item, index)}><i className="las la-save"></i></div>
                                    ) :  <div className="btn-round expand-red mt-2" onClick={() => createPart(item, index)}><i className="las la-save"></i></div>
                                }                              
                                &nbsp;

                                {
                                    data && item.partName  ? (
                                        <div className="btn-round expand-red mt-2" onClick={() => deletePart(item,index)}><i className="las la-times"></i></div>
                                    ) : <div className="btn-round expand-red mt-2" onClick={() => remove(index)}><i className="las la-times"></i></div> 
                                }
                                
                            </Col>
                      </Row>
                    </form>
                )
            })
        }
    </>;
});

///<summary>
///Author: Christopher Chan
///</summary>
export default () => {
    const history = useHistory();
    const { id } = useParams();
    const { state } = useLocation();
    const { register, control, handleSubmit, reset, errors } = useForm();
    const isEditing = id !== 'create';

    let configInstance = Config.getInstance();
    var token = configInstance.getValue(ConfigEnum._TOKEN);

    /// <summary>
    /// Author: Christopher Chan
    /// </summary>
    const onSubmit = (data) => {
        tryCatch(async () => {
            let dao = new ElevatorModelDao();

            if (isEditing) {
                    
                data = {
                    ...data,
                    id: state.details.id
                }
            }

            await dao.createOrUpdateElevatorModel(data, token).then(responseJson => {
                if (responseJson[ApiKey._API_SUCCESS_KEY]) {
                    Report.success('Success', 'Successfully created model parts.', 'Okay', () => history.goBack());
                } else {
                    Report.failure('Error', 'Operation Failed. ' + responseJson[ApiKey._API_MESSAGE_KEY]);
                }
            })
        });
    }

    /// <summary>
    /// Author: Christopher Chan
    /// </summary>
    useEffect(() => {
        if (state?.details) {
            console.log(state)
            reset(state?.details);
        }
        else {
            reset({
                parts: [{
                    partName: '',
                    serialNumber: '',
                    lifespanYear: null,
                    serviceTimeFrame: null,
                    remark: ''
                }]
            });
        }

        document.title = `NECS | ${isEditing ? 'Update' : 'Create'} Elevator Model`;
    }, [reset, isEditing, state])

    return (
        <form id="elevatorModelForm" onSubmit={handleSubmit(onSubmit)}>
            <div className="float-xl-right">
                <button type="submit" className="btn btn-themed btn-min-width">Save</button>
            </div>
            <h1 className="page-header">
                <NavigationButton />
                {!isEditing && 'Create '}Elevator Model {isEditing && ` - ${id}`}
            </h1>
            <Card className="mb-2">
                <CardBody>
                    <Row>
                        <Col xs={6}>        
                            <InputHoc 
                                name='name' 
                                label="Model Name *" 
                                error={errors?.name?.message} 
                                {...register("name", {
                                    required: "Model Name is required",
                                })}
                                showErrorMsg={true} />
                        </Col>
                        
                        <Col xs={3}>
                            <InputHoc 
                                name='serialNumber' 
                                label="Serial Number *" 
                                error={errors?.serialNumber?.message}
                                {...register("serialNumber", {
                                    required: "Serial Number is required",
                                })}
                                showErrorMsg={true} 
                            />                         
                        </Col>
                        <Col xs={3}>
                           <InputHoc 
                                name='price' 
                                label="Item Price (RM)  *" 
                                type={"number"}
                                step={"0.01"}
                                error={errors?.price?.message}
                                {...register("price", {
                                    required: "Price is required",
                                })}
                                showErrorMsg={true} 
                            />                              
                        </Col>
                        <Col xs={9}>
                           <InputHoc 
                                name='description' 
                                label="Description  *" 
                                error={errors?.description?.message}
                                {...register("description", {
                                    required: "Description is required",
                                })}
                                showErrorMsg={true} 
                            />                           
                        </Col>
                        <Col xs={3}>
                          <InputHoc 
                                name='suffix' 
                                label="Suffix  *" 
                                error={errors?.suffix?.message}
                                {...register("suffix", {
                                    required: "Suffix is required",
                                })}
                                showErrorMsg={true} 
                                smallnote="To be used in SNE number generation" 
                            />                               
                        </Col>
                    </Row>
                </CardBody>
            </Card>
            <Card>
                <CardBody>
                     <ModelPartsArray register={register} control={control} data={state} token={token} errors={errors} /> 
                </CardBody>
            </Card>
        </form>
    );
}