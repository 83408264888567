import React from 'react';
import { Confirm, Report } from "notiflix";
import PropTypes from 'prop-types';
import { ApiKey } from "util/Constant";

export const report = (type, responseJson, displayId, isUpdate, successCallback) => {
    Report.Init({ plainText: false });

    if (responseJson[ApiKey._API_SUCCESS_KEY]) {
        Report.Success(
            'Success',
            `Successfully ${isUpdate ? 'updated' : 'created'} ${type}, <b>${displayId}</b>.`,
            'Okay',
            successCallback
        );
    }
    else {
        Report.Failure(
            'Oops',
            `Failed to ${isUpdate ? 'update' : 'create'} ${type}. ` + responseJson[ApiKey._API_MESSAGE_KEY],
            'Okay'
        );
    }
}

report.defaultProps = {
    isUpdate: false,
    successCallback: () => { return; },
}

report.propTypes = {
    type: PropTypes.string.isRequired,
    responseStatus: PropTypes.bool.isRequired,
    responseMsg: PropTypes.string.isRequired,
    displayId: PropTypes.string.isRequired,
    isUpdate: PropTypes.bool,
    successCallback: PropTypes.func,
}